type Maybe<T> = T | undefined;
type InputMaybe<T> = T | null;
type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601Date: { input: string; output: string };
  ISO8601DateTime: { input: string; output: string };
};

type BracketContestInputObject = {
  away: BracketContestTeamInputObject;
  date?: InputMaybe<Scalars['String']['input']>;
  gameNumber: Scalars['Int']['input'];
  home: BracketContestTeamInputObject;
  isNeutral?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  roundId: BracketRoundIdEnum;
  secondaryStatusId?: InputMaybe<Scalars['Int']['input']>;
  seriesContests?: InputMaybe<Array<BracketSeriesInputObject>>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

type BracketContestTeamInputObject = {
  isBye?: InputMaybe<Scalars['Boolean']['input']>;
  isOriginal?: InputMaybe<Scalars['Boolean']['input']>;
  isTbd?: InputMaybe<Scalars['Boolean']['input']>;
  lineScore?: InputMaybe<Array<Scalars['Int']['input']>>;
  score?: InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  seriesTeamInfo?: InputMaybe<Array<BracketSeriesTeamInputObject>>;
  shootoutScore?: InputMaybe<Scalars['Int']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

enum BracketMatchupTypeEnum {
  BEST_OF_FIVE = 'BEST_OF_FIVE',
  BEST_OF_THREE = 'BEST_OF_THREE',
  SINGLE_GAME = 'SINGLE_GAME'
}

type BracketQuadrantInputObject = {
  index?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

enum BracketRoundIdEnum {
  CHAMPIONSHIP_ROUND = 'CHAMPIONSHIP_ROUND',
  EIGHTH_LOSER_ROUND = 'EIGHTH_LOSER_ROUND',
  EXTRA_ROUND = 'EXTRA_ROUND',
  FIFTH_LOSER_ROUND = 'FIFTH_LOSER_ROUND',
  FINAL_ROUND = 'FINAL_ROUND',
  FIRST_LOSER_ROUND = 'FIRST_LOSER_ROUND',
  FIRST_ROUND = 'FIRST_ROUND',
  FOURTH_LOSER_ROUND = 'FOURTH_LOSER_ROUND',
  FOURTH_ROUND = 'FOURTH_ROUND',
  PLAY_IN_ROUND = 'PLAY_IN_ROUND',
  SECOND_LOSER_ROUND = 'SECOND_LOSER_ROUND',
  SECOND_ROUND = 'SECOND_ROUND',
  SEMI_FINAL_ROUND = 'SEMI_FINAL_ROUND',
  SEVENTH_LOSER_ROUND = 'SEVENTH_LOSER_ROUND',
  SIXTH_LOSER_ROUND = 'SIXTH_LOSER_ROUND',
  THIRD_LOSER_ROUND = 'THIRD_LOSER_ROUND',
  THIRD_PLACE_ROUND = 'THIRD_PLACE_ROUND',
  THIRD_ROUND = 'THIRD_ROUND',
  WINNER_FINAL_ROUND = 'WINNER_FINAL_ROUND',
  WINNER_SEMI_FINAL_ROUND = 'WINNER_SEMI_FINAL_ROUND'
}

type BracketRoundInputObject = {
  bracketContests: Array<BracketContestInputObject>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id: BracketRoundIdEnum;
  isLoserRound?: InputMaybe<Scalars['Boolean']['input']>;
  isNeutral?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

type BracketSeriesInputObject = {
  date?: InputMaybe<Scalars['String']['input']>;
  secondaryStatusId?: InputMaybe<Scalars['Int']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

type BracketSeriesTeamInputObject = {
  lineScore?: InputMaybe<Array<Scalars['Int']['input']>>;
  score?: InputMaybe<Scalars['Int']['input']>;
  shootoutScore?: InputMaybe<Scalars['Int']['input']>;
};

type ClaimableEntity = {
  id: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  type: ClaimableEntityType;
};

enum ClaimableEntityType {
  /** A player */
  Player = 'Player',
  /** A school */
  School = 'School',
  /** A team */
  Team = 'Team'
}

enum CoachTypeEnum {
  ACADEMIC_COORDINATOR = 'ACADEMIC_COORDINATOR',
  ADMINISTRATIVE_ASSISTANT = 'ADMINISTRATIVE_ASSISTANT',
  ASSISTANT_ATHLETIC_DIRECTOR = 'ASSISTANT_ATHLETIC_DIRECTOR',
  ASSISTANT_COACH = 'ASSISTANT_COACH',
  ASSOCIATE_HEAD_COACH = 'ASSOCIATE_HEAD_COACH',
  ATHLETIC_DIRECTOR = 'ATHLETIC_DIRECTOR',
  ATHLETIC_TRAINER = 'ATHLETIC_TRAINER',
  BAND_DIRECTOR = 'BAND_DIRECTOR',
  CHEERLEADING_COORDINATOR = 'CHEERLEADING_COORDINATOR',
  DIRECTOR_OF_OPERATIONS = 'DIRECTOR_OF_OPERATIONS',
  HEAD_COACH = 'HEAD_COACH',
  PRINCIPAL = 'PRINCIPAL',
  STUDENT_MANAGER = 'STUDENT_MANAGER',
  VICE_PRINCIPAL = 'VICE_PRINCIPAL',
  VIDEO_COORDINATOR = 'VIDEO_COORDINATOR'
}

enum ContentItemTopicEnum {
  ATHLETE_OF_THE_WEEK = 'ATHLETE_OF_THE_WEEK',
  NATIONAL = 'NATIONAL',
  PHOTOS = 'PHOTOS',
  PODCAST = 'PODCAST',
  RECRUITING = 'RECRUITING',
  TOP_PLAYERS = 'TOP_PLAYERS',
  VIDEOS = 'VIDEOS',
  VIDEO_HIGHLIGHTS = 'VIDEO_HIGHLIGHTS'
}

enum ContentTypeEnum {
  PHOTO_GALLERY = 'PHOTO_GALLERY',
  POLL = 'POLL',
  TEMPEST_ARTICLE = 'TEMPEST_ARTICLE',
  TWEET = 'TWEET',
  VOLTAX_ARTICLE = 'VOLTAX_ARTICLE',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO'
}

enum ContestAffiliateEnum {
  GOFAN_TICKETING = 'GOFAN_TICKETING',
  HOMETOWN_TICKETING = 'HOMETOWN_TICKETING',
  NFHS_NETWORK = 'NFHS_NETWORK'
}

enum ContestExpectedStatusEnum {
  COMPLETED = 'COMPLETED',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING'
}

enum ContestOrderEnum {
  AWAY_TEAM_NAME = 'AWAY_TEAM_NAME',
  CONTEST_TYPE = 'CONTEST_TYPE',
  DATE = 'DATE',
  /** @deprecated Use `DATE` and `orderDirection``: `ASC` */
  DATE_ASC = 'DATE_ASC',
  /** @deprecated Use `DATE` and `orderDirection``: `DESC` */
  DATE_DESC = 'DATE_DESC',
  HOME_TEAM_NAME = 'HOME_TEAM_NAME',
  STATUS = 'STATUS'
}

type ContestParticipantInputObject = {
  forfeited?: InputMaybe<Scalars['Boolean']['input']>;
  linescore?: InputMaybe<Array<Scalars['Int']['input']>>;
  location: ContestTeamLocationEnum;
  participantId?: InputMaybe<Scalars['String']['input']>;
  participantType: ContestTeamTypeEnum;
  score?: InputMaybe<Scalars['Int']['input']>;
};

enum ContestStatusEnum {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  DELAYED = 'DELAYED',
  FORFEIT = 'FORFEIT',
  LIVE = 'LIVE',
  POSTPONED = 'POSTPONED',
  UPCOMING = 'UPCOMING'
}

enum ContestTeamLocationEnum {
  AWAY = 'AWAY',
  HOME = 'HOME',
  NEUTRAL = 'NEUTRAL'
}

enum ContestTeamResultEnum {
  FORFEIT = 'FORFEIT',
  LOSS = 'LOSS',
  TIE = 'TIE',
  WIN = 'WIN'
}

enum ContestTeamTypeEnum {
  BYE = 'BYE',
  TBD = 'TBD',
  TEAM = 'TEAM',
  UNKNOWN = 'UNKNOWN'
}

enum ContestTypeEnum {
  LEAGUE = 'LEAGUE',
  NON_LEAGUE = 'NON_LEAGUE',
  PLAYOFFS = 'PLAYOFFS',
  SCRIMMAGE = 'SCRIMMAGE',
  TOURNAMENT = 'TOURNAMENT'
}

/** The different types of devices supported. */
enum DeviceTypeEnum {
  /** APNS Devices. */
  APNS = 'APNS',
  /** Expo Devices */
  EXPO = 'EXPO',
  /** FCM Devices. */
  FCM = 'FCM'
}

enum EntityTypeEnum {
  Bracket = 'Bracket',
  Group = 'Group',
  Player = 'Player',
  School = 'School',
  Team = 'Team'
}

type ExpoDeviceTokenInputType = {
  /** The actual token. */
  data: Scalars['String']['input'];
  /** Analagous to the device ID. */
  installation_id: Scalars['String']['input'];
};

type FollowableEntity = {
  id: Scalars['ID']['input'];
  index?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  type: FollowableEntityType;
};

enum FollowableEntityType {
  /** A group of teams */
  Group = 'Group',
  /** A team */
  Team = 'Team'
}

type GamePlayerInputType = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jersey_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stat?: InputMaybe<StatInputType>;
};

/** Properties of creating and updating game player stats */
type GamePlayerStatsInputType = {
  away?: InputMaybe<GameTeamInputType>;
  home?: InputMaybe<GameTeamInputType>;
  id: Scalars['ID']['input'];
};

enum GameTeamEntityType {
  SCHOOL = 'SCHOOL',
  /**
   * A school
   * @deprecated Use `SCHOOL` instead.
   */
  School = 'School',
  TEAM = 'TEAM',
  /**
   * A team
   * @deprecated Use `TEAM` instead.
   */
  Team = 'Team'
}

type GameTeamInputType = {
  game_players?: InputMaybe<Array<GamePlayerInputType>>;
};

enum GameTeamResultEnum {
  LOSS = 'LOSS',
  TIE = 'TIE',
  WIN = 'WIN'
}

enum GenderEnum {
  CO_ED = 'CO_ED',
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

enum GenderSportEnum {
  BADMINTON = 'BADMINTON',
  BASEBALL = 'BASEBALL',
  BEACH_VOLLEYBALL = 'BEACH_VOLLEYBALL',
  BOYS_BASKETBALL = 'BOYS_BASKETBALL',
  BOYS_CROSS_COUNTRY = 'BOYS_CROSS_COUNTRY',
  BOYS_FENCING = 'BOYS_FENCING',
  BOYS_FIELD_HOCKEY = 'BOYS_FIELD_HOCKEY',
  BOYS_GOLF = 'BOYS_GOLF',
  BOYS_ICE_HOCKEY = 'BOYS_ICE_HOCKEY',
  BOYS_LACROSSE = 'BOYS_LACROSSE',
  BOYS_SOCCER = 'BOYS_SOCCER',
  BOYS_TENNIS = 'BOYS_TENNIS',
  BOYS_ULTIMATE_FRISBEE = 'BOYS_ULTIMATE_FRISBEE',
  BOYS_VOLLEYBALL = 'BOYS_VOLLEYBALL',
  BOYS_WATER_POLO = 'BOYS_WATER_POLO',
  FIELD_HOCKEY = 'FIELD_HOCKEY',
  FLAG_FOOTBALL = 'FLAG_FOOTBALL',
  FOOTBALL = 'FOOTBALL',
  GIRLS_BASKETBALL = 'GIRLS_BASKETBALL',
  GIRLS_CROSS_COUNTRY = 'GIRLS_CROSS_COUNTRY',
  GIRLS_FENCING = 'GIRLS_FENCING',
  GIRLS_GOLF = 'GIRLS_GOLF',
  GIRLS_ICE_HOCKEY = 'GIRLS_ICE_HOCKEY',
  GIRLS_LACROSSE = 'GIRLS_LACROSSE',
  GIRLS_SOCCER = 'GIRLS_SOCCER',
  GIRLS_TENNIS = 'GIRLS_TENNIS',
  GIRLS_ULTIMATE_FRISBEE = 'GIRLS_ULTIMATE_FRISBEE',
  GIRLS_VOLLEYBALL = 'GIRLS_VOLLEYBALL',
  GIRLS_WATER_POLO = 'GIRLS_WATER_POLO',
  GIRLS_WRESTLING = 'GIRLS_WRESTLING',
  SOFTBALL = 'SOFTBALL',
  UNIFIED_BASKETBALL = 'UNIFIED_BASKETBALL',
  WRESTLING = 'WRESTLING'
}

enum LevelEnum {
  ADULT = 'ADULT',
  C_TEAM = 'C_TEAM',
  EIGHTH_GRADE = 'EIGHTH_GRADE',
  FIBA = 'FIBA',
  FIFTHTEENU = 'FIFTHTEENU',
  FIFTH_GRADE = 'FIFTH_GRADE',
  FOURTEENU = 'FOURTEENU',
  FOURTH_GRADE = 'FOURTH_GRADE',
  FRESHMAN = 'FRESHMAN',
  JUNIOR_COLLEGE = 'JUNIOR_COLLEGE',
  JUNIOR_VARSITY = 'JUNIOR_VARSITY',
  NAIA = 'NAIA',
  NCAA_D1 = 'NCAA_D1',
  NCAA_D2 = 'NCAA_D2',
  NCAA_D3 = 'NCAA_D3',
  PROFESSIONAL = 'PROFESSIONAL',
  RECREATIONAL = 'RECREATIONAL',
  SEMI_PROFESSIONAL = 'SEMI_PROFESSIONAL',
  SEVENTEENU = 'SEVENTEENU',
  SEVENTH_GRADE = 'SEVENTH_GRADE',
  SIXTEENU = 'SIXTEENU',
  SIXTH_GRADE = 'SIXTH_GRADE',
  VARSITY = 'VARSITY',
  YOUTH = 'YOUTH'
}

enum NfhsNetworkUrlTypeEnum {
  DIRECT = 'DIRECT',
  STATE = 'STATE'
}

type NotifiableEntity = {
  id: Scalars['ID']['input'];
  type: NotifiableEntityType;
};

enum NotifiableEntityType {
  /** A game */
  Game = 'Game',
  /** A team */
  Team = 'Team'
}

type NotifiableGroupEntity = {
  ids: Array<Scalars['ID']['input']>;
  type: NotifiableEntityType;
};

/** The different types of notifications supported. */
enum NotificationTypeKeyEnum {
  /** Notifications about a game's final score. */
  FINAL_SCORE = 'FINAL_SCORE',
  /** Notifications about a game starting. */
  GAME_START = 'GAME_START',
  /** Notifications about a game entering halftime. */
  HALFTIME = 'HALFTIME',
  /** Notifications used to help remind users to enter a score. */
  MISSING_SCORE = 'MISSING_SCORE',
  /** Notifications about the news around a team. */
  NEWS = 'NEWS',
  /** Notifications for when a team recieves a new follower. */
  NEW_FOLLOWER = 'NEW_FOLLOWER',
  /** Notifications prior to a game's start */
  PREGAME = 'PREGAME',
  /** Notifications about new rankings. */
  RANKING = 'RANKING',
  /** Notifications about an upcoming game. */
  UPCOMING_GAME = 'UPCOMING_GAME'
}

enum OrderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

enum OrganizationOrderEnum {
  FULL_NAME = 'FULL_NAME',
  NAME = 'NAME',
  SORTING_PRIORITY = 'SORTING_PRIORITY'
}

enum OrganizationTypeEnum {
  ASSOCIATION = 'ASSOCIATION',
  DIVISION = 'DIVISION',
  LEAGUE = 'LEAGUE',
  METRO = 'METRO',
  SECTION = 'SECTION'
}

/** Properties of creating and updating a Pick Em */
type PickEmInputObject = {
  gameTeamId: Scalars['ID']['input'];
};

enum RankingFormulaEnum {
  CIF_RPI = 'CIF_RPI',
  COLLEY = 'COLLEY',
  COLLEY_ROYALTY = 'COLLEY_ROYALTY',
  DIVISION_POINT_INDEX = 'DIVISION_POINT_INDEX',
  MIAA_POWER_RATING = 'MIAA_POWER_RATING',
  OSAA_RPI = 'OSAA_RPI',
  OSAA_RPI_COLLEY = 'OSAA_RPI_COLLEY',
  POINT_INDEX = 'POINT_INDEX',
  POWER_25 = 'POWER_25',
  QUALITY_POINT_RATING = 'QUALITY_POINT_RATING'
}

type RosterAthleteInputObject = {
  birthdate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  classYear?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  firstName: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Int']['input']>;
  jerseyNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  lastName: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
  positions?: InputMaybe<Array<Scalars['String']['input']>>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

enum RostersOrderEnum {
  DATE = 'DATE'
}

/** Properties of creating and updating a school */
type SchoolInputType = {
  abbrev?: InputMaybe<Scalars['String']['input']>;
  athletic_director?: InputMaybe<Scalars['String']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  enrollment_count?: InputMaybe<Scalars['Int']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  primary_color?: InputMaybe<Scalars['String']['input']>;
  principal?: InputMaybe<Scalars['String']['input']>;
  secondary_color?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

enum SchoolTypeEnum {
  PRIVATE_SCHOOL = 'PRIVATE_SCHOOL',
  PUBLIC_SCHOOL = 'PUBLIC_SCHOOL'
}

enum SchoolsOrderEnum {
  NAME = 'NAME',
  OWNED = 'OWNED'
}

enum ScoreboardDateScopeEnum {
  MONTH = 'MONTH'
}

enum SearchableEntityTypeEnum {
  BRACKET = 'BRACKET',
  ORGANIZATION = 'ORGANIZATION',
  SCHOOL = 'SCHOOL',
  TEAM = 'TEAM'
}

enum SponsorPlacementEnum {
  AL_AHSAA_WHITE_LABEL = 'AL_AHSAA_WHITE_LABEL',
  AR_AAA_WHITE_LABEL = 'AR_AAA_WHITE_LABEL',
  CA_CIF_CENTRAL_WHITE_LABEL = 'CA_CIF_CENTRAL_WHITE_LABEL',
  CA_CIF_LA_CITY_WHITE_LABEL = 'CA_CIF_LA_CITY_WHITE_LABEL',
  CA_CIF_NCS_WHITE_LABEL = 'CA_CIF_NCS_WHITE_LABEL',
  CA_CIF_SDS_WHITE_LABEL = 'CA_CIF_SDS_WHITE_LABEL',
  CA_CIF_SF_WHITE_LABEL = 'CA_CIF_SF_WHITE_LABEL',
  CA_CIF_SS_WHITE_LABEL = 'CA_CIF_SS_WHITE_LABEL',
  FAN_APP_FEED_BOTTOM = 'FAN_APP_FEED_BOTTOM',
  FAN_APP_HOME_BOTTOM = 'FAN_APP_HOME_BOTTOM',
  FAN_APP_SPLASH_MODAL = 'FAN_APP_SPLASH_MODAL',
  MS_MHSAA_WHITE_LABEL = 'MS_MHSAA_WHITE_LABEL',
  SBLIVE_WHITE_LABEL = 'SBLIVE_WHITE_LABEL',
  VT_VPA_WHITE_LABEL = 'VT_VPA_WHITE_LABEL',
  WA_WIAA_WHITE_LABEL = 'WA_WIAA_WHITE_LABEL'
}

enum SportEnum {
  BADMINTON = 'BADMINTON',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  BEACH_VOLLEYBALL = 'BEACH_VOLLEYBALL',
  CROSS_COUNTRY = 'CROSS_COUNTRY',
  FENCING = 'FENCING',
  FIELD_HOCKEY = 'FIELD_HOCKEY',
  FLAG_FOOTBALL = 'FLAG_FOOTBALL',
  FOOTBALL = 'FOOTBALL',
  GOLF = 'GOLF',
  ICE_HOCKEY = 'ICE_HOCKEY',
  LACROSSE = 'LACROSSE',
  SOCCER = 'SOCCER',
  SOFTBALL = 'SOFTBALL',
  TENNIS = 'TENNIS',
  ULTIMATE_FRISBEE = 'ULTIMATE_FRISBEE',
  UNIFIED_BASKETBALL = 'UNIFIED_BASKETBALL',
  VOLLEYBALL = 'VOLLEYBALL',
  WATER_POLO = 'WATER_POLO',
  WRESTLING = 'WRESTLING'
}

type StatInputType = {
  assisted_tackles?: InputMaybe<Scalars['Int']['input']>;
  assists?: InputMaybe<Scalars['Int']['input']>;
  at_bats?: InputMaybe<Scalars['Int']['input']>;
  attack_errors?: InputMaybe<Scalars['Int']['input']>;
  average_finish?: InputMaybe<Scalars['Int']['input']>;
  ball_handling_assists?: InputMaybe<Scalars['Int']['input']>;
  ball_handling_errors?: InputMaybe<Scalars['Int']['input']>;
  batters_faced?: InputMaybe<Scalars['Int']['input']>;
  best_finish?: InputMaybe<Scalars['Int']['input']>;
  birdies?: InputMaybe<Scalars['Int']['input']>;
  block_assists?: InputMaybe<Scalars['Int']['input']>;
  blocking_errors?: InputMaybe<Scalars['Int']['input']>;
  blocks?: InputMaybe<Scalars['Int']['input']>;
  bogeys?: InputMaybe<Scalars['Int']['input']>;
  break_point_attempts?: InputMaybe<Scalars['Int']['input']>;
  break_points_won?: InputMaybe<Scalars['Int']['input']>;
  caught_stealing?: InputMaybe<Scalars['Int']['input']>;
  cautions?: InputMaybe<Scalars['Int']['input']>;
  clears?: InputMaybe<Scalars['Int']['input']>;
  complete_game?: InputMaybe<Scalars['Int']['input']>;
  concussion?: InputMaybe<Scalars['Int']['input']>;
  concussions?: InputMaybe<Scalars['Int']['input']>;
  defensive_blocked_field_goals?: InputMaybe<Scalars['Int']['input']>;
  defensive_blocked_punts?: InputMaybe<Scalars['Int']['input']>;
  defensive_fumble_recovery_yard?: InputMaybe<Scalars['Int']['input']>;
  defensive_fumbles_forced?: InputMaybe<Scalars['Int']['input']>;
  defensive_fumbles_recovered?: InputMaybe<Scalars['Int']['input']>;
  defensive_interception_yards?: InputMaybe<Scalars['Int']['input']>;
  defensive_interceptions?: InputMaybe<Scalars['Int']['input']>;
  defensive_passes_defended?: InputMaybe<Scalars['Int']['input']>;
  defensive_rebounds?: InputMaybe<Scalars['Int']['input']>;
  defensive_sacks?: InputMaybe<Scalars['Int']['input']>;
  defensive_save?: InputMaybe<Scalars['Int']['input']>;
  defensive_touchdown?: InputMaybe<Scalars['Int']['input']>;
  dig_errors?: InputMaybe<Scalars['Int']['input']>;
  digs?: InputMaybe<Scalars['Int']['input']>;
  double_faults?: InputMaybe<Scalars['Int']['input']>;
  double_plays?: InputMaybe<Scalars['Int']['input']>;
  doubles?: InputMaybe<Scalars['Int']['input']>;
  doubles_allowed?: InputMaybe<Scalars['Int']['input']>;
  draw_control?: InputMaybe<Scalars['Int']['input']>;
  draws?: InputMaybe<Scalars['Int']['input']>;
  eagles?: InputMaybe<Scalars['Int']['input']>;
  earned_runs_allowed?: InputMaybe<Scalars['Int']['input']>;
  ejections?: InputMaybe<Scalars['Int']['input']>;
  ejects_drawn?: InputMaybe<Scalars['Int']['input']>;
  ejects_given?: InputMaybe<Scalars['Int']['input']>;
  errors_stat?: InputMaybe<Scalars['Int']['input']>;
  events_played?: InputMaybe<Scalars['Int']['input']>;
  faceoffs_lost?: InputMaybe<Scalars['Int']['input']>;
  faceoffs_won?: InputMaybe<Scalars['Int']['input']>;
  fast_break?: InputMaybe<Scalars['Int']['input']>;
  field_goals_attempts?: InputMaybe<Scalars['Int']['input']>;
  field_goals_longest?: InputMaybe<Scalars['Int']['input']>;
  field_goals_made?: InputMaybe<Scalars['Int']['input']>;
  fielders_choice?: InputMaybe<Scalars['Int']['input']>;
  fielding_errors?: InputMaybe<Scalars['Int']['input']>;
  finish?: InputMaybe<Scalars['Int']['input']>;
  fouls?: InputMaybe<Scalars['Int']['input']>;
  free_throws_attempted?: InputMaybe<Scalars['Int']['input']>;
  free_throws_made?: InputMaybe<Scalars['Int']['input']>;
  fumbles_lost?: InputMaybe<Scalars['Int']['input']>;
  fumbles_recovered?: InputMaybe<Scalars['Int']['input']>;
  games_played?: InputMaybe<Scalars['Int']['input']>;
  games_started?: InputMaybe<Scalars['Int']['input']>;
  goalie_saves?: InputMaybe<Scalars['Int']['input']>;
  goals?: InputMaybe<Scalars['Int']['input']>;
  goals_against?: InputMaybe<Scalars['Int']['input']>;
  ground_balls?: InputMaybe<Scalars['Int']['input']>;
  hit_batter?: InputMaybe<Scalars['Int']['input']>;
  hit_by_pitch?: InputMaybe<Scalars['Int']['input']>;
  hits_allowed?: InputMaybe<Scalars['Int']['input']>;
  holds?: InputMaybe<Scalars['Int']['input']>;
  home_runs?: InputMaybe<Scalars['Int']['input']>;
  home_runs_allowed?: InputMaybe<Scalars['Int']['input']>;
  hurries?: InputMaybe<Scalars['Int']['input']>;
  innings_pitched?: InputMaybe<Scalars['String']['input']>;
  innings_played?: InputMaybe<Scalars['Int']['input']>;
  intentional_walks?: InputMaybe<Scalars['Int']['input']>;
  kick_return_long?: InputMaybe<Scalars['Int']['input']>;
  kick_return_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  kickoff_attempts?: InputMaybe<Scalars['Int']['input']>;
  kickoff_return_yards?: InputMaybe<Scalars['Int']['input']>;
  kickoff_yards?: InputMaybe<Scalars['Int']['input']>;
  kickoffs?: InputMaybe<Scalars['Int']['input']>;
  kickoffs_long?: InputMaybe<Scalars['Int']['input']>;
  kills?: InputMaybe<Scalars['Int']['input']>;
  losses?: InputMaybe<Scalars['Int']['input']>;
  minutes_in_goal?: InputMaybe<Scalars['Int']['input']>;
  minutes_on_field?: InputMaybe<Scalars['Int']['input']>;
  minutes_played?: InputMaybe<Scalars['Int']['input']>;
  offensive_rebounds?: InputMaybe<Scalars['Int']['input']>;
  overtime_losses?: InputMaybe<Scalars['Int']['input']>;
  pars?: InputMaybe<Scalars['Int']['input']>;
  passed_balls?: InputMaybe<Scalars['Int']['input']>;
  passing_attempts?: InputMaybe<Scalars['Int']['input']>;
  passing_completions?: InputMaybe<Scalars['Int']['input']>;
  passing_interceptions?: InputMaybe<Scalars['Int']['input']>;
  passing_sacks?: InputMaybe<Scalars['Int']['input']>;
  passing_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  passing_yards?: InputMaybe<Scalars['Int']['input']>;
  pat_attempts?: InputMaybe<Scalars['Int']['input']>;
  pat_made?: InputMaybe<Scalars['Int']['input']>;
  penalties?: InputMaybe<Scalars['Int']['input']>;
  penalties_in_minutes?: InputMaybe<Scalars['Int']['input']>;
  penalty_corners?: InputMaybe<Scalars['Int']['input']>;
  penalty_kick_attempts?: InputMaybe<Scalars['Int']['input']>;
  penalty_kick_goals?: InputMaybe<Scalars['Int']['input']>;
  periods_played?: InputMaybe<Scalars['Int']['input']>;
  personal_fouls?: InputMaybe<Scalars['Int']['input']>;
  pickoffs?: InputMaybe<Scalars['Int']['input']>;
  pitch_count?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  power_play_assists?: InputMaybe<Scalars['Int']['input']>;
  power_play_goals?: InputMaybe<Scalars['Int']['input']>;
  punt_attempts?: InputMaybe<Scalars['Int']['input']>;
  punt_long?: InputMaybe<Scalars['Int']['input']>;
  punt_return_attempts?: InputMaybe<Scalars['Int']['input']>;
  punt_return_long?: InputMaybe<Scalars['Int']['input']>;
  punt_return_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  punt_return_yards?: InputMaybe<Scalars['Int']['input']>;
  punt_yards?: InputMaybe<Scalars['Int']['input']>;
  punts?: InputMaybe<Scalars['Int']['input']>;
  punts_inside_20_yard_line?: InputMaybe<Scalars['Int']['input']>;
  put_outs?: InputMaybe<Scalars['Int']['input']>;
  qb_hits?: InputMaybe<Scalars['Int']['input']>;
  quarters_played?: InputMaybe<Scalars['Int']['input']>;
  reached_on_error?: InputMaybe<Scalars['Int']['input']>;
  receiving_errors?: InputMaybe<Scalars['Int']['input']>;
  receiving_fumbles?: InputMaybe<Scalars['Int']['input']>;
  receiving_longest?: InputMaybe<Scalars['Int']['input']>;
  receiving_receptions?: InputMaybe<Scalars['Int']['input']>;
  receiving_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  receiving_yards?: InputMaybe<Scalars['Int']['input']>;
  receptions?: InputMaybe<Scalars['Int']['input']>;
  red_cards?: InputMaybe<Scalars['Int']['input']>;
  return_points_won?: InputMaybe<Scalars['Int']['input']>;
  returns_attempted?: InputMaybe<Scalars['Int']['input']>;
  rounds_played?: InputMaybe<Scalars['Int']['input']>;
  runners_caught_stealing?: InputMaybe<Scalars['Int']['input']>;
  runners_left_in_scoring_position?: InputMaybe<Scalars['Int']['input']>;
  runners_left_on_base?: InputMaybe<Scalars['Int']['input']>;
  runs?: InputMaybe<Scalars['Int']['input']>;
  runs_allowed?: InputMaybe<Scalars['Int']['input']>;
  runs_batted_in?: InputMaybe<Scalars['Int']['input']>;
  rushing_attempts?: InputMaybe<Scalars['Int']['input']>;
  rushing_fumbles?: InputMaybe<Scalars['Int']['input']>;
  rushing_longest?: InputMaybe<Scalars['Int']['input']>;
  rushing_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  rushing_yards?: InputMaybe<Scalars['Int']['input']>;
  sacrifice_bunts?: InputMaybe<Scalars['Int']['input']>;
  sacrifice_flies?: InputMaybe<Scalars['Int']['input']>;
  saves?: InputMaybe<Scalars['Int']['input']>;
  service_aces?: InputMaybe<Scalars['Int']['input']>;
  service_attempts?: InputMaybe<Scalars['Int']['input']>;
  service_errors?: InputMaybe<Scalars['Int']['input']>;
  service_faults?: InputMaybe<Scalars['Int']['input']>;
  service_points_won?: InputMaybe<Scalars['Int']['input']>;
  sets_played?: InputMaybe<Scalars['Int']['input']>;
  shootout_saves?: InputMaybe<Scalars['Int']['input']>;
  shootout_shorts_against?: InputMaybe<Scalars['Int']['input']>;
  short_hand_assists?: InputMaybe<Scalars['Int']['input']>;
  short_hand_goals?: InputMaybe<Scalars['Int']['input']>;
  shots?: InputMaybe<Scalars['Int']['input']>;
  shots_against?: InputMaybe<Scalars['Int']['input']>;
  shots_on_goal?: InputMaybe<Scalars['Int']['input']>;
  singles?: InputMaybe<Scalars['Int']['input']>;
  singles_allowed?: InputMaybe<Scalars['Int']['input']>;
  snaps_played?: InputMaybe<Scalars['Int']['input']>;
  solo_blocks?: InputMaybe<Scalars['Int']['input']>;
  solo_tackles?: InputMaybe<Scalars['Int']['input']>;
  sprints_attempted?: InputMaybe<Scalars['Int']['input']>;
  sprints_won?: InputMaybe<Scalars['Int']['input']>;
  steals?: InputMaybe<Scalars['Int']['input']>;
  stolen_base_attempts?: InputMaybe<Scalars['Int']['input']>;
  stolen_bases?: InputMaybe<Scalars['Int']['input']>;
  strikeouts_def?: InputMaybe<Scalars['Int']['input']>;
  strikeouts_off?: InputMaybe<Scalars['Int']['input']>;
  strikes_thrown?: InputMaybe<Scalars['Int']['input']>;
  tackles_for_loss?: InputMaybe<Scalars['Int']['input']>;
  take_away?: InputMaybe<Scalars['Int']['input']>;
  targets?: InputMaybe<Scalars['Int']['input']>;
  technical_fouls?: InputMaybe<Scalars['Int']['input']>;
  three_points_attempted?: InputMaybe<Scalars['Int']['input']>;
  three_points_made?: InputMaybe<Scalars['Int']['input']>;
  ties?: InputMaybe<Scalars['Int']['input']>;
  time_on_ice?: InputMaybe<Scalars['Int']['input']>;
  top_five_finishes?: InputMaybe<Scalars['Int']['input']>;
  top_ten_finishes?: InputMaybe<Scalars['Int']['input']>;
  top_twenty_finishes?: InputMaybe<Scalars['Int']['input']>;
  total_attack_attempts?: InputMaybe<Scalars['Int']['input']>;
  total_faceoffs?: InputMaybe<Scalars['Int']['input']>;
  total_kick_returns?: InputMaybe<Scalars['Int']['input']>;
  total_punt_returns?: InputMaybe<Scalars['Int']['input']>;
  total_strokes?: InputMaybe<Scalars['Int']['input']>;
  touchbacks?: InputMaybe<Scalars['Int']['input']>;
  triples?: InputMaybe<Scalars['Int']['input']>;
  triples_allowed?: InputMaybe<Scalars['Int']['input']>;
  turnovers?: InputMaybe<Scalars['Int']['input']>;
  two_points_attempted?: InputMaybe<Scalars['Int']['input']>;
  two_points_made?: InputMaybe<Scalars['Int']['input']>;
  vs_par?: InputMaybe<Scalars['Int']['input']>;
  walks?: InputMaybe<Scalars['Int']['input']>;
  walks_allowed?: InputMaybe<Scalars['Int']['input']>;
  wins?: InputMaybe<Scalars['Int']['input']>;
  yards_lost?: InputMaybe<Scalars['Int']['input']>;
  yellow_cards?: InputMaybe<Scalars['Int']['input']>;
};

enum StateEnum {
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  ALBERTA = 'ALBERTA',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  BRITISH_COLUMBIA = 'BRITISH_COLUMBIA',
  CALIFORNIA = 'CALIFORNIA',
  COLORADO = 'COLORADO',
  CONNECTICUT = 'CONNECTICUT',
  DELAWARE = 'DELAWARE',
  DISTRICT_OF_COLUMBIA = 'DISTRICT_OF_COLUMBIA',
  FLORIDA = 'FLORIDA',
  GEORGIA = 'GEORGIA',
  HAWAII = 'HAWAII',
  IDAHO = 'IDAHO',
  ILLINOIS = 'ILLINOIS',
  INDIANA = 'INDIANA',
  IOWA = 'IOWA',
  KANSAS = 'KANSAS',
  KENTUCKY = 'KENTUCKY',
  LOUISIANA = 'LOUISIANA',
  MAINE = 'MAINE',
  MANITOBA = 'MANITOBA',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MONTANA = 'MONTANA',
  NEBRASKA = 'NEBRASKA',
  NEVADA = 'NEVADA',
  NEWFOUNDLAND_AND_LABRADOR = 'NEWFOUNDLAND_AND_LABRADOR',
  NEW_BRUNSWICK = 'NEW_BRUNSWICK',
  NEW_HAMPSHIRE = 'NEW_HAMPSHIRE',
  NEW_JERSEY = 'NEW_JERSEY',
  NEW_MEXICO = 'NEW_MEXICO',
  NEW_YORK = 'NEW_YORK',
  NORTH_CAROLINA = 'NORTH_CAROLINA',
  NORTH_DAKOTA = 'NORTH_DAKOTA',
  NOVA_SCOTIA = 'NOVA_SCOTIA',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  ONTARIO = 'ONTARIO',
  OREGON = 'OREGON',
  PENNSYLVANIA = 'PENNSYLVANIA',
  PRINCE_EDWARD_ISLAND = 'PRINCE_EDWARD_ISLAND',
  PUERTO_RICO = 'PUERTO_RICO',
  QUEBEC = 'QUEBEC',
  RHODE_ISLAND = 'RHODE_ISLAND',
  SASKATCHEWAN = 'SASKATCHEWAN',
  SOUTH_CAROLINA = 'SOUTH_CAROLINA',
  SOUTH_DAKOTA = 'SOUTH_DAKOTA',
  TENNESSEE = 'TENNESSEE',
  TEXAS = 'TEXAS',
  UTAH = 'UTAH',
  VERMONT = 'VERMONT',
  VIRGINIA = 'VIRGINIA',
  WASHINGTON = 'WASHINGTON',
  WEST_VIRGINIA = 'WEST_VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING'
}

enum StatsOrderEnum {
  POINTS = 'POINTS',
  YARDS = 'YARDS'
}

/** Properties of creating and updating a team coach */
type TeamCoachInputType = {
  coach_type_id?: InputMaybe<Scalars['Int']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  year_hired?: InputMaybe<Scalars['Int']['input']>;
};

/** Properties of creating and updating a game */
type TeamGameInputType = {
  date: Scalars['String']['input'];
  game_type_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_home: Scalars['Boolean']['input'];
  is_neutral: Scalars['Boolean']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  opponent_id: Scalars['ID']['input'];
  rule_type_id?: InputMaybe<Scalars['Int']['input']>;
  secondary_status_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
  track_subs?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Properties of creating and updating a game */
type TeamGameResultsInputType = {
  featured_forfeit?: InputMaybe<Scalars['Boolean']['input']>;
  featured_linescore?: InputMaybe<Array<Scalars['Int']['input']>>;
  featured_score: Scalars['Int']['input'];
  featured_shootout_score?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  opponent_forfeit?: InputMaybe<Scalars['Boolean']['input']>;
  opponent_linescore?: InputMaybe<Array<Scalars['Int']['input']>>;
  opponent_score: Scalars['Int']['input'];
  opponent_shootout_score?: InputMaybe<Scalars['Int']['input']>;
  periods?: InputMaybe<Scalars['Int']['input']>;
};

/** Properties of creating and updating a team */
type TeamInputType = {
  abbrev?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  genderSport?: InputMaybe<GenderSportEnum>;
  hashtag?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LevelEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_color?: InputMaybe<Scalars['String']['input']>;
  rivalId?: InputMaybe<Scalars['ID']['input']>;
  school_id?: InputMaybe<Scalars['Int']['input']>;
  secondary_color?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  teamOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<Scalars['String']['input']>;
  venueCapacity?: InputMaybe<Scalars['Int']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** Properties of creating and updating a team player */
type TeamPlayerInputType = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  class_year?: InputMaybe<Scalars['Int']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  jersey_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  position_2_id?: InputMaybe<Scalars['Int']['input']>;
  position_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

enum TeamsOrderEnum {
  NAME = 'NAME',
  OWNED = 'OWNED'
}

/** Properties of an entity that a user is invited to manager */
type UserEntityInvitationEntityInputType = {
  entity_id: Scalars['Int']['input'];
  entity_type: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

enum UserEntityRoleEnum {
  FAVORITE = 'FAVORITE',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
  RIVAL = 'RIVAL',
  SCOREKEEPER = 'SCOREKEEPER'
}

enum UserEntityRoleLegacyEnum {
  Favorite = 'Favorite',
  Manager = 'Manager',
  Owner = 'Owner',
  Rival = 'Rival',
  Scorekeeper = 'Scorekeeper'
}

enum UserEntityStatusEnum {
  CONFIRMED = 'CONFIRMED',
  REQUESTED = 'REQUESTED',
  TENTATIVE = 'TENTATIVE'
}

enum UserEntityTypeEnum {
  BRACKET = 'BRACKET',
  GROUP = 'GROUP',
  SCHOOL = 'SCHOOL',
  TEAM = 'TEAM'
}

enum UserNotificationStatusEnum {
  DISMISSED = 'DISMISSED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SEEN = 'SEEN',
  SENT = 'SENT'
}

/** A Directive can be adjacent to many parts of the GraphQL language, a __DirectiveLocation describes one such possible adjacencies. */
enum DirectiveLocation {
  /** Location adjacent to a query operation. */
  QUERY = 'QUERY',
  /** Location adjacent to a mutation operation. */
  MUTATION = 'MUTATION',
  /** Location adjacent to a subscription operation. */
  SUBSCRIPTION = 'SUBSCRIPTION',
  /** Location adjacent to a field. */
  FIELD = 'FIELD',
  /** Location adjacent to a fragment definition. */
  FRAGMENT_DEFINITION = 'FRAGMENT_DEFINITION',
  /** Location adjacent to a fragment spread. */
  FRAGMENT_SPREAD = 'FRAGMENT_SPREAD',
  /** Location adjacent to an inline fragment. */
  INLINE_FRAGMENT = 'INLINE_FRAGMENT',
  /** Location adjacent to a variable definition. */
  VARIABLE_DEFINITION = 'VARIABLE_DEFINITION',
  /** Location adjacent to a schema definition. */
  SCHEMA = 'SCHEMA',
  /** Location adjacent to a scalar definition. */
  SCALAR = 'SCALAR',
  /** Location adjacent to an object type definition. */
  OBJECT = 'OBJECT',
  /** Location adjacent to a field definition. */
  FIELD_DEFINITION = 'FIELD_DEFINITION',
  /** Location adjacent to an argument definition. */
  ARGUMENT_DEFINITION = 'ARGUMENT_DEFINITION',
  /** Location adjacent to an interface definition. */
  INTERFACE = 'INTERFACE',
  /** Location adjacent to a union definition. */
  UNION = 'UNION',
  /** Location adjacent to an enum definition. */
  ENUM = 'ENUM',
  /** Location adjacent to an enum value definition. */
  ENUM_VALUE = 'ENUM_VALUE',
  /** Location adjacent to an input object type definition. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Location adjacent to an input object field definition. */
  INPUT_FIELD_DEFINITION = 'INPUT_FIELD_DEFINITION'
}

/** An enum describing what kind of type a given `__Type` is. */
enum TypeKind {
  /** Indicates this type is a scalar. */
  SCALAR = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  OBJECT = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  INTERFACE = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  UNION = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  ENUM = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  LIST = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NON_NULL = 'NON_NULL'
}

export type ActiveGamesQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveGamesQuery = {
  activeGames: Array<
    | {
        id: string;
        longStatusText: string;
        statusId: number;
        gameTeams: Array<{ id: string; isLoser: boolean; isWinner: boolean; scoreText: string | undefined }>;
      }
    | {
        id: string;
        longStatusText: string;
        statusId: number;
        gameTeams: Array<{ id: string; isLoser: boolean; isWinner: boolean; scoreText: string | undefined }>;
      }
    | {
        id: string;
        longStatusText: string;
        statusId: number;
        gameTeams: Array<{ id: string; isLoser: boolean; isWinner: boolean; scoreText: string | undefined }>;
      }
  >;
};

export type AdminTeamGamesScoreQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  team_id: Scalars['Int']['input'];
}>;

export type AdminTeamGamesScoreQuery = {
  game:
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        periods: number;
        sport_id: number;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        title_text: string;
        image: string;
        long_status_text: string;
        clock_text: string | undefined;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          is_home: boolean;
          score: number;
          linescore: string | undefined;
          shootout_score: number | undefined;
          forfeit: boolean | undefined;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        opponent: {
          id: string;
          score: number;
          linescore: string | undefined;
          shootout_score: number | undefined;
          forfeit: boolean | undefined;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        periods: number;
        sport_id: number;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        title_text: string;
        image: string;
        long_status_text: string;
        clock_text: string | undefined;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          is_home: boolean;
          score: number;
          linescore: string | undefined;
          shootout_score: number | undefined;
          forfeit: boolean | undefined;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        opponent: {
          id: string;
          score: number;
          linescore: string | undefined;
          shootout_score: number | undefined;
          forfeit: boolean | undefined;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        game_snapshot: { possession_game_team_id: string | undefined } | undefined;
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        periods: number;
        sport_id: number;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        title_text: string;
        image: string;
        long_status_text: string;
        clock_text: string | undefined;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          is_home: boolean;
          score: number;
          linescore: string | undefined;
          shootout_score: number | undefined;
          forfeit: boolean | undefined;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        opponent: {
          id: string;
          score: number;
          linescore: string | undefined;
          shootout_score: number | undefined;
          forfeit: boolean | undefined;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      };
};

export type AdminTeamGamesScoreQueryGameTeamFieldsFragment = {
  score: number;
  linescore: string | undefined;
  shootout_score: number | undefined;
  forfeit: boolean | undefined;
};

export type AdminTeamGamesScoreQueryTeamFieldsFragment = {
  id: string;
  name: string;
  image: string | undefined;
  abbrev: string;
  mascot: string | undefined;
  web_path: string;
  location_text: string;
};

export type AdminTeamGamesShowQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  team_id: Scalars['Int']['input'];
}>;

export type AdminTeamGamesShowQuery = {
  game:
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        clock_text: string | undefined;
        mapped_from_partner: boolean | undefined;
        source: string | undefined;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        sport_id: number;
        title_text: string;
        image: string;
        long_status_text: string;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          is_home: boolean;
          score: number;
          forfeit: boolean | undefined;
          standing: { overallRecord: string; leagueRecord: string };
          team: {
            id: string;
            name: string;
            sport_id: number;
            gender_id: number;
            level_id: number;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
            state: { name: string; abbrev: string } | undefined;
          };
        };
        opponent: {
          id: string;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        clock_text: string | undefined;
        mapped_from_partner: boolean | undefined;
        source: string | undefined;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        sport_id: number;
        title_text: string;
        image: string;
        long_status_text: string;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          is_home: boolean;
          score: number;
          forfeit: boolean | undefined;
          standing: { overallRecord: string; leagueRecord: string };
          team: {
            id: string;
            name: string;
            sport_id: number;
            gender_id: number;
            level_id: number;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
            state: { name: string; abbrev: string } | undefined;
          };
        };
        opponent: {
          id: string;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        game_snapshot: { possession_game_team_id: string | undefined } | undefined;
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        clock_text: string | undefined;
        mapped_from_partner: boolean | undefined;
        source: string | undefined;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        sport_id: number;
        title_text: string;
        image: string;
        long_status_text: string;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          is_home: boolean;
          score: number;
          forfeit: boolean | undefined;
          standing: { overallRecord: string; leagueRecord: string };
          team: {
            id: string;
            name: string;
            sport_id: number;
            gender_id: number;
            level_id: number;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
            state: { name: string; abbrev: string } | undefined;
          };
        };
        opponent: {
          id: string;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      };
};

export type AdminTeamGamesStatsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  team_id: Scalars['Int']['input'];
}>;

export type AdminTeamGamesStatsQuery = {
  game:
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        periods: number;
        sport_id: number;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        title_text: string;
        image: string;
        long_status_text: string;
        clock_text: string | undefined;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          id: string;
          is_home: boolean;
          forfeit: boolean | undefined;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
          game_players:
            | Array<{
                id: string;
                jersey_number: string | undefined;
                name: string | undefined;
                first_name: string | undefined;
                last_name: string | undefined;
                stat:
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      at_bats: number | undefined;
                      runs: number | undefined;
                      singles: number | undefined;
                      doubles: number | undefined;
                      triples: number | undefined;
                      home_runs: number | undefined;
                      runs_batted_in: number | undefined;
                      sacrifice_flies: number | undefined;
                      sacrifice_bunts: number | undefined;
                      walks: number | undefined;
                      strikeouts_off: number | undefined;
                      hit_by_pitch: number | undefined;
                      reached_on_error: number | undefined;
                      fielders_choice: number | undefined;
                      runners_left_on_base: number | undefined;
                      runners_left_in_scoring_position: number | undefined;
                      stolen_bases: number | undefined;
                      stolen_base_attempts: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      holds: number | undefined;
                      saves: number | undefined;
                      complete_game: number | undefined;
                      innings_pitched: string | undefined;
                      batters_faced: number | undefined;
                      runs_allowed: number | undefined;
                      earned_runs_allowed: number | undefined;
                      singles_allowed: number | undefined;
                      doubles_allowed: number | undefined;
                      triples_allowed: number | undefined;
                      home_runs_allowed: number | undefined;
                      walks_allowed: number | undefined;
                      strikeouts_def: number | undefined;
                      hit_batter: number | undefined;
                      strikes_thrown: number | undefined;
                      pitch_count: number | undefined;
                      put_outs: number | undefined;
                      assists: number | undefined;
                      fielding_errors: number | undefined;
                      double_plays: number | undefined;
                      pickoffs: number | undefined;
                      caught_stealing: number | undefined;
                      passed_balls: number | undefined;
                      innings_played: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      periods_played: number | undefined;
                      two_points_made: number | undefined;
                      two_points_attempted: number | undefined;
                      three_points_made: number | undefined;
                      three_points_attempted: number | undefined;
                      free_throws_made: number | undefined;
                      free_throws_attempted: number | undefined;
                      offensive_rebounds: number | undefined;
                      defensive_rebounds: number | undefined;
                      assists: number | undefined;
                      steals: number | undefined;
                      blocks: number | undefined;
                      turnovers: number | undefined;
                      personal_fouls: number | undefined;
                      points: number | undefined;
                      quarters_played: number | undefined;
                      technical_fouls: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_aces: number | undefined;
                      service_errors: number | undefined;
                      points: number | undefined;
                      total_attack_attempts: number | undefined;
                      kills: number | undefined;
                      attack_errors: number | undefined;
                      receptions: number | undefined;
                      receiving_errors: number | undefined;
                      solo_blocks: number | undefined;
                      block_assists: number | undefined;
                      blocking_errors: number | undefined;
                      assists: number | undefined;
                      ball_handling_errors: number | undefined;
                      digs: number | undefined;
                      dig_errors: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      assists: number | undefined;
                      defensive_save: number | undefined;
                      penalty_corners: number | undefined;
                      fouls: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      ties: number | undefined;
                      goals_against: number | undefined;
                      goalie_saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      passing_completions: number | undefined;
                      passing_attempts: number | undefined;
                      passing_yards: number | undefined;
                      passing_sacks: number | undefined;
                      passing_touchdowns: number | undefined;
                      passing_interceptions: number | undefined;
                      rushing_attempts: number | undefined;
                      rushing_yards: number | undefined;
                      rushing_longest: number | undefined;
                      rushing_touchdowns: number | undefined;
                      rushing_fumbles: number | undefined;
                      receiving_receptions: number | undefined;
                      targets: number | undefined;
                      receiving_yards: number | undefined;
                      receiving_touchdowns: number | undefined;
                      receiving_longest: number | undefined;
                      receiving_fumbles: number | undefined;
                      solo_tackles: number | undefined;
                      assisted_tackles: number | undefined;
                      tackles_for_loss: number | undefined;
                      defensive_sacks: number | undefined;
                      yards_lost: number | undefined;
                      hurries: number | undefined;
                      qb_hits: number | undefined;
                      defensive_passes_defended: number | undefined;
                      defensive_interceptions: number | undefined;
                      defensive_interception_yards: number | undefined;
                      defensive_fumbles_forced: number | undefined;
                      defensive_fumbles_recovered: number | undefined;
                      defensive_blocked_punts: number | undefined;
                      defensive_blocked_field_goals: number | undefined;
                      defensive_touchdown: number | undefined;
                      field_goals_made: number | undefined;
                      field_goals_attempts: number | undefined;
                      field_goals_longest: number | undefined;
                      pat_made: number | undefined;
                      pat_attempts: number | undefined;
                      points: number | undefined;
                      kickoffs: number | undefined;
                      kickoff_yards: number | undefined;
                      kickoffs_long: number | undefined;
                      touchbacks: number | undefined;
                      punts: number | undefined;
                      punt_yards: number | undefined;
                      punt_long: number | undefined;
                      punts_inside_20_yard_line: number | undefined;
                      total_kick_returns: number | undefined;
                      kickoff_return_yards: number | undefined;
                      kick_return_long: number | undefined;
                      kick_return_touchdowns: number | undefined;
                      total_punt_returns: number | undefined;
                      punt_return_yards: number | undefined;
                      punt_return_long: number | undefined;
                      punt_return_touchdowns: number | undefined;
                      snaps_played: number | undefined;
                      penalties: number | undefined;
                      concussions: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      passing_completions: number | undefined;
                      passing_attempts: number | undefined;
                      passing_yards: number | undefined;
                      passing_sacks: number | undefined;
                      passing_touchdowns: number | undefined;
                      passing_interceptions: number | undefined;
                      rushing_attempts: number | undefined;
                      rushing_yards: number | undefined;
                      rushing_longest: number | undefined;
                      rushing_touchdowns: number | undefined;
                      rushing_fumbles: number | undefined;
                      receiving_receptions: number | undefined;
                      targets: number | undefined;
                      receiving_yards: number | undefined;
                      receiving_touchdowns: number | undefined;
                      receiving_longest: number | undefined;
                      receiving_fumbles: number | undefined;
                      solo_tackles: number | undefined;
                      assisted_tackles: number | undefined;
                      tackles_for_loss: number | undefined;
                      defensive_sacks: number | undefined;
                      yards_lost: number | undefined;
                      hurries: number | undefined;
                      qb_hits: number | undefined;
                      defensive_passes_defended: number | undefined;
                      defensive_interceptions: number | undefined;
                      defensive_interception_yards: number | undefined;
                      defensive_fumbles_forced: number | undefined;
                      defensive_fumbles_recovered: number | undefined;
                      defensive_blocked_punts: number | undefined;
                      defensive_blocked_field_goals: number | undefined;
                      defensive_touchdown: number | undefined;
                      field_goals_made: number | undefined;
                      field_goals_attempts: number | undefined;
                      field_goals_longest: number | undefined;
                      pat_made: number | undefined;
                      pat_attempts: number | undefined;
                      points: number | undefined;
                      kickoffs: number | undefined;
                      kickoff_yards: number | undefined;
                      kickoffs_long: number | undefined;
                      touchbacks: number | undefined;
                      punts: number | undefined;
                      punt_yards: number | undefined;
                      punt_long: number | undefined;
                      punts_inside_20_yard_line: number | undefined;
                      total_kick_returns: number | undefined;
                      kickoff_return_yards: number | undefined;
                      kick_return_long: number | undefined;
                      kick_return_touchdowns: number | undefined;
                      total_punt_returns: number | undefined;
                      punt_return_yards: number | undefined;
                      punt_return_long: number | undefined;
                      punt_return_touchdowns: number | undefined;
                      snaps_played: number | undefined;
                      penalties: number | undefined;
                      concussions: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      events_played: number | undefined;
                      rounds_played: number | undefined;
                      total_strokes: number | undefined;
                      vs_par: number | undefined;
                      eagles: number | undefined;
                      birdies: number | undefined;
                      pars: number | undefined;
                      bogeys: number | undefined;
                      finish: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      time_on_ice: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      penalties_in_minutes: number | undefined;
                      power_play_goals: number | undefined;
                      power_play_assists: number | undefined;
                      short_hand_goals: number | undefined;
                      short_hand_assists: number | undefined;
                      shots: number | undefined;
                      games_started: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      overtime_losses: number | undefined;
                      saves: number | undefined;
                      goals_against: number | undefined;
                      shots_against: number | undefined;
                      shootout_shorts_against: number | undefined;
                      shootout_saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      ground_balls: number | undefined;
                      faceoffs_won: number | undefined;
                      faceoffs_lost: number | undefined;
                      turnovers: number | undefined;
                      clears: number | undefined;
                      penalties: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      ties: number | undefined;
                      minutes_in_goal: number | undefined;
                      goals_against: number | undefined;
                      saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      penalty_kick_attempts: number | undefined;
                      penalty_kick_goals: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      draws: number | undefined;
                      goals_against: number | undefined;
                      saves: number | undefined;
                      yellow_cards: number | undefined;
                      red_cards: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      at_bats: number | undefined;
                      runs: number | undefined;
                      singles: number | undefined;
                      doubles: number | undefined;
                      triples: number | undefined;
                      home_runs: number | undefined;
                      runs_batted_in: number | undefined;
                      sacrifice_flies: number | undefined;
                      sacrifice_bunts: number | undefined;
                      walks: number | undefined;
                      strikeouts_off: number | undefined;
                      hit_by_pitch: number | undefined;
                      reached_on_error: number | undefined;
                      fielders_choice: number | undefined;
                      runners_left_on_base: number | undefined;
                      runners_left_in_scoring_position: number | undefined;
                      stolen_bases: number | undefined;
                      stolen_base_attempts: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      holds: number | undefined;
                      saves: number | undefined;
                      complete_game: number | undefined;
                      innings_pitched: string | undefined;
                      batters_faced: number | undefined;
                      runs_allowed: number | undefined;
                      earned_runs_allowed: number | undefined;
                      singles_allowed: number | undefined;
                      doubles_allowed: number | undefined;
                      triples_allowed: number | undefined;
                      home_runs_allowed: number | undefined;
                      walks_allowed: number | undefined;
                      strikeouts_def: number | undefined;
                      hit_batter: number | undefined;
                      strikes_thrown: number | undefined;
                      pitch_count: number | undefined;
                      put_outs: number | undefined;
                      assists: number | undefined;
                      fielding_errors: number | undefined;
                      double_plays: number | undefined;
                      pickoffs: number | undefined;
                      caught_stealing: number | undefined;
                      passed_balls: number | undefined;
                      innings_played: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | { points: number | undefined }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_points_won: number | undefined;
                      service_aces: number | undefined;
                      service_faults: number | undefined;
                      double_faults: number | undefined;
                      points: number | undefined;
                      returns_attempted: number | undefined;
                      return_points_won: number | undefined;
                      break_point_attempts: number | undefined;
                      break_points_won: number | undefined;
                      errors_stat: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | { points: number | undefined }
                  | { points: number | undefined }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_aces: number | undefined;
                      service_errors: number | undefined;
                      points: number | undefined;
                      total_attack_attempts: number | undefined;
                      kills: number | undefined;
                      attack_errors: number | undefined;
                      receptions: number | undefined;
                      receiving_errors: number | undefined;
                      solo_blocks: number | undefined;
                      block_assists: number | undefined;
                      blocking_errors: number | undefined;
                      assists: number | undefined;
                      ball_handling_errors: number | undefined;
                      digs: number | undefined;
                      dig_errors: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      shots: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      steals: number | undefined;
                      ejects_drawn: number | undefined;
                      ejects_given: number | undefined;
                      fast_break: number | undefined;
                      sprints_won: number | undefined;
                      sprints_attempted: number | undefined;
                      games_started: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      minutes_in_goal: number | undefined;
                      shots_against: number | undefined;
                      saves: number | undefined;
                      goals_against: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    };
              }>
            | undefined;
        };
        opponent: {
          id: string;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        periods: number;
        sport_id: number;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        title_text: string;
        image: string;
        long_status_text: string;
        clock_text: string | undefined;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          id: string;
          is_home: boolean;
          forfeit: boolean | undefined;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
          game_players:
            | Array<{
                id: string;
                jersey_number: string | undefined;
                name: string | undefined;
                first_name: string | undefined;
                last_name: string | undefined;
                stat:
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      at_bats: number | undefined;
                      runs: number | undefined;
                      singles: number | undefined;
                      doubles: number | undefined;
                      triples: number | undefined;
                      home_runs: number | undefined;
                      runs_batted_in: number | undefined;
                      sacrifice_flies: number | undefined;
                      sacrifice_bunts: number | undefined;
                      walks: number | undefined;
                      strikeouts_off: number | undefined;
                      hit_by_pitch: number | undefined;
                      reached_on_error: number | undefined;
                      fielders_choice: number | undefined;
                      runners_left_on_base: number | undefined;
                      runners_left_in_scoring_position: number | undefined;
                      stolen_bases: number | undefined;
                      stolen_base_attempts: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      holds: number | undefined;
                      saves: number | undefined;
                      complete_game: number | undefined;
                      innings_pitched: string | undefined;
                      batters_faced: number | undefined;
                      runs_allowed: number | undefined;
                      earned_runs_allowed: number | undefined;
                      singles_allowed: number | undefined;
                      doubles_allowed: number | undefined;
                      triples_allowed: number | undefined;
                      home_runs_allowed: number | undefined;
                      walks_allowed: number | undefined;
                      strikeouts_def: number | undefined;
                      hit_batter: number | undefined;
                      strikes_thrown: number | undefined;
                      pitch_count: number | undefined;
                      put_outs: number | undefined;
                      assists: number | undefined;
                      fielding_errors: number | undefined;
                      double_plays: number | undefined;
                      pickoffs: number | undefined;
                      caught_stealing: number | undefined;
                      passed_balls: number | undefined;
                      innings_played: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      periods_played: number | undefined;
                      two_points_made: number | undefined;
                      two_points_attempted: number | undefined;
                      three_points_made: number | undefined;
                      three_points_attempted: number | undefined;
                      free_throws_made: number | undefined;
                      free_throws_attempted: number | undefined;
                      offensive_rebounds: number | undefined;
                      defensive_rebounds: number | undefined;
                      assists: number | undefined;
                      steals: number | undefined;
                      blocks: number | undefined;
                      turnovers: number | undefined;
                      personal_fouls: number | undefined;
                      points: number | undefined;
                      quarters_played: number | undefined;
                      technical_fouls: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_aces: number | undefined;
                      service_errors: number | undefined;
                      points: number | undefined;
                      total_attack_attempts: number | undefined;
                      kills: number | undefined;
                      attack_errors: number | undefined;
                      receptions: number | undefined;
                      receiving_errors: number | undefined;
                      solo_blocks: number | undefined;
                      block_assists: number | undefined;
                      blocking_errors: number | undefined;
                      assists: number | undefined;
                      ball_handling_errors: number | undefined;
                      digs: number | undefined;
                      dig_errors: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      assists: number | undefined;
                      defensive_save: number | undefined;
                      penalty_corners: number | undefined;
                      fouls: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      ties: number | undefined;
                      goals_against: number | undefined;
                      goalie_saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      passing_completions: number | undefined;
                      passing_attempts: number | undefined;
                      passing_yards: number | undefined;
                      passing_sacks: number | undefined;
                      passing_touchdowns: number | undefined;
                      passing_interceptions: number | undefined;
                      rushing_attempts: number | undefined;
                      rushing_yards: number | undefined;
                      rushing_longest: number | undefined;
                      rushing_touchdowns: number | undefined;
                      rushing_fumbles: number | undefined;
                      receiving_receptions: number | undefined;
                      targets: number | undefined;
                      receiving_yards: number | undefined;
                      receiving_touchdowns: number | undefined;
                      receiving_longest: number | undefined;
                      receiving_fumbles: number | undefined;
                      solo_tackles: number | undefined;
                      assisted_tackles: number | undefined;
                      tackles_for_loss: number | undefined;
                      defensive_sacks: number | undefined;
                      yards_lost: number | undefined;
                      hurries: number | undefined;
                      qb_hits: number | undefined;
                      defensive_passes_defended: number | undefined;
                      defensive_interceptions: number | undefined;
                      defensive_interception_yards: number | undefined;
                      defensive_fumbles_forced: number | undefined;
                      defensive_fumbles_recovered: number | undefined;
                      defensive_blocked_punts: number | undefined;
                      defensive_blocked_field_goals: number | undefined;
                      defensive_touchdown: number | undefined;
                      field_goals_made: number | undefined;
                      field_goals_attempts: number | undefined;
                      field_goals_longest: number | undefined;
                      pat_made: number | undefined;
                      pat_attempts: number | undefined;
                      points: number | undefined;
                      kickoffs: number | undefined;
                      kickoff_yards: number | undefined;
                      kickoffs_long: number | undefined;
                      touchbacks: number | undefined;
                      punts: number | undefined;
                      punt_yards: number | undefined;
                      punt_long: number | undefined;
                      punts_inside_20_yard_line: number | undefined;
                      total_kick_returns: number | undefined;
                      kickoff_return_yards: number | undefined;
                      kick_return_long: number | undefined;
                      kick_return_touchdowns: number | undefined;
                      total_punt_returns: number | undefined;
                      punt_return_yards: number | undefined;
                      punt_return_long: number | undefined;
                      punt_return_touchdowns: number | undefined;
                      snaps_played: number | undefined;
                      penalties: number | undefined;
                      concussions: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      passing_completions: number | undefined;
                      passing_attempts: number | undefined;
                      passing_yards: number | undefined;
                      passing_sacks: number | undefined;
                      passing_touchdowns: number | undefined;
                      passing_interceptions: number | undefined;
                      rushing_attempts: number | undefined;
                      rushing_yards: number | undefined;
                      rushing_longest: number | undefined;
                      rushing_touchdowns: number | undefined;
                      rushing_fumbles: number | undefined;
                      receiving_receptions: number | undefined;
                      targets: number | undefined;
                      receiving_yards: number | undefined;
                      receiving_touchdowns: number | undefined;
                      receiving_longest: number | undefined;
                      receiving_fumbles: number | undefined;
                      solo_tackles: number | undefined;
                      assisted_tackles: number | undefined;
                      tackles_for_loss: number | undefined;
                      defensive_sacks: number | undefined;
                      yards_lost: number | undefined;
                      hurries: number | undefined;
                      qb_hits: number | undefined;
                      defensive_passes_defended: number | undefined;
                      defensive_interceptions: number | undefined;
                      defensive_interception_yards: number | undefined;
                      defensive_fumbles_forced: number | undefined;
                      defensive_fumbles_recovered: number | undefined;
                      defensive_blocked_punts: number | undefined;
                      defensive_blocked_field_goals: number | undefined;
                      defensive_touchdown: number | undefined;
                      field_goals_made: number | undefined;
                      field_goals_attempts: number | undefined;
                      field_goals_longest: number | undefined;
                      pat_made: number | undefined;
                      pat_attempts: number | undefined;
                      points: number | undefined;
                      kickoffs: number | undefined;
                      kickoff_yards: number | undefined;
                      kickoffs_long: number | undefined;
                      touchbacks: number | undefined;
                      punts: number | undefined;
                      punt_yards: number | undefined;
                      punt_long: number | undefined;
                      punts_inside_20_yard_line: number | undefined;
                      total_kick_returns: number | undefined;
                      kickoff_return_yards: number | undefined;
                      kick_return_long: number | undefined;
                      kick_return_touchdowns: number | undefined;
                      total_punt_returns: number | undefined;
                      punt_return_yards: number | undefined;
                      punt_return_long: number | undefined;
                      punt_return_touchdowns: number | undefined;
                      snaps_played: number | undefined;
                      penalties: number | undefined;
                      concussions: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      events_played: number | undefined;
                      rounds_played: number | undefined;
                      total_strokes: number | undefined;
                      vs_par: number | undefined;
                      eagles: number | undefined;
                      birdies: number | undefined;
                      pars: number | undefined;
                      bogeys: number | undefined;
                      finish: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      time_on_ice: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      penalties_in_minutes: number | undefined;
                      power_play_goals: number | undefined;
                      power_play_assists: number | undefined;
                      short_hand_goals: number | undefined;
                      short_hand_assists: number | undefined;
                      shots: number | undefined;
                      games_started: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      overtime_losses: number | undefined;
                      saves: number | undefined;
                      goals_against: number | undefined;
                      shots_against: number | undefined;
                      shootout_shorts_against: number | undefined;
                      shootout_saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      ground_balls: number | undefined;
                      faceoffs_won: number | undefined;
                      faceoffs_lost: number | undefined;
                      turnovers: number | undefined;
                      clears: number | undefined;
                      penalties: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      ties: number | undefined;
                      minutes_in_goal: number | undefined;
                      goals_against: number | undefined;
                      saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      penalty_kick_attempts: number | undefined;
                      penalty_kick_goals: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      draws: number | undefined;
                      goals_against: number | undefined;
                      saves: number | undefined;
                      yellow_cards: number | undefined;
                      red_cards: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      at_bats: number | undefined;
                      runs: number | undefined;
                      singles: number | undefined;
                      doubles: number | undefined;
                      triples: number | undefined;
                      home_runs: number | undefined;
                      runs_batted_in: number | undefined;
                      sacrifice_flies: number | undefined;
                      sacrifice_bunts: number | undefined;
                      walks: number | undefined;
                      strikeouts_off: number | undefined;
                      hit_by_pitch: number | undefined;
                      reached_on_error: number | undefined;
                      fielders_choice: number | undefined;
                      runners_left_on_base: number | undefined;
                      runners_left_in_scoring_position: number | undefined;
                      stolen_bases: number | undefined;
                      stolen_base_attempts: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      holds: number | undefined;
                      saves: number | undefined;
                      complete_game: number | undefined;
                      innings_pitched: string | undefined;
                      batters_faced: number | undefined;
                      runs_allowed: number | undefined;
                      earned_runs_allowed: number | undefined;
                      singles_allowed: number | undefined;
                      doubles_allowed: number | undefined;
                      triples_allowed: number | undefined;
                      home_runs_allowed: number | undefined;
                      walks_allowed: number | undefined;
                      strikeouts_def: number | undefined;
                      hit_batter: number | undefined;
                      strikes_thrown: number | undefined;
                      pitch_count: number | undefined;
                      put_outs: number | undefined;
                      assists: number | undefined;
                      fielding_errors: number | undefined;
                      double_plays: number | undefined;
                      pickoffs: number | undefined;
                      caught_stealing: number | undefined;
                      passed_balls: number | undefined;
                      innings_played: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | { points: number | undefined }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_points_won: number | undefined;
                      service_aces: number | undefined;
                      service_faults: number | undefined;
                      double_faults: number | undefined;
                      points: number | undefined;
                      returns_attempted: number | undefined;
                      return_points_won: number | undefined;
                      break_point_attempts: number | undefined;
                      break_points_won: number | undefined;
                      errors_stat: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | { points: number | undefined }
                  | { points: number | undefined }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_aces: number | undefined;
                      service_errors: number | undefined;
                      points: number | undefined;
                      total_attack_attempts: number | undefined;
                      kills: number | undefined;
                      attack_errors: number | undefined;
                      receptions: number | undefined;
                      receiving_errors: number | undefined;
                      solo_blocks: number | undefined;
                      block_assists: number | undefined;
                      blocking_errors: number | undefined;
                      assists: number | undefined;
                      ball_handling_errors: number | undefined;
                      digs: number | undefined;
                      dig_errors: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      shots: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      steals: number | undefined;
                      ejects_drawn: number | undefined;
                      ejects_given: number | undefined;
                      fast_break: number | undefined;
                      sprints_won: number | undefined;
                      sprints_attempted: number | undefined;
                      games_started: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      minutes_in_goal: number | undefined;
                      shots_against: number | undefined;
                      saves: number | undefined;
                      goals_against: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    };
              }>
            | undefined;
        };
        opponent: {
          id: string;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        game_snapshot: { possession_game_team_id: string | undefined } | undefined;
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        short_status_text: string | undefined;
        medium_status_text: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        facebook_path: string | undefined;
        twitter_path: string | undefined;
        web_path: string;
        periods: number;
        sport_id: number;
        short_result: string | undefined;
        score_text: string | undefined;
        gender_sport: string | undefined;
        title_text: string;
        image: string;
        long_status_text: string;
        clock_text: string | undefined;
        web_boxscore_path: string | undefined;
        web_scorebookcast_path: string | undefined;
        web_recap_path: string | undefined;
        webPhotosPath: string | undefined;
        web_preview_path: string | undefined;
        game_type: { id: number; name: string | undefined } | undefined;
        featured: {
          id: string;
          is_home: boolean;
          forfeit: boolean | undefined;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
          game_players:
            | Array<{
                id: string;
                jersey_number: string | undefined;
                name: string | undefined;
                first_name: string | undefined;
                last_name: string | undefined;
                stat:
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      at_bats: number | undefined;
                      runs: number | undefined;
                      singles: number | undefined;
                      doubles: number | undefined;
                      triples: number | undefined;
                      home_runs: number | undefined;
                      runs_batted_in: number | undefined;
                      sacrifice_flies: number | undefined;
                      sacrifice_bunts: number | undefined;
                      walks: number | undefined;
                      strikeouts_off: number | undefined;
                      hit_by_pitch: number | undefined;
                      reached_on_error: number | undefined;
                      fielders_choice: number | undefined;
                      runners_left_on_base: number | undefined;
                      runners_left_in_scoring_position: number | undefined;
                      stolen_bases: number | undefined;
                      stolen_base_attempts: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      holds: number | undefined;
                      saves: number | undefined;
                      complete_game: number | undefined;
                      innings_pitched: string | undefined;
                      batters_faced: number | undefined;
                      runs_allowed: number | undefined;
                      earned_runs_allowed: number | undefined;
                      singles_allowed: number | undefined;
                      doubles_allowed: number | undefined;
                      triples_allowed: number | undefined;
                      home_runs_allowed: number | undefined;
                      walks_allowed: number | undefined;
                      strikeouts_def: number | undefined;
                      hit_batter: number | undefined;
                      strikes_thrown: number | undefined;
                      pitch_count: number | undefined;
                      put_outs: number | undefined;
                      assists: number | undefined;
                      fielding_errors: number | undefined;
                      double_plays: number | undefined;
                      pickoffs: number | undefined;
                      caught_stealing: number | undefined;
                      passed_balls: number | undefined;
                      innings_played: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      periods_played: number | undefined;
                      two_points_made: number | undefined;
                      two_points_attempted: number | undefined;
                      three_points_made: number | undefined;
                      three_points_attempted: number | undefined;
                      free_throws_made: number | undefined;
                      free_throws_attempted: number | undefined;
                      offensive_rebounds: number | undefined;
                      defensive_rebounds: number | undefined;
                      assists: number | undefined;
                      steals: number | undefined;
                      blocks: number | undefined;
                      turnovers: number | undefined;
                      personal_fouls: number | undefined;
                      points: number | undefined;
                      quarters_played: number | undefined;
                      technical_fouls: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_aces: number | undefined;
                      service_errors: number | undefined;
                      points: number | undefined;
                      total_attack_attempts: number | undefined;
                      kills: number | undefined;
                      attack_errors: number | undefined;
                      receptions: number | undefined;
                      receiving_errors: number | undefined;
                      solo_blocks: number | undefined;
                      block_assists: number | undefined;
                      blocking_errors: number | undefined;
                      assists: number | undefined;
                      ball_handling_errors: number | undefined;
                      digs: number | undefined;
                      dig_errors: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      assists: number | undefined;
                      defensive_save: number | undefined;
                      penalty_corners: number | undefined;
                      fouls: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      ties: number | undefined;
                      goals_against: number | undefined;
                      goalie_saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      passing_completions: number | undefined;
                      passing_attempts: number | undefined;
                      passing_yards: number | undefined;
                      passing_sacks: number | undefined;
                      passing_touchdowns: number | undefined;
                      passing_interceptions: number | undefined;
                      rushing_attempts: number | undefined;
                      rushing_yards: number | undefined;
                      rushing_longest: number | undefined;
                      rushing_touchdowns: number | undefined;
                      rushing_fumbles: number | undefined;
                      receiving_receptions: number | undefined;
                      targets: number | undefined;
                      receiving_yards: number | undefined;
                      receiving_touchdowns: number | undefined;
                      receiving_longest: number | undefined;
                      receiving_fumbles: number | undefined;
                      solo_tackles: number | undefined;
                      assisted_tackles: number | undefined;
                      tackles_for_loss: number | undefined;
                      defensive_sacks: number | undefined;
                      yards_lost: number | undefined;
                      hurries: number | undefined;
                      qb_hits: number | undefined;
                      defensive_passes_defended: number | undefined;
                      defensive_interceptions: number | undefined;
                      defensive_interception_yards: number | undefined;
                      defensive_fumbles_forced: number | undefined;
                      defensive_fumbles_recovered: number | undefined;
                      defensive_blocked_punts: number | undefined;
                      defensive_blocked_field_goals: number | undefined;
                      defensive_touchdown: number | undefined;
                      field_goals_made: number | undefined;
                      field_goals_attempts: number | undefined;
                      field_goals_longest: number | undefined;
                      pat_made: number | undefined;
                      pat_attempts: number | undefined;
                      points: number | undefined;
                      kickoffs: number | undefined;
                      kickoff_yards: number | undefined;
                      kickoffs_long: number | undefined;
                      touchbacks: number | undefined;
                      punts: number | undefined;
                      punt_yards: number | undefined;
                      punt_long: number | undefined;
                      punts_inside_20_yard_line: number | undefined;
                      total_kick_returns: number | undefined;
                      kickoff_return_yards: number | undefined;
                      kick_return_long: number | undefined;
                      kick_return_touchdowns: number | undefined;
                      total_punt_returns: number | undefined;
                      punt_return_yards: number | undefined;
                      punt_return_long: number | undefined;
                      punt_return_touchdowns: number | undefined;
                      snaps_played: number | undefined;
                      penalties: number | undefined;
                      concussions: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      passing_completions: number | undefined;
                      passing_attempts: number | undefined;
                      passing_yards: number | undefined;
                      passing_sacks: number | undefined;
                      passing_touchdowns: number | undefined;
                      passing_interceptions: number | undefined;
                      rushing_attempts: number | undefined;
                      rushing_yards: number | undefined;
                      rushing_longest: number | undefined;
                      rushing_touchdowns: number | undefined;
                      rushing_fumbles: number | undefined;
                      receiving_receptions: number | undefined;
                      targets: number | undefined;
                      receiving_yards: number | undefined;
                      receiving_touchdowns: number | undefined;
                      receiving_longest: number | undefined;
                      receiving_fumbles: number | undefined;
                      solo_tackles: number | undefined;
                      assisted_tackles: number | undefined;
                      tackles_for_loss: number | undefined;
                      defensive_sacks: number | undefined;
                      yards_lost: number | undefined;
                      hurries: number | undefined;
                      qb_hits: number | undefined;
                      defensive_passes_defended: number | undefined;
                      defensive_interceptions: number | undefined;
                      defensive_interception_yards: number | undefined;
                      defensive_fumbles_forced: number | undefined;
                      defensive_fumbles_recovered: number | undefined;
                      defensive_blocked_punts: number | undefined;
                      defensive_blocked_field_goals: number | undefined;
                      defensive_touchdown: number | undefined;
                      field_goals_made: number | undefined;
                      field_goals_attempts: number | undefined;
                      field_goals_longest: number | undefined;
                      pat_made: number | undefined;
                      pat_attempts: number | undefined;
                      points: number | undefined;
                      kickoffs: number | undefined;
                      kickoff_yards: number | undefined;
                      kickoffs_long: number | undefined;
                      touchbacks: number | undefined;
                      punts: number | undefined;
                      punt_yards: number | undefined;
                      punt_long: number | undefined;
                      punts_inside_20_yard_line: number | undefined;
                      total_kick_returns: number | undefined;
                      kickoff_return_yards: number | undefined;
                      kick_return_long: number | undefined;
                      kick_return_touchdowns: number | undefined;
                      total_punt_returns: number | undefined;
                      punt_return_yards: number | undefined;
                      punt_return_long: number | undefined;
                      punt_return_touchdowns: number | undefined;
                      snaps_played: number | undefined;
                      penalties: number | undefined;
                      concussions: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      events_played: number | undefined;
                      rounds_played: number | undefined;
                      total_strokes: number | undefined;
                      vs_par: number | undefined;
                      eagles: number | undefined;
                      birdies: number | undefined;
                      pars: number | undefined;
                      bogeys: number | undefined;
                      finish: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      time_on_ice: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      penalties_in_minutes: number | undefined;
                      power_play_goals: number | undefined;
                      power_play_assists: number | undefined;
                      short_hand_goals: number | undefined;
                      short_hand_assists: number | undefined;
                      shots: number | undefined;
                      games_started: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      overtime_losses: number | undefined;
                      saves: number | undefined;
                      goals_against: number | undefined;
                      shots_against: number | undefined;
                      shootout_shorts_against: number | undefined;
                      shootout_saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      ground_balls: number | undefined;
                      faceoffs_won: number | undefined;
                      faceoffs_lost: number | undefined;
                      turnovers: number | undefined;
                      clears: number | undefined;
                      penalties: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      ties: number | undefined;
                      minutes_in_goal: number | undefined;
                      goals_against: number | undefined;
                      saves: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      minutes_played: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      shots: number | undefined;
                      shots_on_goal: number | undefined;
                      penalty_kick_attempts: number | undefined;
                      penalty_kick_goals: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      draws: number | undefined;
                      goals_against: number | undefined;
                      saves: number | undefined;
                      yellow_cards: number | undefined;
                      red_cards: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      games_started: number | undefined;
                      at_bats: number | undefined;
                      runs: number | undefined;
                      singles: number | undefined;
                      doubles: number | undefined;
                      triples: number | undefined;
                      home_runs: number | undefined;
                      runs_batted_in: number | undefined;
                      sacrifice_flies: number | undefined;
                      sacrifice_bunts: number | undefined;
                      walks: number | undefined;
                      strikeouts_off: number | undefined;
                      hit_by_pitch: number | undefined;
                      reached_on_error: number | undefined;
                      fielders_choice: number | undefined;
                      runners_left_on_base: number | undefined;
                      runners_left_in_scoring_position: number | undefined;
                      stolen_bases: number | undefined;
                      stolen_base_attempts: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      holds: number | undefined;
                      saves: number | undefined;
                      complete_game: number | undefined;
                      innings_pitched: string | undefined;
                      batters_faced: number | undefined;
                      runs_allowed: number | undefined;
                      earned_runs_allowed: number | undefined;
                      singles_allowed: number | undefined;
                      doubles_allowed: number | undefined;
                      triples_allowed: number | undefined;
                      home_runs_allowed: number | undefined;
                      walks_allowed: number | undefined;
                      strikeouts_def: number | undefined;
                      hit_batter: number | undefined;
                      strikes_thrown: number | undefined;
                      pitch_count: number | undefined;
                      put_outs: number | undefined;
                      assists: number | undefined;
                      fielding_errors: number | undefined;
                      double_plays: number | undefined;
                      pickoffs: number | undefined;
                      caught_stealing: number | undefined;
                      passed_balls: number | undefined;
                      innings_played: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    }
                  | { points: number | undefined }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_points_won: number | undefined;
                      service_aces: number | undefined;
                      service_faults: number | undefined;
                      double_faults: number | undefined;
                      points: number | undefined;
                      returns_attempted: number | undefined;
                      return_points_won: number | undefined;
                      break_point_attempts: number | undefined;
                      break_points_won: number | undefined;
                      errors_stat: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | { points: number | undefined }
                  | { points: number | undefined }
                  | {
                      games_played: number | undefined;
                      sets_played: number | undefined;
                      service_attempts: number | undefined;
                      service_aces: number | undefined;
                      service_errors: number | undefined;
                      points: number | undefined;
                      total_attack_attempts: number | undefined;
                      kills: number | undefined;
                      attack_errors: number | undefined;
                      receptions: number | undefined;
                      receiving_errors: number | undefined;
                      solo_blocks: number | undefined;
                      block_assists: number | undefined;
                      blocking_errors: number | undefined;
                      assists: number | undefined;
                      ball_handling_errors: number | undefined;
                      digs: number | undefined;
                      dig_errors: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                    }
                  | {
                      games_played: number | undefined;
                      shots: number | undefined;
                      goals: number | undefined;
                      assists: number | undefined;
                      steals: number | undefined;
                      ejects_drawn: number | undefined;
                      ejects_given: number | undefined;
                      fast_break: number | undefined;
                      sprints_won: number | undefined;
                      sprints_attempted: number | undefined;
                      games_started: number | undefined;
                      wins: number | undefined;
                      losses: number | undefined;
                      minutes_in_goal: number | undefined;
                      shots_against: number | undefined;
                      saves: number | undefined;
                      goals_against: number | undefined;
                      concussion: number | undefined;
                      ejections: number | undefined;
                      points: number | undefined;
                    };
              }>
            | undefined;
        };
        opponent: {
          id: string;
          score: number;
          team: {
            id: string;
            name: string;
            image: string | undefined;
            abbrev: string;
            mascot: string | undefined;
            web_path: string;
            location_text: string;
          };
        };
        level: { abbrev: string | undefined } | undefined;
        away: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
        home: {
          id: string;
          score: number;
          is_loser: boolean;
          standing: { overallRecord: string };
          team: {
            id: string;
            abbrev: string;
            name: string;
            mascot: string | undefined;
            web_path: string;
            image: string | undefined;
            hide: boolean | undefined;
          };
        };
      };
};

export type AdminTeamGamesStatsQueryGameTeamFieldsFragment = {
  id: string;
  score: number;
  forfeit: boolean | undefined;
  game_players:
    | Array<{
        id: string;
        jersey_number: string | undefined;
        name: string | undefined;
        first_name: string | undefined;
        last_name: string | undefined;
        stat:
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              at_bats: number | undefined;
              runs: number | undefined;
              singles: number | undefined;
              doubles: number | undefined;
              triples: number | undefined;
              home_runs: number | undefined;
              runs_batted_in: number | undefined;
              sacrifice_flies: number | undefined;
              sacrifice_bunts: number | undefined;
              walks: number | undefined;
              strikeouts_off: number | undefined;
              hit_by_pitch: number | undefined;
              reached_on_error: number | undefined;
              fielders_choice: number | undefined;
              runners_left_on_base: number | undefined;
              runners_left_in_scoring_position: number | undefined;
              stolen_bases: number | undefined;
              stolen_base_attempts: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              holds: number | undefined;
              saves: number | undefined;
              complete_game: number | undefined;
              innings_pitched: string | undefined;
              batters_faced: number | undefined;
              runs_allowed: number | undefined;
              earned_runs_allowed: number | undefined;
              singles_allowed: number | undefined;
              doubles_allowed: number | undefined;
              triples_allowed: number | undefined;
              home_runs_allowed: number | undefined;
              walks_allowed: number | undefined;
              strikeouts_def: number | undefined;
              hit_batter: number | undefined;
              strikes_thrown: number | undefined;
              pitch_count: number | undefined;
              put_outs: number | undefined;
              assists: number | undefined;
              fielding_errors: number | undefined;
              double_plays: number | undefined;
              pickoffs: number | undefined;
              caught_stealing: number | undefined;
              passed_balls: number | undefined;
              innings_played: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              periods_played: number | undefined;
              two_points_made: number | undefined;
              two_points_attempted: number | undefined;
              three_points_made: number | undefined;
              three_points_attempted: number | undefined;
              free_throws_made: number | undefined;
              free_throws_attempted: number | undefined;
              offensive_rebounds: number | undefined;
              defensive_rebounds: number | undefined;
              assists: number | undefined;
              steals: number | undefined;
              blocks: number | undefined;
              turnovers: number | undefined;
              personal_fouls: number | undefined;
              points: number | undefined;
              quarters_played: number | undefined;
              technical_fouls: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
            }
          | {
              games_played: number | undefined;
              sets_played: number | undefined;
              service_attempts: number | undefined;
              service_aces: number | undefined;
              service_errors: number | undefined;
              points: number | undefined;
              total_attack_attempts: number | undefined;
              kills: number | undefined;
              attack_errors: number | undefined;
              receptions: number | undefined;
              receiving_errors: number | undefined;
              solo_blocks: number | undefined;
              block_assists: number | undefined;
              blocking_errors: number | undefined;
              assists: number | undefined;
              ball_handling_errors: number | undefined;
              digs: number | undefined;
              dig_errors: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
            }
          | {
              games_played: number | undefined;
              minutes_played: number | undefined;
              goals: number | undefined;
              shots: number | undefined;
              shots_on_goal: number | undefined;
              assists: number | undefined;
              defensive_save: number | undefined;
              penalty_corners: number | undefined;
              fouls: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              ties: number | undefined;
              goals_against: number | undefined;
              goalie_saves: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              passing_completions: number | undefined;
              passing_attempts: number | undefined;
              passing_yards: number | undefined;
              passing_sacks: number | undefined;
              passing_touchdowns: number | undefined;
              passing_interceptions: number | undefined;
              rushing_attempts: number | undefined;
              rushing_yards: number | undefined;
              rushing_longest: number | undefined;
              rushing_touchdowns: number | undefined;
              rushing_fumbles: number | undefined;
              receiving_receptions: number | undefined;
              targets: number | undefined;
              receiving_yards: number | undefined;
              receiving_touchdowns: number | undefined;
              receiving_longest: number | undefined;
              receiving_fumbles: number | undefined;
              solo_tackles: number | undefined;
              assisted_tackles: number | undefined;
              tackles_for_loss: number | undefined;
              defensive_sacks: number | undefined;
              yards_lost: number | undefined;
              hurries: number | undefined;
              qb_hits: number | undefined;
              defensive_passes_defended: number | undefined;
              defensive_interceptions: number | undefined;
              defensive_interception_yards: number | undefined;
              defensive_fumbles_forced: number | undefined;
              defensive_fumbles_recovered: number | undefined;
              defensive_blocked_punts: number | undefined;
              defensive_blocked_field_goals: number | undefined;
              defensive_touchdown: number | undefined;
              field_goals_made: number | undefined;
              field_goals_attempts: number | undefined;
              field_goals_longest: number | undefined;
              pat_made: number | undefined;
              pat_attempts: number | undefined;
              points: number | undefined;
              kickoffs: number | undefined;
              kickoff_yards: number | undefined;
              kickoffs_long: number | undefined;
              touchbacks: number | undefined;
              punts: number | undefined;
              punt_yards: number | undefined;
              punt_long: number | undefined;
              punts_inside_20_yard_line: number | undefined;
              total_kick_returns: number | undefined;
              kickoff_return_yards: number | undefined;
              kick_return_long: number | undefined;
              kick_return_touchdowns: number | undefined;
              total_punt_returns: number | undefined;
              punt_return_yards: number | undefined;
              punt_return_long: number | undefined;
              punt_return_touchdowns: number | undefined;
              snaps_played: number | undefined;
              penalties: number | undefined;
              concussions: number | undefined;
              ejections: number | undefined;
            }
          | {
              games_played: number | undefined;
              passing_completions: number | undefined;
              passing_attempts: number | undefined;
              passing_yards: number | undefined;
              passing_sacks: number | undefined;
              passing_touchdowns: number | undefined;
              passing_interceptions: number | undefined;
              rushing_attempts: number | undefined;
              rushing_yards: number | undefined;
              rushing_longest: number | undefined;
              rushing_touchdowns: number | undefined;
              rushing_fumbles: number | undefined;
              receiving_receptions: number | undefined;
              targets: number | undefined;
              receiving_yards: number | undefined;
              receiving_touchdowns: number | undefined;
              receiving_longest: number | undefined;
              receiving_fumbles: number | undefined;
              solo_tackles: number | undefined;
              assisted_tackles: number | undefined;
              tackles_for_loss: number | undefined;
              defensive_sacks: number | undefined;
              yards_lost: number | undefined;
              hurries: number | undefined;
              qb_hits: number | undefined;
              defensive_passes_defended: number | undefined;
              defensive_interceptions: number | undefined;
              defensive_interception_yards: number | undefined;
              defensive_fumbles_forced: number | undefined;
              defensive_fumbles_recovered: number | undefined;
              defensive_blocked_punts: number | undefined;
              defensive_blocked_field_goals: number | undefined;
              defensive_touchdown: number | undefined;
              field_goals_made: number | undefined;
              field_goals_attempts: number | undefined;
              field_goals_longest: number | undefined;
              pat_made: number | undefined;
              pat_attempts: number | undefined;
              points: number | undefined;
              kickoffs: number | undefined;
              kickoff_yards: number | undefined;
              kickoffs_long: number | undefined;
              touchbacks: number | undefined;
              punts: number | undefined;
              punt_yards: number | undefined;
              punt_long: number | undefined;
              punts_inside_20_yard_line: number | undefined;
              total_kick_returns: number | undefined;
              kickoff_return_yards: number | undefined;
              kick_return_long: number | undefined;
              kick_return_touchdowns: number | undefined;
              total_punt_returns: number | undefined;
              punt_return_yards: number | undefined;
              punt_return_long: number | undefined;
              punt_return_touchdowns: number | undefined;
              snaps_played: number | undefined;
              penalties: number | undefined;
              concussions: number | undefined;
              ejections: number | undefined;
            }
          | {
              events_played: number | undefined;
              rounds_played: number | undefined;
              total_strokes: number | undefined;
              vs_par: number | undefined;
              eagles: number | undefined;
              birdies: number | undefined;
              pars: number | undefined;
              bogeys: number | undefined;
              finish: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              time_on_ice: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              penalties_in_minutes: number | undefined;
              power_play_goals: number | undefined;
              power_play_assists: number | undefined;
              short_hand_goals: number | undefined;
              short_hand_assists: number | undefined;
              shots: number | undefined;
              games_started: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              overtime_losses: number | undefined;
              saves: number | undefined;
              goals_against: number | undefined;
              shots_against: number | undefined;
              shootout_shorts_against: number | undefined;
              shootout_saves: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              minutes_played: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              shots: number | undefined;
              shots_on_goal: number | undefined;
              ground_balls: number | undefined;
              faceoffs_won: number | undefined;
              faceoffs_lost: number | undefined;
              turnovers: number | undefined;
              clears: number | undefined;
              penalties: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              ties: number | undefined;
              minutes_in_goal: number | undefined;
              goals_against: number | undefined;
              saves: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              minutes_played: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              shots: number | undefined;
              shots_on_goal: number | undefined;
              penalty_kick_attempts: number | undefined;
              penalty_kick_goals: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              draws: number | undefined;
              goals_against: number | undefined;
              saves: number | undefined;
              yellow_cards: number | undefined;
              red_cards: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              at_bats: number | undefined;
              runs: number | undefined;
              singles: number | undefined;
              doubles: number | undefined;
              triples: number | undefined;
              home_runs: number | undefined;
              runs_batted_in: number | undefined;
              sacrifice_flies: number | undefined;
              sacrifice_bunts: number | undefined;
              walks: number | undefined;
              strikeouts_off: number | undefined;
              hit_by_pitch: number | undefined;
              reached_on_error: number | undefined;
              fielders_choice: number | undefined;
              runners_left_on_base: number | undefined;
              runners_left_in_scoring_position: number | undefined;
              stolen_bases: number | undefined;
              stolen_base_attempts: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              holds: number | undefined;
              saves: number | undefined;
              complete_game: number | undefined;
              innings_pitched: string | undefined;
              batters_faced: number | undefined;
              runs_allowed: number | undefined;
              earned_runs_allowed: number | undefined;
              singles_allowed: number | undefined;
              doubles_allowed: number | undefined;
              triples_allowed: number | undefined;
              home_runs_allowed: number | undefined;
              walks_allowed: number | undefined;
              strikeouts_def: number | undefined;
              hit_batter: number | undefined;
              strikes_thrown: number | undefined;
              pitch_count: number | undefined;
              put_outs: number | undefined;
              assists: number | undefined;
              fielding_errors: number | undefined;
              double_plays: number | undefined;
              pickoffs: number | undefined;
              caught_stealing: number | undefined;
              passed_balls: number | undefined;
              innings_played: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
              points: number | undefined;
            }
          | { points: number | undefined }
          | {
              games_played: number | undefined;
              sets_played: number | undefined;
              service_attempts: number | undefined;
              service_points_won: number | undefined;
              service_aces: number | undefined;
              service_faults: number | undefined;
              double_faults: number | undefined;
              points: number | undefined;
              returns_attempted: number | undefined;
              return_points_won: number | undefined;
              break_point_attempts: number | undefined;
              break_points_won: number | undefined;
              errors_stat: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
            }
          | { points: number | undefined }
          | { points: number | undefined }
          | {
              games_played: number | undefined;
              sets_played: number | undefined;
              service_attempts: number | undefined;
              service_aces: number | undefined;
              service_errors: number | undefined;
              points: number | undefined;
              total_attack_attempts: number | undefined;
              kills: number | undefined;
              attack_errors: number | undefined;
              receptions: number | undefined;
              receiving_errors: number | undefined;
              solo_blocks: number | undefined;
              block_assists: number | undefined;
              blocking_errors: number | undefined;
              assists: number | undefined;
              ball_handling_errors: number | undefined;
              digs: number | undefined;
              dig_errors: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
            }
          | {
              games_played: number | undefined;
              shots: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              steals: number | undefined;
              ejects_drawn: number | undefined;
              ejects_given: number | undefined;
              fast_break: number | undefined;
              sprints_won: number | undefined;
              sprints_attempted: number | undefined;
              games_started: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              minutes_in_goal: number | undefined;
              shots_against: number | undefined;
              saves: number | undefined;
              goals_against: number | undefined;
              concussion: number | undefined;
              ejections: number | undefined;
              points: number | undefined;
            };
      }>
    | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsBaseballStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  at_bats: number | undefined;
  runs: number | undefined;
  singles: number | undefined;
  doubles: number | undefined;
  triples: number | undefined;
  home_runs: number | undefined;
  runs_batted_in: number | undefined;
  sacrifice_flies: number | undefined;
  sacrifice_bunts: number | undefined;
  walks: number | undefined;
  strikeouts_off: number | undefined;
  hit_by_pitch: number | undefined;
  reached_on_error: number | undefined;
  fielders_choice: number | undefined;
  runners_left_on_base: number | undefined;
  runners_left_in_scoring_position: number | undefined;
  stolen_bases: number | undefined;
  stolen_base_attempts: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  holds: number | undefined;
  saves: number | undefined;
  complete_game: number | undefined;
  innings_pitched: string | undefined;
  batters_faced: number | undefined;
  runs_allowed: number | undefined;
  earned_runs_allowed: number | undefined;
  singles_allowed: number | undefined;
  doubles_allowed: number | undefined;
  triples_allowed: number | undefined;
  home_runs_allowed: number | undefined;
  walks_allowed: number | undefined;
  strikeouts_def: number | undefined;
  hit_batter: number | undefined;
  strikes_thrown: number | undefined;
  pitch_count: number | undefined;
  put_outs: number | undefined;
  assists: number | undefined;
  fielding_errors: number | undefined;
  double_plays: number | undefined;
  pickoffs: number | undefined;
  caught_stealing: number | undefined;
  passed_balls: number | undefined;
  innings_played: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
  points: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsBasketballStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  periods_played: number | undefined;
  two_points_made: number | undefined;
  two_points_attempted: number | undefined;
  three_points_made: number | undefined;
  three_points_attempted: number | undefined;
  free_throws_made: number | undefined;
  free_throws_attempted: number | undefined;
  offensive_rebounds: number | undefined;
  defensive_rebounds: number | undefined;
  assists: number | undefined;
  steals: number | undefined;
  blocks: number | undefined;
  turnovers: number | undefined;
  personal_fouls: number | undefined;
  points: number | undefined;
  quarters_played: number | undefined;
  technical_fouls: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsBeachVolleyballStatFragment = {
  games_played: number | undefined;
  sets_played: number | undefined;
  service_attempts: number | undefined;
  service_aces: number | undefined;
  service_errors: number | undefined;
  points: number | undefined;
  total_attack_attempts: number | undefined;
  kills: number | undefined;
  attack_errors: number | undefined;
  receptions: number | undefined;
  receiving_errors: number | undefined;
  solo_blocks: number | undefined;
  block_assists: number | undefined;
  blocking_errors: number | undefined;
  assists: number | undefined;
  ball_handling_errors: number | undefined;
  digs: number | undefined;
  dig_errors: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsFieldHockeyStatFragment = {
  games_played: number | undefined;
  minutes_played: number | undefined;
  goals: number | undefined;
  shots: number | undefined;
  shots_on_goal: number | undefined;
  assists: number | undefined;
  defensive_save: number | undefined;
  penalty_corners: number | undefined;
  fouls: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  ties: number | undefined;
  goals_against: number | undefined;
  goalie_saves: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
  points: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsFlagFootballStatFragment = {
  games_played: number | undefined;
  passing_completions: number | undefined;
  passing_attempts: number | undefined;
  passing_yards: number | undefined;
  passing_sacks: number | undefined;
  passing_touchdowns: number | undefined;
  passing_interceptions: number | undefined;
  rushing_attempts: number | undefined;
  rushing_yards: number | undefined;
  rushing_longest: number | undefined;
  rushing_touchdowns: number | undefined;
  rushing_fumbles: number | undefined;
  receiving_receptions: number | undefined;
  targets: number | undefined;
  receiving_yards: number | undefined;
  receiving_touchdowns: number | undefined;
  receiving_longest: number | undefined;
  receiving_fumbles: number | undefined;
  solo_tackles: number | undefined;
  assisted_tackles: number | undefined;
  tackles_for_loss: number | undefined;
  defensive_sacks: number | undefined;
  yards_lost: number | undefined;
  hurries: number | undefined;
  qb_hits: number | undefined;
  defensive_passes_defended: number | undefined;
  defensive_interceptions: number | undefined;
  defensive_interception_yards: number | undefined;
  defensive_fumbles_forced: number | undefined;
  defensive_fumbles_recovered: number | undefined;
  defensive_blocked_punts: number | undefined;
  defensive_blocked_field_goals: number | undefined;
  defensive_touchdown: number | undefined;
  field_goals_made: number | undefined;
  field_goals_attempts: number | undefined;
  field_goals_longest: number | undefined;
  pat_made: number | undefined;
  pat_attempts: number | undefined;
  points: number | undefined;
  kickoffs: number | undefined;
  kickoff_yards: number | undefined;
  kickoffs_long: number | undefined;
  touchbacks: number | undefined;
  punts: number | undefined;
  punt_yards: number | undefined;
  punt_long: number | undefined;
  punts_inside_20_yard_line: number | undefined;
  total_kick_returns: number | undefined;
  kickoff_return_yards: number | undefined;
  kick_return_long: number | undefined;
  kick_return_touchdowns: number | undefined;
  total_punt_returns: number | undefined;
  punt_return_yards: number | undefined;
  punt_return_long: number | undefined;
  punt_return_touchdowns: number | undefined;
  snaps_played: number | undefined;
  penalties: number | undefined;
  concussions: number | undefined;
  ejections: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsFootballStatFragment = {
  games_played: number | undefined;
  passing_completions: number | undefined;
  passing_attempts: number | undefined;
  passing_yards: number | undefined;
  passing_sacks: number | undefined;
  passing_touchdowns: number | undefined;
  passing_interceptions: number | undefined;
  rushing_attempts: number | undefined;
  rushing_yards: number | undefined;
  rushing_longest: number | undefined;
  rushing_touchdowns: number | undefined;
  rushing_fumbles: number | undefined;
  receiving_receptions: number | undefined;
  targets: number | undefined;
  receiving_yards: number | undefined;
  receiving_touchdowns: number | undefined;
  receiving_longest: number | undefined;
  receiving_fumbles: number | undefined;
  solo_tackles: number | undefined;
  assisted_tackles: number | undefined;
  tackles_for_loss: number | undefined;
  defensive_sacks: number | undefined;
  yards_lost: number | undefined;
  hurries: number | undefined;
  qb_hits: number | undefined;
  defensive_passes_defended: number | undefined;
  defensive_interceptions: number | undefined;
  defensive_interception_yards: number | undefined;
  defensive_fumbles_forced: number | undefined;
  defensive_fumbles_recovered: number | undefined;
  defensive_blocked_punts: number | undefined;
  defensive_blocked_field_goals: number | undefined;
  defensive_touchdown: number | undefined;
  field_goals_made: number | undefined;
  field_goals_attempts: number | undefined;
  field_goals_longest: number | undefined;
  pat_made: number | undefined;
  pat_attempts: number | undefined;
  points: number | undefined;
  kickoffs: number | undefined;
  kickoff_yards: number | undefined;
  kickoffs_long: number | undefined;
  touchbacks: number | undefined;
  punts: number | undefined;
  punt_yards: number | undefined;
  punt_long: number | undefined;
  punts_inside_20_yard_line: number | undefined;
  total_kick_returns: number | undefined;
  kickoff_return_yards: number | undefined;
  kick_return_long: number | undefined;
  kick_return_touchdowns: number | undefined;
  total_punt_returns: number | undefined;
  punt_return_yards: number | undefined;
  punt_return_long: number | undefined;
  punt_return_touchdowns: number | undefined;
  snaps_played: number | undefined;
  penalties: number | undefined;
  concussions: number | undefined;
  ejections: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsGolfStatFragment = {
  events_played: number | undefined;
  rounds_played: number | undefined;
  total_strokes: number | undefined;
  vs_par: number | undefined;
  eagles: number | undefined;
  birdies: number | undefined;
  pars: number | undefined;
  bogeys: number | undefined;
  finish: number | undefined;
  points: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsIceHockeyStatFragment = {
  games_played: number | undefined;
  time_on_ice: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  penalties_in_minutes: number | undefined;
  power_play_goals: number | undefined;
  power_play_assists: number | undefined;
  short_hand_goals: number | undefined;
  short_hand_assists: number | undefined;
  shots: number | undefined;
  games_started: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  overtime_losses: number | undefined;
  saves: number | undefined;
  goals_against: number | undefined;
  shots_against: number | undefined;
  shootout_shorts_against: number | undefined;
  shootout_saves: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
  points: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsLacrosseStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  minutes_played: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  shots: number | undefined;
  shots_on_goal: number | undefined;
  ground_balls: number | undefined;
  faceoffs_won: number | undefined;
  faceoffs_lost: number | undefined;
  turnovers: number | undefined;
  clears: number | undefined;
  penalties: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  ties: number | undefined;
  minutes_in_goal: number | undefined;
  goals_against: number | undefined;
  saves: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
  points: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsSoccerStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  minutes_played: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  shots: number | undefined;
  shots_on_goal: number | undefined;
  penalty_kick_attempts: number | undefined;
  penalty_kick_goals: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  draws: number | undefined;
  goals_against: number | undefined;
  saves: number | undefined;
  yellow_cards: number | undefined;
  red_cards: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
  points: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsSoftballStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  at_bats: number | undefined;
  runs: number | undefined;
  singles: number | undefined;
  doubles: number | undefined;
  triples: number | undefined;
  home_runs: number | undefined;
  runs_batted_in: number | undefined;
  sacrifice_flies: number | undefined;
  sacrifice_bunts: number | undefined;
  walks: number | undefined;
  strikeouts_off: number | undefined;
  hit_by_pitch: number | undefined;
  reached_on_error: number | undefined;
  fielders_choice: number | undefined;
  runners_left_on_base: number | undefined;
  runners_left_in_scoring_position: number | undefined;
  stolen_bases: number | undefined;
  stolen_base_attempts: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  holds: number | undefined;
  saves: number | undefined;
  complete_game: number | undefined;
  innings_pitched: string | undefined;
  batters_faced: number | undefined;
  runs_allowed: number | undefined;
  earned_runs_allowed: number | undefined;
  singles_allowed: number | undefined;
  doubles_allowed: number | undefined;
  triples_allowed: number | undefined;
  home_runs_allowed: number | undefined;
  walks_allowed: number | undefined;
  strikeouts_def: number | undefined;
  hit_batter: number | undefined;
  strikes_thrown: number | undefined;
  pitch_count: number | undefined;
  put_outs: number | undefined;
  assists: number | undefined;
  fielding_errors: number | undefined;
  double_plays: number | undefined;
  pickoffs: number | undefined;
  caught_stealing: number | undefined;
  passed_balls: number | undefined;
  innings_played: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
  points: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsStatFragment = { points: number | undefined };

type AdminTeamGamesStatsQueryStatFieldsTennisStatFragment = {
  games_played: number | undefined;
  sets_played: number | undefined;
  service_attempts: number | undefined;
  service_points_won: number | undefined;
  service_aces: number | undefined;
  service_faults: number | undefined;
  double_faults: number | undefined;
  points: number | undefined;
  returns_attempted: number | undefined;
  return_points_won: number | undefined;
  break_point_attempts: number | undefined;
  break_points_won: number | undefined;
  errors_stat: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsUltimateFrisbeeStatFragment = { points: number | undefined };

type AdminTeamGamesStatsQueryStatFieldsUnifiedBasketballStatFragment = { points: number | undefined };

type AdminTeamGamesStatsQueryStatFieldsVolleyballStatFragment = {
  games_played: number | undefined;
  sets_played: number | undefined;
  service_attempts: number | undefined;
  service_aces: number | undefined;
  service_errors: number | undefined;
  points: number | undefined;
  total_attack_attempts: number | undefined;
  kills: number | undefined;
  attack_errors: number | undefined;
  receptions: number | undefined;
  receiving_errors: number | undefined;
  solo_blocks: number | undefined;
  block_assists: number | undefined;
  blocking_errors: number | undefined;
  assists: number | undefined;
  ball_handling_errors: number | undefined;
  digs: number | undefined;
  dig_errors: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
};

type AdminTeamGamesStatsQueryStatFieldsWaterPoloStatFragment = {
  games_played: number | undefined;
  shots: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  steals: number | undefined;
  ejects_drawn: number | undefined;
  ejects_given: number | undefined;
  fast_break: number | undefined;
  sprints_won: number | undefined;
  sprints_attempted: number | undefined;
  games_started: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  minutes_in_goal: number | undefined;
  shots_against: number | undefined;
  saves: number | undefined;
  goals_against: number | undefined;
  concussion: number | undefined;
  ejections: number | undefined;
  points: number | undefined;
};

export type AdminTeamGamesStatsQueryStatFieldsFragment =
  | AdminTeamGamesStatsQueryStatFieldsBaseballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsBasketballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsBeachVolleyballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsFieldHockeyStatFragment
  | AdminTeamGamesStatsQueryStatFieldsFlagFootballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsFootballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsGolfStatFragment
  | AdminTeamGamesStatsQueryStatFieldsIceHockeyStatFragment
  | AdminTeamGamesStatsQueryStatFieldsLacrosseStatFragment
  | AdminTeamGamesStatsQueryStatFieldsSoccerStatFragment
  | AdminTeamGamesStatsQueryStatFieldsSoftballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsStatFragment
  | AdminTeamGamesStatsQueryStatFieldsTennisStatFragment
  | AdminTeamGamesStatsQueryStatFieldsUltimateFrisbeeStatFragment
  | AdminTeamGamesStatsQueryStatFieldsUnifiedBasketballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsVolleyballStatFragment
  | AdminTeamGamesStatsQueryStatFieldsWaterPoloStatFragment;

export type AdminTeamGamesStatsQueryTeamFieldsFragment = {
  id: string;
  name: string;
  image: string | undefined;
  abbrev: string;
  mascot: string | undefined;
  web_path: string;
  location_text: string;
};

export type AdminTeamsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type AdminTeamsQuery = {
  team: {
    level_id: number;
    abbrev: string;
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    sport_id: number;
    gender_id: number;
    division_text: string;
    location_text: string;
    web_path: string;
    web_schedule_path: string | undefined;
    web_standings_path: string | undefined;
    web_news_path: string | undefined;
    webPhotosPath: string | undefined;
    web_rankings_path: string | undefined;
    web_roster_path: string | undefined;
    web_stats_path: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    gender_sport: string | undefined;
    state: { name: string; abbrev: string; slug: string } | undefined;
    seasons:
      | Array<{
          id: number;
          isCurrent: boolean;
          school_year: { name: string; full_name: string | undefined; has_multiple_years: boolean | undefined } | undefined;
        }>
      | undefined;
    owner: { id: string } | undefined;
    level: { name: string; id: number };
    power25Ranking: { place: number } | undefined;
    computerRankings: { place: number } | undefined;
    league: { webPath: string } | undefined;
    school: { id: string; name: string; web_path: string } | undefined;
    other_teams: Array<{ web_path: string; level: { id: number; name: string; abbrev: string | undefined } }>;
  };
};

export type BracketsGlobalIndexQueryVariables = Exact<{
  genderSport?: InputMaybe<GenderSportEnum>;
  state?: InputMaybe<StateEnum>;
}>;

export type BracketsGlobalIndexQuery = {
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type BracketsIndexClassificationQueryVariables = Exact<{
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<Scalars['Int']['input']>;
}>;

export type BracketsIndexClassificationQuery = {
  classifications: Array<{
    id: string;
    name: string;
    tier: number | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    status: string;
    bracketsUpdatedAt: string | undefined;
    bracketsCount: number;
  }>;
};

export type BracketsIndexQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
  season_name?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  bracket_classification_id?: InputMaybe<Scalars['Int']['input']>;
  ancestor_bracket_classification_id?: InputMaybe<Scalars['Int']['input']>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  gender_sport: GenderSportEnum;
  state_enum: StateEnum;
  paginating?: Scalars['Boolean']['input'];
}>;

export type BracketsIndexQuery = {
  associations: { nodes: Array<{ id: string; name: string } | { id: string; name: string }> };
  bracketClassifications?: Array<{ id: string; name: string; classificationType: string }>;
  childrenClassification: Array<{ id: string; name: string; classificationType: string }>;
  brackets: {
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    nodes: Array<{
      __typename: 'Bracket';
      id: string;
      name: string;
      logoImage: string | undefined;
      webPath: string;
      statusId: number;
      startDate: string | undefined;
      endDate: string | undefined;
      updatedAt: string;
      champion: { abbrev: string; mascot: string | undefined; web_path: string; image: string | undefined } | undefined;
      topSeed: { abbrev: string; mascot: string | undefined; web_path: string; image: string | undefined } | undefined;
      state: { name: string } | undefined;
      bracketClassification:
        | {
            name: string;
            id: string;
            tier: number | undefined;
            classificationType: string;
            parent: { id: string; name: string; classificationType: string } | undefined;
          }
        | undefined;
    }>;
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type BracketsMoreQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  genderSport?: InputMaybe<GenderSportEnum>;
  state?: InputMaybe<StateEnum>;
}>;

export type BracketsMoreQuery = {
  bracket: {
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    formatId: number;
    previousChampion: string | undefined;
    id: string;
    coverPhotoImage: string | undefined;
    logoImage: string | undefined;
    name: string;
    divisionText: string;
    webMorePath: string;
    webPath: string;
    webScoresPath: string;
    webTeamsPath: string;
    statusId: number;
    topSeed: { image: string | undefined; name: string; webPath: string } | undefined;
    rounds:
      | Array<{
          defaultName: string;
          name: string | undefined;
          bracketContests: Array<{
            game:
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | undefined;
          }>;
        }>
      | undefined;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    bracketContestTeams: Array<{
      seed: string | undefined;
      team:
        | {
            id: string;
            name: string;
            webPath: string;
            image: string | undefined;
            standing: { leagueRecord: string; overallRecord: string };
          }
        | undefined;
    }>;
    genderSport: { name: string };
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type BracketsScoresQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  genderSport?: InputMaybe<GenderSportEnum>;
  state?: InputMaybe<StateEnum>;
}>;

export type BracketsScoresQuery = {
  bracket: {
    id: string;
    coverPhotoImage: string | undefined;
    logoImage: string | undefined;
    name: string;
    divisionText: string;
    webMorePath: string;
    webPath: string;
    webScoresPath: string;
    webTeamsPath: string;
    statusId: number;
    contentItems: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    rounds:
      | Array<{
          id: BracketRoundIdEnum;
          name: string | undefined;
          defaultName: string;
          bracketContests: Array<{
            game:
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | undefined;
          }>;
        }>
      | undefined;
    genderSport: { name: string };
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type BracketsShowQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type BracketsShowQuery = {
  bracket: {
    formatId: number;
    matchupType: BracketMatchupTypeEnum;
    hideSeedNumbers: boolean;
    id: string;
    logoImage: string | undefined;
    name: string;
    gofanTicketingUrl: string | undefined;
    nfhsNetworkUrl: string | undefined;
    quadrants: number | undefined;
    numberOfTeams: number;
    helperText: string | undefined;
    statusId: number;
    startDate: string | undefined;
    endDate: string | undefined;
    previousChampion: string | undefined;
    showHomeTeamFirst: boolean;
    showThirdPlaceGame: boolean;
    playInGames: number;
    webPath: string;
    coverPhotoImage: string | undefined;
    divisionText: string;
    webMorePath: string;
    webScoresPath: string;
    webTeamsPath: string;
    quadrantNames: Array<{ index: number | undefined; name: string | undefined }> | undefined;
    topSeed: { name: string; web_path: string } | undefined;
    rounds:
      | Array<{
          defaultName: string;
          endDate: string | undefined;
          id: BracketRoundIdEnum;
          isLoserRound: boolean;
          name: string | undefined;
          size: number;
          startDate: string | undefined;
          bracketContests: Array<{
            date: string | undefined;
            gameNumber: number;
            name: string | undefined;
            location: string | undefined;
            position: number;
            roundId: BracketRoundIdEnum;
            statusId: number | undefined;
            secondaryStatusId: number | undefined;
            webGamePath: string | undefined;
            away: {
              isBye: boolean;
              isLoser: boolean;
              seed: string | undefined;
              score: number | undefined;
              shootoutScore: number | undefined;
              isTbd: boolean;
              team: { id: string; image: string | undefined; mascot: string | undefined; name: string; locationText: string } | undefined;
            };
            home: {
              isBye: boolean;
              isLoser: boolean;
              seed: string | undefined;
              score: number | undefined;
              shootoutScore: number | undefined;
              isTbd: boolean;
              team: { id: string; image: string | undefined; mascot: string | undefined; name: string; locationText: string } | undefined;
            };
            game:
              | {
                  id: string;
                  web_recap_path: string | undefined;
                  web_preview_path: string | undefined;
                  web_path: string;
                  status_id: number;
                  longStatusText: string;
                  secondary_status_id: number | undefined;
                  home: { id: string; is_winner: boolean; picks: number; score: number };
                  away: { id: string; is_winner: boolean; picks: number; score: number };
                }
              | undefined;
            series: { webPath: string | undefined } | undefined;
          }>;
        }>
      | undefined;
    genderSport: { name: string };
  };
};

export type BracketsShowQueryBracketContestTeamFragment = {
  isBye: boolean;
  isLoser: boolean;
  seed: string | undefined;
  score: number | undefined;
  shootoutScore: number | undefined;
  isTbd: boolean;
  team: { id: string; image: string | undefined; mascot: string | undefined; name: string; locationText: string } | undefined;
};

export type BracketsTeamsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  genderSport?: InputMaybe<GenderSportEnum>;
  state?: InputMaybe<StateEnum>;
}>;

export type BracketsTeamsQuery = {
  bracket: {
    id: string;
    coverPhotoImage: string | undefined;
    logoImage: string | undefined;
    name: string;
    divisionText: string;
    webMorePath: string;
    webPath: string;
    webScoresPath: string;
    webTeamsPath: string;
    statusId: number;
    bracketContestTeams: Array<{
      seed: string | undefined;
      team:
        | {
            id: string;
            name: string;
            webPath: string;
            image: string | undefined;
            standing: { leagueRecord: string; overallRecord: string; overallWinPercentage: string; homeRecord: string; awayRecord: string };
          }
        | undefined;
    }>;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    genderSport: { name: string };
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type ConstantsQueryVariables = Exact<{ [key: string]: never }>;

export type ConstantsQuery = {
  bracketFormats: Array<{ id: number; name: string }>;
  bracketStatuses: Array<{ id: number; name: string }>;
  classifications: Array<{
    id: string;
    name: string;
    states: Array<string> | undefined;
    sections: Array<{ id: string; name: string }> | undefined;
  }>;
  coach_types: Array<{ id: number; name: string | undefined }>;
  game_secondary_statuses: Array<{ id: number; name: string | undefined }>;
  game_statuses: Array<{ id: number; name: string | undefined }>;
  game_types: Array<{ id: number; name: string | undefined }>;
  genderSports: Array<{ sportId: number; genderId: number; slug: string; name: string; key: GenderSportEnum }>;
  genders: Array<{ id: number; name: string }>;
  levels: Array<{ id: number; key: LevelEnum; name: string; abbrev: string | undefined }>;
  positions: Array<{ id: string; sport_id: number; name: string; abbrev: string; gender_ids: Array<number> | undefined }>;
  seasons: Array<{ id: number; sport_id: number; name: string; full_name: string; isCurrent: boolean; shortName: string }>;
  sections: Array<{ id: string; name: string; state: { abbrev: string } | undefined }>;
  sports: Array<{ id: number; name: string }>;
  states: Array<{ abbrev: string; key: StateEnum; isAmerican: boolean; name: string; slug: string }>;
  user_types: Array<{ id: number; name: string }>;
};

export type ContentIndexQueryVariables = Exact<{
  gender_sport?: InputMaybe<GenderSportEnum>;
  state?: InputMaybe<StateEnum>;
  topic?: InputMaybe<Array<ContentItemTopicEnum> | ContentItemTopicEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContentIndexQuery = {
  articles: {
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type EnumTypesQueryVariables = Exact<{ [key: string]: never }>;

export type EnumTypesQuery = {
  __schema: { types: Array<{ kind: TypeKind; name: string | undefined; enumValues: Array<{ name: string }> | undefined }> };
};

export type FavoriteGamesQueryVariables = Exact<{
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  genderSport?: InputMaybe<GenderSportEnum>;
  level?: InputMaybe<LevelEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  power25?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<StateEnum>;
}>;

export type FavoriteGamesQuery = {
  currentUser: {
    favoriteGames: {
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
      nodes: Array<
        | {
            id: string;
            date: string;
            gameTypeLabel: string;
            longStatusText: string;
            statusId: number;
            webPath: string;
            titleText: string;
            webPhotosPath: string | undefined;
            gameTeams: Array<{
              id: string;
              isLoser: boolean;
              isTbd: boolean;
              isWinner: boolean;
              scoreText: string | undefined;
              power25Ranking: { place: number } | undefined;
              pregameStanding: { overallRecord: string } | undefined;
              team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
            }>;
            contest:
              | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
              | undefined;
          }
        | {
            id: string;
            date: string;
            gameTypeLabel: string;
            longStatusText: string;
            statusId: number;
            webPath: string;
            titleText: string;
            webPhotosPath: string | undefined;
            gameTeams: Array<{
              id: string;
              isLoser: boolean;
              isTbd: boolean;
              isWinner: boolean;
              scoreText: string | undefined;
              power25Ranking: { place: number } | undefined;
              pregameStanding: { overallRecord: string } | undefined;
              team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
            }>;
            contest:
              | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
              | undefined;
          }
        | {
            id: string;
            date: string;
            gameTypeLabel: string;
            longStatusText: string;
            statusId: number;
            webPath: string;
            titleText: string;
            webPhotosPath: string | undefined;
            gameTeams: Array<{
              id: string;
              isLoser: boolean;
              isTbd: boolean;
              isWinner: boolean;
              scoreText: string | undefined;
              power25Ranking: { place: number } | undefined;
              pregameStanding: { overallRecord: string } | undefined;
              team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
            }>;
            contest:
              | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
              | undefined;
          }
      >;
    };
  };
};

export type ArticlesFragment = {
  nodes: Array<
    | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
    | { __typename: 'ContentItemPoll'; id: string; date: string }
    | {
        __typename: 'ContentItemTempestArticle';
        authorImage: string | undefined;
        authorName: string;
        description: string | undefined;
        imageAlt: string | undefined;
        imageUrl: string | undefined;
        path: string;
        title: string;
        id: string;
        date: string;
        genderSport: { name: string } | undefined;
      }
    | { __typename: 'ContentItemTweet'; id: string; date: string }
    | {
        __typename: 'ContentItemVoltaxArticle';
        authorImage: string | undefined;
        authorName: string;
        description: string | undefined;
        imageAlt: string | undefined;
        imageUrl: string | undefined;
        path: string;
        title: string;
        id: string;
        date: string;
        genderSport: { name: string } | undefined;
      }
    | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
  >;
};

export type BracketSubNavFragment = {
  id: string;
  coverPhotoImage: string | undefined;
  logoImage: string | undefined;
  name: string;
  divisionText: string;
  webMorePath: string;
  webPath: string;
  webScoresPath: string;
  webTeamsPath: string;
  statusId: number;
  genderSport: { name: string };
};

type GameHeaderBasketballGameFragment = {
  id: string;
  gender_sport: string | undefined;
  sport_id: number;
  status_id: number;
  secondary_status_id: number | undefined;
  title_text: string;
  image: string;
  medium_status_text: string | undefined;
  long_status_text: string;
  clock_text: string | undefined;
  date: string;
  facebook_path: string | undefined;
  twitter_path: string | undefined;
  web_path: string;
  web_boxscore_path: string | undefined;
  web_scorebookcast_path: string | undefined;
  web_recap_path: string | undefined;
  webPhotosPath: string | undefined;
  web_preview_path: string | undefined;
  level: { abbrev: string | undefined } | undefined;
  away: {
    id: string;
    score: number;
    is_loser: boolean;
    standing: { overallRecord: string };
    team: {
      id: string;
      abbrev: string;
      name: string;
      mascot: string | undefined;
      web_path: string;
      image: string | undefined;
      hide: boolean | undefined;
    };
  };
  home: {
    id: string;
    score: number;
    is_loser: boolean;
    standing: { overallRecord: string };
    team: {
      id: string;
      abbrev: string;
      name: string;
      mascot: string | undefined;
      web_path: string;
      image: string | undefined;
      hide: boolean | undefined;
    };
  };
};

type GameHeaderFootballGameFragment = {
  id: string;
  gender_sport: string | undefined;
  sport_id: number;
  status_id: number;
  secondary_status_id: number | undefined;
  title_text: string;
  image: string;
  medium_status_text: string | undefined;
  long_status_text: string;
  clock_text: string | undefined;
  date: string;
  facebook_path: string | undefined;
  twitter_path: string | undefined;
  web_path: string;
  web_boxscore_path: string | undefined;
  web_scorebookcast_path: string | undefined;
  web_recap_path: string | undefined;
  webPhotosPath: string | undefined;
  web_preview_path: string | undefined;
  game_snapshot: { possession_game_team_id: string | undefined } | undefined;
  level: { abbrev: string | undefined } | undefined;
  away: {
    id: string;
    score: number;
    is_loser: boolean;
    standing: { overallRecord: string };
    team: {
      id: string;
      abbrev: string;
      name: string;
      mascot: string | undefined;
      web_path: string;
      image: string | undefined;
      hide: boolean | undefined;
    };
  };
  home: {
    id: string;
    score: number;
    is_loser: boolean;
    standing: { overallRecord: string };
    team: {
      id: string;
      abbrev: string;
      name: string;
      mascot: string | undefined;
      web_path: string;
      image: string | undefined;
      hide: boolean | undefined;
    };
  };
};

type GameHeaderGameFragment = {
  id: string;
  gender_sport: string | undefined;
  sport_id: number;
  status_id: number;
  secondary_status_id: number | undefined;
  title_text: string;
  image: string;
  medium_status_text: string | undefined;
  long_status_text: string;
  clock_text: string | undefined;
  date: string;
  facebook_path: string | undefined;
  twitter_path: string | undefined;
  web_path: string;
  web_boxscore_path: string | undefined;
  web_scorebookcast_path: string | undefined;
  web_recap_path: string | undefined;
  webPhotosPath: string | undefined;
  web_preview_path: string | undefined;
  level: { abbrev: string | undefined } | undefined;
  away: {
    id: string;
    score: number;
    is_loser: boolean;
    standing: { overallRecord: string };
    team: {
      id: string;
      abbrev: string;
      name: string;
      mascot: string | undefined;
      web_path: string;
      image: string | undefined;
      hide: boolean | undefined;
    };
  };
  home: {
    id: string;
    score: number;
    is_loser: boolean;
    standing: { overallRecord: string };
    team: {
      id: string;
      abbrev: string;
      name: string;
      mascot: string | undefined;
      web_path: string;
      image: string | undefined;
      hide: boolean | undefined;
    };
  };
};

export type GameHeaderFragment = GameHeaderBasketballGameFragment | GameHeaderFootballGameFragment | GameHeaderGameFragment;

export type GameHeaderGameTeamHeaderFragment = {
  id: string;
  score: number;
  is_loser: boolean;
  standing: { overallRecord: string };
  team: {
    id: string;
    abbrev: string;
    name: string;
    mascot: string | undefined;
    web_path: string;
    image: string | undefined;
    hide: boolean | undefined;
  };
};

type GameSubNavBasketballGameFragment = {
  id: string;
  clockText: string | undefined;
  date: string;
  mediumStatusText: string;
  statusId: number;
  webBoxscorePath: string | undefined;
  webPath: string;
  webPhotosPath: string | undefined;
  webPreviewPath: string | undefined;
  webRecapPath: string | undefined;
  webScorebookCastPath: string | undefined;
  image: string;
  titleText: string;
  contest:
    | {
        hometownTicketingUrl: string | undefined;
        gofanTicketingUrl: string | undefined;
        nfhsNetworkUrl: string | undefined;
        bracket: { webPath: string } | undefined;
      }
    | undefined;
  gameTeams: Array<{
    id: string;
    isHome: boolean;
    isTbd: boolean;
    result: GameTeamResultEnum | undefined;
    scoreText: string | undefined;
    standing: { overallRecord: string };
    team: {
      abbrev: string;
      image: string | undefined;
      mascot: string | undefined;
      name: string;
      webPath: string;
      state: { name: string } | undefined;
    };
  }>;
  genderSport: { name: string };
  level: { abbrev: string | undefined } | undefined;
};

type GameSubNavFootballGameFragment = {
  id: string;
  clockText: string | undefined;
  date: string;
  mediumStatusText: string;
  statusId: number;
  webBoxscorePath: string | undefined;
  webPath: string;
  webPhotosPath: string | undefined;
  webPreviewPath: string | undefined;
  webRecapPath: string | undefined;
  webScorebookCastPath: string | undefined;
  image: string;
  titleText: string;
  contest:
    | {
        hometownTicketingUrl: string | undefined;
        gofanTicketingUrl: string | undefined;
        nfhsNetworkUrl: string | undefined;
        bracket: { webPath: string } | undefined;
      }
    | undefined;
  gameTeams: Array<{
    id: string;
    isHome: boolean;
    isTbd: boolean;
    result: GameTeamResultEnum | undefined;
    scoreText: string | undefined;
    standing: { overallRecord: string };
    team: {
      abbrev: string;
      image: string | undefined;
      mascot: string | undefined;
      name: string;
      webPath: string;
      state: { name: string } | undefined;
    };
  }>;
  genderSport: { name: string };
  level: { abbrev: string | undefined } | undefined;
};

type GameSubNavGameFragment = {
  id: string;
  clockText: string | undefined;
  date: string;
  mediumStatusText: string;
  statusId: number;
  webBoxscorePath: string | undefined;
  webPath: string;
  webPhotosPath: string | undefined;
  webPreviewPath: string | undefined;
  webRecapPath: string | undefined;
  webScorebookCastPath: string | undefined;
  image: string;
  titleText: string;
  contest:
    | {
        hometownTicketingUrl: string | undefined;
        gofanTicketingUrl: string | undefined;
        nfhsNetworkUrl: string | undefined;
        bracket: { webPath: string } | undefined;
      }
    | undefined;
  gameTeams: Array<{
    id: string;
    isHome: boolean;
    isTbd: boolean;
    result: GameTeamResultEnum | undefined;
    scoreText: string | undefined;
    standing: { overallRecord: string };
    team: {
      abbrev: string;
      image: string | undefined;
      mascot: string | undefined;
      name: string;
      webPath: string;
      state: { name: string } | undefined;
    };
  }>;
  genderSport: { name: string };
  level: { abbrev: string | undefined } | undefined;
};

export type GameSubNavFragment = GameSubNavBasketballGameFragment | GameSubNavFootballGameFragment | GameSubNavGameFragment;

type OrganizationSubNavSchoolOrganizationFragment = {
  __typename: 'SchoolOrganization';
  fullName: string | undefined;
  image: string | undefined;
  name: string;
  state: { name: string };
};

type OrganizationSubNavTeamOrganizationFragment = {
  __typename: 'TeamOrganization';
  webPath: string;
  webScoresPath: string;
  webStandingsPath: string;
  fullName: string | undefined;
  image: string | undefined;
  name: string;
  genderSport: { name: string };
  state: { name: string };
};

export type OrganizationSubNavFragment = OrganizationSubNavSchoolOrganizationFragment | OrganizationSubNavTeamOrganizationFragment;

export type PhotoGalleryFragment = {
  totalCount: number;
  nodes: Array<{
    __typename: 'ContentItemPhotoGallery';
    id: string;
    date: string;
    name: string;
    webPath: string;
    photoCount: number;
    coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
    teams: Array<{
      id: string;
      abbrev: string;
      name: string;
      image: string | undefined;
      webPhotosPath: string | undefined;
      state: { name: string; abbrev: string } | undefined;
    }>;
    genderSport: { key: GenderSportEnum; slug: string } | undefined;
    photographer: { firstName: string; lastName: string; email: string };
  }>;
  pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
};

export type SchoolHeaderLegacyFragment = {
  id: string;
  web_path: string;
  webNewsPath: string | undefined;
  webTeamsPath: string | undefined;
  webScoresPath: string | undefined;
  name: string;
  mascot: string | undefined;
  fans_count: number | undefined;
  nfhsNetworkUrl: string | undefined;
  gofanTicketingUrl: string | undefined;
  location_text: string;
  image: string | undefined;
  cover_image: string | undefined;
  owned: boolean | undefined;
  teams_count: number | undefined;
  primaryColor: string | undefined;
  secondaryColor: string | undefined;
  athletic_director: string | undefined;
  schoolType: string | undefined;
};

export type SchoolSubNavFragment = {
  id: string;
  image: string | undefined;
  name: string;
  mascot: string | undefined;
  locationText: string;
  webPath: string;
  webTeamsPath: string | undefined;
  webScoresPath: string | undefined;
  webNewsPath: string | undefined;
  nfhsNetworkUrl: string | undefined;
  gofanTicketingUrl: string | undefined;
  owner: { id: string; username: string } | undefined;
};

type ScoreboardGameBasketballGameFragment = {
  id: string;
  date: string;
  longStatusText: string;
  statusId: number;
  titleText: string;
  webPath: string;
  webPhotosPath: string | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
};

type ScoreboardGameFootballGameFragment = {
  id: string;
  date: string;
  longStatusText: string;
  statusId: number;
  titleText: string;
  webPath: string;
  webPhotosPath: string | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
};

type ScoreboardGameGameFragment = {
  id: string;
  date: string;
  longStatusText: string;
  statusId: number;
  titleText: string;
  webPath: string;
  webPhotosPath: string | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
};

export type ScoreboardGameFragment = ScoreboardGameBasketballGameFragment | ScoreboardGameFootballGameFragment | ScoreboardGameGameFragment;

type ScoreboardGameWithPower25BasketballGameFragment = {
  id: string;
  date: string;
  longStatusText: string;
  statusId: number;
  titleText: string;
  webPath: string;
  webPhotosPath: string | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    power25Ranking: { place: number } | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
};

type ScoreboardGameWithPower25FootballGameFragment = {
  id: string;
  date: string;
  longStatusText: string;
  statusId: number;
  titleText: string;
  webPath: string;
  webPhotosPath: string | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    power25Ranking: { place: number } | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
};

type ScoreboardGameWithPower25GameFragment = {
  id: string;
  date: string;
  longStatusText: string;
  statusId: number;
  titleText: string;
  webPath: string;
  webPhotosPath: string | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    power25Ranking: { place: number } | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
};

export type ScoreboardGameWithPower25Fragment =
  | ScoreboardGameWithPower25BasketballGameFragment
  | ScoreboardGameWithPower25FootballGameFragment
  | ScoreboardGameWithPower25GameFragment;

type ScoreboardGameFanAppBasketballGameFragment = {
  id: string;
  date: string;
  gameTypeLabel: string;
  longStatusText: string;
  statusId: number;
  webPath: string;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
};

type ScoreboardGameFanAppFootballGameFragment = {
  id: string;
  date: string;
  gameTypeLabel: string;
  longStatusText: string;
  statusId: number;
  webPath: string;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
};

type ScoreboardGameFanAppGameFragment = {
  id: string;
  date: string;
  gameTypeLabel: string;
  longStatusText: string;
  statusId: number;
  webPath: string;
  contest:
    | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
    | undefined;
  gameTeams: Array<{
    id: string;
    isLoser: boolean;
    isTbd: boolean;
    isWinner: boolean;
    scoreText: string | undefined;
    pregameStanding: { overallRecord: string } | undefined;
    team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
  }>;
};

export type ScoreboardGameFanAppFragment =
  | ScoreboardGameFanAppBasketballGameFragment
  | ScoreboardGameFanAppFootballGameFragment
  | ScoreboardGameFanAppGameFragment;

export type TeamHeaderLegacyFragment = {
  id: string;
  image: string | undefined;
  name: string;
  mascot: string | undefined;
  sport_id: number;
  gender_id: number;
  division_text: string;
  location_text: string;
  web_path: string;
  web_schedule_path: string | undefined;
  web_standings_path: string | undefined;
  web_news_path: string | undefined;
  webPhotosPath: string | undefined;
  web_rankings_path: string | undefined;
  web_roster_path: string | undefined;
  web_stats_path: string | undefined;
  nfhsNetworkUrl: string | undefined;
  gofanTicketingUrl: string | undefined;
  level: { name: string; id: number };
  power25Ranking: { place: number } | undefined;
  computerRankings: { place: number } | undefined;
  league: { webPath: string } | undefined;
  school: { id: string; name: string; web_path: string } | undefined;
  owner: { id: string } | undefined;
  state: { abbrev: string; slug: string } | undefined;
  other_teams: Array<{ web_path: string; level: { id: number; name: string; abbrev: string | undefined } }>;
};

export type TeamOrganizationHeaderFragment = {
  label: string;
  image: string | undefined;
  name: string;
  webPath: string;
  webScoresPath: string;
  webStandingsPath: string;
  genderSport: { name: string };
  state: { name: string };
  teams: Array<{ id: string; image: string | undefined; name: string; web_path: string }>;
};

export type TeamSeasonHeaderFragment = { standing: { overallRecord: string; leagueRecord: string } };

export type TeamStandingFragment = {
  team: { id: string; image: string | undefined; name: string; webPath: string };
  standing: {
    leagueRecord: string;
    leagueGamesBack: string;
    overallRecord: string;
    overallWinPercentage: string;
    homeRecord: string;
    awayRecord: string;
  };
};

export type TeamSubNavFragment = {
  id: string;
  image: string | undefined;
  name: string;
  mascot: string | undefined;
  locationText: string;
  webPath: string;
  webSchedulePath: string | undefined;
  webRosterPath: string | undefined;
  webStandingsPath: string | undefined;
  webRankingsPath: string | undefined;
  webNewsPath: string | undefined;
  webPhotosPath: string | undefined;
  webStatsPath: string | undefined;
  nfhsNetworkUrl: string | undefined;
  gofanTicketingUrl: string | undefined;
  level: { name: string };
  genderSport: { name: string };
  standing: { overallRecord: string; leagueRecord: string };
  school: { id: string; webPath: string } | undefined;
  otherTeams: Array<{ webPath: string; level: { name: string } }>;
  owner: { id: string; username: string } | undefined;
};

export type GamesActiveLegacyQueryVariables = Exact<{ [key: string]: never }>;

export type GamesActiveLegacyQuery = {
  activeGames: Array<
    | {
        id: string;
        status_id: number;
        secondary_status_id: number | undefined;
        is_crunchtime: boolean;
        long_status_text: string;
        away: { score: number; is_loser: boolean };
        home: { score: number; is_loser: boolean };
      }
    | {
        id: string;
        status_id: number;
        secondary_status_id: number | undefined;
        is_crunchtime: boolean;
        long_status_text: string;
        game_snapshot:
          | {
              possession_game_team_id: string | undefined;
              yardline: number | undefined;
              down: number | undefined;
              distance: number | undefined;
              to_go_text: string | undefined;
              last_event_text: string | undefined;
            }
          | undefined;
        away: { score: number; is_loser: boolean };
        home: { score: number; is_loser: boolean };
      }
    | {
        id: string;
        status_id: number;
        secondary_status_id: number | undefined;
        is_crunchtime: boolean;
        long_status_text: string;
        away: { score: number; is_loser: boolean };
        home: { score: number; is_loser: boolean };
      }
  >;
};

export type GamesActiveQueryGameTeamActiveFragment = { score: number; is_loser: boolean };

export type GamesAllGenderSportsIndexFanAppQueryVariables = Exact<{
  date: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GamesAllGenderSportsIndexFanAppQuery = {
  genderSportGameGroups: {
    nodes: Array<{
      games: {
        nodes: Array<
          | {
              id: string;
              date: string;
              gameTypeLabel: string;
              longStatusText: string;
              statusId: number;
              webPath: string;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
            }
          | {
              id: string;
              date: string;
              gameTypeLabel: string;
              longStatusText: string;
              statusId: number;
              webPath: string;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
            }
          | {
              id: string;
              date: string;
              gameTypeLabel: string;
              longStatusText: string;
              statusId: number;
              webPath: string;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
            }
        >;
      };
      genderSport: { name: string; slug: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
};

export type GamesAllGenderSportsIndexQueryVariables = Exact<{
  date: Scalars['String']['input'];
  state?: InputMaybe<StateEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GamesAllGenderSportsIndexQuery = {
  genderSportGameGroups: {
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    nodes: Array<{
      genderSport: { name: string; slug: string };
      games: {
        nodes: Array<
          | {
              id: string;
              date: string;
              longStatusText: string;
              statusId: number;
              titleText: string;
              webPath: string;
              webPhotosPath: string | undefined;
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                power25Ranking: { place: number } | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
            }
          | {
              id: string;
              date: string;
              longStatusText: string;
              statusId: number;
              titleText: string;
              webPath: string;
              webPhotosPath: string | undefined;
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                power25Ranking: { place: number } | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
            }
          | {
              id: string;
              date: string;
              longStatusText: string;
              statusId: number;
              titleText: string;
              webPath: string;
              webPhotosPath: string | undefined;
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                power25Ranking: { place: number } | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
            }
        >;
      };
    }>;
  };
};

export type GamesBoxscoreQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  hasStats?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GamesBoxscoreQuery = {
  game:
    | {
        locationText: string | undefined;
        gameTypeLabel: string;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        genderSport: { sportId: number; slug: string; name: string };
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          linescore: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
          stat:
            | {
                at_bats: number | undefined;
                runs: number | undefined;
                hits: number | undefined;
                singles: number | undefined;
                doubles: number | undefined;
                triples: number | undefined;
                home_runs: number | undefined;
                runs_batted_in: number | undefined;
                total_bases: number | undefined;
                total_walks: number | undefined;
                strikeouts_off: number | undefined;
                stolen_bases: number | undefined;
                batting_average: string | undefined;
                on_base_percentage: string | undefined;
                slugging_percentage: string | undefined;
                on_base_plus_slugging: string | undefined;
                innings_pitched: string | undefined;
                hits_allowed: number | undefined;
                earned_runs_allowed: number | undefined;
                walks_allowed: number | undefined;
                strikeouts_def: number | undefined;
                home_runs_allowed: number | undefined;
                pitch_count: number | undefined;
                earned_run_average: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                holds: number | undefined;
                saves: number | undefined;
                put_outs: number | undefined;
                assists: number | undefined;
                fielding_errors: number | undefined;
                double_plays: number | undefined;
                pickoffs: number | undefined;
                stolen_base_attempts: number | undefined;
                runners_caught_stealing: number | undefined;
                points: number | undefined;
              }
            | {
                field_goals_made: number | undefined;
                field_goals_attempted: number | undefined;
                three_points_made: number | undefined;
                three_points_attempted: number | undefined;
                free_throws_made: number | undefined;
                free_throws_attempted: number | undefined;
                offensive_rebounds: number | undefined;
                defensive_rebounds: number | undefined;
                rebounds: number | undefined;
                assists: number | undefined;
                steals: number | undefined;
                blocks: number | undefined;
                turnovers: number | undefined;
                personal_fouls: number | undefined;
                points: number | undefined;
              }
            | {
                sets_played: number | undefined;
                kills: number | undefined;
                attack_errors: number | undefined;
                total_attack_attempts: number | undefined;
                attacking_percentage: number | undefined;
                assists: number | undefined;
                service_aces: number | undefined;
                service_errors: number | undefined;
                receiving_errors: number | undefined;
                digs: number | undefined;
                solo_blocks: number | undefined;
                block_assists: number | undefined;
                total_blocks: number | undefined;
                blocking_errors: number | undefined;
                ball_handling_errors: number | undefined;
                points: number | undefined;
              }
            | {
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shots: number | undefined;
                shots_on_goal: number | undefined;
                defensive_save: number | undefined;
                penalty_corners: number | undefined;
                fouls: number | undefined;
                minutes_played: number | undefined;
                goals_against: number | undefined;
                goalie_saves: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                ties: number | undefined;
                points: number | undefined;
              }
            | {
                passing_completions: number | undefined;
                passing_attempts: number | undefined;
                passing_yards: number | undefined;
                yards_per_attempt: number | undefined;
                passing_sacks: number | undefined;
                passing_touchdowns: number | undefined;
                passing_interceptions: number | undefined;
                rushing_attempts: number | undefined;
                rushing_yards: number | undefined;
                rushing_yards_per_attempt: number | undefined;
                rushing_longest: number | undefined;
                rushing_touchdowns: number | undefined;
                rushing_fumbles: number | undefined;
                receiving_receptions: number | undefined;
                targets: number | undefined;
                receiving_yards: number | undefined;
                yards_per_reception: string | undefined;
                receiving_longest: number | undefined;
                receiving_touchdowns: number | undefined;
                receiving_fumbles: number | undefined;
                solo_tackles: number | undefined;
                assisted_tackles: number | undefined;
                total_tackles: number | undefined;
                tackles_for_loss: number | undefined;
                defensive_sacks: number | undefined;
                yards_lost: number | undefined;
                hurries: number | undefined;
                qb_hits: number | undefined;
                defensive_passes_defended: number | undefined;
                defensive_interceptions: number | undefined;
                defensive_interception_yards: number | undefined;
                defensive_fumbles_forced: number | undefined;
                defensive_fumbles_recovered: number | undefined;
                defensive_blocked_punts: number | undefined;
                defensive_blocked_field_goals: number | undefined;
                defensive_touchdown: number | undefined;
                field_goals_made: number | undefined;
                field_goals_attempts: number | undefined;
                field_goal_percentage: number | undefined;
                field_goals_longest: number | undefined;
                pat_made: number | undefined;
                pat_attempts: number | undefined;
                points: number | undefined;
                punts: number | undefined;
                punt_yards: number | undefined;
                average_punt_yards: number | undefined;
                punt_long: number | undefined;
                punts_inside_20_yard_line: number | undefined;
                total_kick_returns: number | undefined;
                kickoff_return_yards: number | undefined;
                yards_per_kick_return: number | undefined;
                kick_return_long: number | undefined;
                kick_return_touchdowns: number | undefined;
                total_punt_returns: number | undefined;
                punt_return_yards: number | undefined;
                yards_per_punt_return: number | undefined;
                punt_return_long: number | undefined;
                punt_return_touchdowns: number | undefined;
              }
            | {
                passing_completions: number | undefined;
                passing_attempts: number | undefined;
                passing_yards: number | undefined;
                yards_per_attempt: number | undefined;
                passing_sacks: number | undefined;
                passing_touchdowns: number | undefined;
                passing_interceptions: number | undefined;
                rushing_attempts: number | undefined;
                rushing_yards: number | undefined;
                rushing_yards_per_attempt: number | undefined;
                rushing_longest: number | undefined;
                rushing_touchdowns: number | undefined;
                rushing_fumbles: number | undefined;
                receiving_receptions: number | undefined;
                targets: number | undefined;
                receiving_yards: number | undefined;
                yards_per_reception: string | undefined;
                receiving_longest: number | undefined;
                receiving_touchdowns: number | undefined;
                receiving_fumbles: number | undefined;
                solo_tackles: number | undefined;
                assisted_tackles: number | undefined;
                total_tackles: number | undefined;
                tackles_for_loss: number | undefined;
                defensive_sacks: number | undefined;
                yards_lost: number | undefined;
                hurries: number | undefined;
                qb_hits: number | undefined;
                defensive_passes_defended: number | undefined;
                defensive_interceptions: number | undefined;
                defensive_interception_yards: number | undefined;
                defensive_fumbles_forced: number | undefined;
                defensive_fumbles_recovered: number | undefined;
                defensive_blocked_punts: number | undefined;
                defensive_blocked_field_goals: number | undefined;
                defensive_touchdown: number | undefined;
                field_goals_made: number | undefined;
                field_goals_attempts: number | undefined;
                field_goal_percentage: number | undefined;
                field_goals_longest: number | undefined;
                pat_made: number | undefined;
                pat_attempts: number | undefined;
                points: number | undefined;
                punts: number | undefined;
                punt_yards: number | undefined;
                average_punt_yards: number | undefined;
                punt_long: number | undefined;
                punts_inside_20_yard_line: number | undefined;
                total_kick_returns: number | undefined;
                kickoff_return_yards: number | undefined;
                yards_per_kick_return: number | undefined;
                kick_return_long: number | undefined;
                kick_return_touchdowns: number | undefined;
                total_punt_returns: number | undefined;
                punt_return_yards: number | undefined;
                yards_per_punt_return: number | undefined;
                punt_return_long: number | undefined;
                punt_return_touchdowns: number | undefined;
              }
            | {
                rounds_played: number | undefined;
                total_strokes: number | undefined;
                average_score: number | undefined;
                vs_par: number | undefined;
                vs_par_average: number | undefined;
                points: number | undefined;
              }
            | {
                time_on_ice: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                penalties_in_minutes: number | undefined;
                power_play_goals: number | undefined;
                power_play_assists: number | undefined;
                short_hand_goals: number | undefined;
                short_hand_assists: number | undefined;
                shots: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                goals_against: number | undefined;
                shots_against: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                overtime_losses: number | undefined;
                shootout_shorts_against: number | undefined;
                shootout_saves: number | undefined;
                shootout_save_percentage: number | undefined;
                points: number | undefined;
              }
            | {
                minutes_played: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shots: number | undefined;
                shots_on_goal: number | undefined;
                ground_balls: number | undefined;
                faceoffs_won: number | undefined;
                total_faceoffs: number | undefined;
                turnovers: number | undefined;
                clears: number | undefined;
                penalties: number | undefined;
                minutes_in_goal: number | undefined;
                goals_against: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                ties: number | undefined;
                points: number | undefined;
              }
            | {
                minutes_played: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                shots: number | undefined;
                shot_percentage: number | undefined;
                shots_on_goal: number | undefined;
                shots_on_goal_percentage: number | undefined;
                penalty_kick_attempts: number | undefined;
                penalty_kick_goals: number | undefined;
                penalty_kick_percentage: number | undefined;
                goals_against: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                draws: number | undefined;
                points: number | undefined;
              }
            | {
                games_played: number | undefined;
                at_bats: number | undefined;
                runs: number | undefined;
                hits: number | undefined;
                singles: number | undefined;
                doubles: number | undefined;
                triples: number | undefined;
                home_runs: number | undefined;
                runs_batted_in: number | undefined;
                total_bases: number | undefined;
                total_walks: number | undefined;
                strikeouts_off: number | undefined;
                stolen_bases: number | undefined;
                batting_average: string | undefined;
                on_base_percentage: string | undefined;
                slugging_percentage: string | undefined;
                on_base_plus_slugging: string | undefined;
                games_started: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                saves: number | undefined;
                holds: number | undefined;
                innings_pitched: string | undefined;
                hits_allowed: number | undefined;
                earned_runs_allowed: number | undefined;
                home_runs_allowed: number | undefined;
                walks_allowed: number | undefined;
                strikeouts_def: number | undefined;
                strikeouts_per_9_innings: number | undefined;
                walks_hits_per_inning_pitched: number | undefined;
                earned_run_average: number | undefined;
                total_chances: number | undefined;
                put_outs: number | undefined;
                assists: number | undefined;
                fielding_percentage: string | undefined;
                fielding_errors: number | undefined;
                double_plays: number | undefined;
                pickoffs: number | undefined;
                runners_caught_stealing: number | undefined;
                caught_stealing_percentage: number | undefined;
                points: number | undefined;
              }
            | { points: number | undefined }
            | {
                sets_played: number | undefined;
                service_attempts: number | undefined;
                service_points_won: number | undefined;
                service_aces: number | undefined;
                service_faults: number | undefined;
                double_faults: number | undefined;
                points: number | undefined;
                returns_attempted: number | undefined;
                return_points_won: number | undefined;
                break_point_attempts: number | undefined;
                break_points_won: number | undefined;
                ejections: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
              }
            | { points: number | undefined }
            | { points: number | undefined }
            | {
                sets_played: number | undefined;
                kills: number | undefined;
                attack_errors: number | undefined;
                total_attack_attempts: number | undefined;
                attacking_percentage: number | undefined;
                assists: number | undefined;
                service_aces: number | undefined;
                service_errors: number | undefined;
                receiving_errors: number | undefined;
                digs: number | undefined;
                solo_blocks: number | undefined;
                block_assists: number | undefined;
                total_blocks: number | undefined;
                blocking_errors: number | undefined;
                ball_handling_errors: number | undefined;
                points: number | undefined;
              }
            | {
                shots: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shooting_percentage: number | undefined;
                steals: number | undefined;
                ejects_drawn: number | undefined;
                ejects_given: number | undefined;
                fast_break: number | undefined;
                sprints_won: number | undefined;
                sprints_attempted: number | undefined;
                minutes_in_goal: number | undefined;
                shots_against: number | undefined;
                saves: number | undefined;
                goals_against: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                points: number | undefined;
              };
          gamePlayers: Array<{
            id: string;
            jersey_number: string | undefined;
            first_name: string | undefined;
            last_name: string | undefined;
            web_path: string | undefined;
            image: string | undefined;
            stat:
              | {
                  at_bats: number | undefined;
                  runs: number | undefined;
                  hits: number | undefined;
                  singles: number | undefined;
                  doubles: number | undefined;
                  triples: number | undefined;
                  home_runs: number | undefined;
                  runs_batted_in: number | undefined;
                  total_bases: number | undefined;
                  total_walks: number | undefined;
                  strikeouts_off: number | undefined;
                  stolen_bases: number | undefined;
                  batting_average: string | undefined;
                  on_base_percentage: string | undefined;
                  slugging_percentage: string | undefined;
                  on_base_plus_slugging: string | undefined;
                  innings_pitched: string | undefined;
                  hits_allowed: number | undefined;
                  earned_runs_allowed: number | undefined;
                  walks_allowed: number | undefined;
                  strikeouts_def: number | undefined;
                  home_runs_allowed: number | undefined;
                  pitch_count: number | undefined;
                  earned_run_average: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  holds: number | undefined;
                  saves: number | undefined;
                  put_outs: number | undefined;
                  assists: number | undefined;
                  fielding_errors: number | undefined;
                  double_plays: number | undefined;
                  pickoffs: number | undefined;
                  stolen_base_attempts: number | undefined;
                  runners_caught_stealing: number | undefined;
                  points: number | undefined;
                }
              | {
                  field_goals_made: number | undefined;
                  field_goals_attempted: number | undefined;
                  three_points_made: number | undefined;
                  three_points_attempted: number | undefined;
                  free_throws_made: number | undefined;
                  free_throws_attempted: number | undefined;
                  offensive_rebounds: number | undefined;
                  defensive_rebounds: number | undefined;
                  rebounds: number | undefined;
                  assists: number | undefined;
                  steals: number | undefined;
                  blocks: number | undefined;
                  turnovers: number | undefined;
                  personal_fouls: number | undefined;
                  points: number | undefined;
                }
              | {
                  sets_played: number | undefined;
                  kills: number | undefined;
                  attack_errors: number | undefined;
                  total_attack_attempts: number | undefined;
                  attacking_percentage: number | undefined;
                  assists: number | undefined;
                  service_aces: number | undefined;
                  service_errors: number | undefined;
                  receiving_errors: number | undefined;
                  digs: number | undefined;
                  solo_blocks: number | undefined;
                  block_assists: number | undefined;
                  total_blocks: number | undefined;
                  blocking_errors: number | undefined;
                  ball_handling_errors: number | undefined;
                  points: number | undefined;
                }
              | {
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shots: number | undefined;
                  shots_on_goal: number | undefined;
                  defensive_save: number | undefined;
                  penalty_corners: number | undefined;
                  fouls: number | undefined;
                  minutes_played: number | undefined;
                  goals_against: number | undefined;
                  goalie_saves: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  ties: number | undefined;
                  points: number | undefined;
                }
              | {
                  passing_completions: number | undefined;
                  passing_attempts: number | undefined;
                  passing_yards: number | undefined;
                  yards_per_attempt: number | undefined;
                  passing_sacks: number | undefined;
                  passing_touchdowns: number | undefined;
                  passing_interceptions: number | undefined;
                  rushing_attempts: number | undefined;
                  rushing_yards: number | undefined;
                  rushing_yards_per_attempt: number | undefined;
                  rushing_longest: number | undefined;
                  rushing_touchdowns: number | undefined;
                  rushing_fumbles: number | undefined;
                  receiving_receptions: number | undefined;
                  targets: number | undefined;
                  receiving_yards: number | undefined;
                  yards_per_reception: string | undefined;
                  receiving_longest: number | undefined;
                  receiving_touchdowns: number | undefined;
                  receiving_fumbles: number | undefined;
                  solo_tackles: number | undefined;
                  assisted_tackles: number | undefined;
                  total_tackles: number | undefined;
                  tackles_for_loss: number | undefined;
                  defensive_sacks: number | undefined;
                  yards_lost: number | undefined;
                  hurries: number | undefined;
                  qb_hits: number | undefined;
                  defensive_passes_defended: number | undefined;
                  defensive_interceptions: number | undefined;
                  defensive_interception_yards: number | undefined;
                  defensive_fumbles_forced: number | undefined;
                  defensive_fumbles_recovered: number | undefined;
                  defensive_blocked_punts: number | undefined;
                  defensive_blocked_field_goals: number | undefined;
                  defensive_touchdown: number | undefined;
                  field_goals_made: number | undefined;
                  field_goals_attempts: number | undefined;
                  field_goal_percentage: number | undefined;
                  field_goals_longest: number | undefined;
                  pat_made: number | undefined;
                  pat_attempts: number | undefined;
                  points: number | undefined;
                  punts: number | undefined;
                  punt_yards: number | undefined;
                  average_punt_yards: number | undefined;
                  punt_long: number | undefined;
                  punts_inside_20_yard_line: number | undefined;
                  total_kick_returns: number | undefined;
                  kickoff_return_yards: number | undefined;
                  yards_per_kick_return: number | undefined;
                  kick_return_long: number | undefined;
                  kick_return_touchdowns: number | undefined;
                  total_punt_returns: number | undefined;
                  punt_return_yards: number | undefined;
                  yards_per_punt_return: number | undefined;
                  punt_return_long: number | undefined;
                  punt_return_touchdowns: number | undefined;
                }
              | {
                  passing_completions: number | undefined;
                  passing_attempts: number | undefined;
                  passing_yards: number | undefined;
                  yards_per_attempt: number | undefined;
                  passing_sacks: number | undefined;
                  passing_touchdowns: number | undefined;
                  passing_interceptions: number | undefined;
                  rushing_attempts: number | undefined;
                  rushing_yards: number | undefined;
                  rushing_yards_per_attempt: number | undefined;
                  rushing_longest: number | undefined;
                  rushing_touchdowns: number | undefined;
                  rushing_fumbles: number | undefined;
                  receiving_receptions: number | undefined;
                  targets: number | undefined;
                  receiving_yards: number | undefined;
                  yards_per_reception: string | undefined;
                  receiving_longest: number | undefined;
                  receiving_touchdowns: number | undefined;
                  receiving_fumbles: number | undefined;
                  solo_tackles: number | undefined;
                  assisted_tackles: number | undefined;
                  total_tackles: number | undefined;
                  tackles_for_loss: number | undefined;
                  defensive_sacks: number | undefined;
                  yards_lost: number | undefined;
                  hurries: number | undefined;
                  qb_hits: number | undefined;
                  defensive_passes_defended: number | undefined;
                  defensive_interceptions: number | undefined;
                  defensive_interception_yards: number | undefined;
                  defensive_fumbles_forced: number | undefined;
                  defensive_fumbles_recovered: number | undefined;
                  defensive_blocked_punts: number | undefined;
                  defensive_blocked_field_goals: number | undefined;
                  defensive_touchdown: number | undefined;
                  field_goals_made: number | undefined;
                  field_goals_attempts: number | undefined;
                  field_goal_percentage: number | undefined;
                  field_goals_longest: number | undefined;
                  pat_made: number | undefined;
                  pat_attempts: number | undefined;
                  points: number | undefined;
                  punts: number | undefined;
                  punt_yards: number | undefined;
                  average_punt_yards: number | undefined;
                  punt_long: number | undefined;
                  punts_inside_20_yard_line: number | undefined;
                  total_kick_returns: number | undefined;
                  kickoff_return_yards: number | undefined;
                  yards_per_kick_return: number | undefined;
                  kick_return_long: number | undefined;
                  kick_return_touchdowns: number | undefined;
                  total_punt_returns: number | undefined;
                  punt_return_yards: number | undefined;
                  yards_per_punt_return: number | undefined;
                  punt_return_long: number | undefined;
                  punt_return_touchdowns: number | undefined;
                }
              | {
                  rounds_played: number | undefined;
                  total_strokes: number | undefined;
                  average_score: number | undefined;
                  vs_par: number | undefined;
                  vs_par_average: number | undefined;
                  points: number | undefined;
                }
              | {
                  time_on_ice: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  penalties_in_minutes: number | undefined;
                  power_play_goals: number | undefined;
                  power_play_assists: number | undefined;
                  short_hand_goals: number | undefined;
                  short_hand_assists: number | undefined;
                  shots: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  goals_against: number | undefined;
                  shots_against: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  overtime_losses: number | undefined;
                  shootout_shorts_against: number | undefined;
                  shootout_saves: number | undefined;
                  shootout_save_percentage: number | undefined;
                  points: number | undefined;
                }
              | {
                  minutes_played: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shots: number | undefined;
                  shots_on_goal: number | undefined;
                  ground_balls: number | undefined;
                  faceoffs_won: number | undefined;
                  total_faceoffs: number | undefined;
                  turnovers: number | undefined;
                  clears: number | undefined;
                  penalties: number | undefined;
                  minutes_in_goal: number | undefined;
                  goals_against: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  ties: number | undefined;
                  points: number | undefined;
                }
              | {
                  minutes_played: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  shots: number | undefined;
                  shot_percentage: number | undefined;
                  shots_on_goal: number | undefined;
                  shots_on_goal_percentage: number | undefined;
                  penalty_kick_attempts: number | undefined;
                  penalty_kick_goals: number | undefined;
                  penalty_kick_percentage: number | undefined;
                  goals_against: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  draws: number | undefined;
                  points: number | undefined;
                }
              | {
                  games_played: number | undefined;
                  at_bats: number | undefined;
                  runs: number | undefined;
                  hits: number | undefined;
                  singles: number | undefined;
                  doubles: number | undefined;
                  triples: number | undefined;
                  home_runs: number | undefined;
                  runs_batted_in: number | undefined;
                  total_bases: number | undefined;
                  total_walks: number | undefined;
                  strikeouts_off: number | undefined;
                  stolen_bases: number | undefined;
                  batting_average: string | undefined;
                  on_base_percentage: string | undefined;
                  slugging_percentage: string | undefined;
                  on_base_plus_slugging: string | undefined;
                  games_started: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  saves: number | undefined;
                  holds: number | undefined;
                  innings_pitched: string | undefined;
                  hits_allowed: number | undefined;
                  earned_runs_allowed: number | undefined;
                  home_runs_allowed: number | undefined;
                  walks_allowed: number | undefined;
                  strikeouts_def: number | undefined;
                  strikeouts_per_9_innings: number | undefined;
                  walks_hits_per_inning_pitched: number | undefined;
                  earned_run_average: number | undefined;
                  total_chances: number | undefined;
                  put_outs: number | undefined;
                  assists: number | undefined;
                  fielding_percentage: string | undefined;
                  fielding_errors: number | undefined;
                  double_plays: number | undefined;
                  pickoffs: number | undefined;
                  runners_caught_stealing: number | undefined;
                  caught_stealing_percentage: number | undefined;
                  points: number | undefined;
                }
              | { points: number | undefined }
              | {
                  sets_played: number | undefined;
                  service_attempts: number | undefined;
                  service_points_won: number | undefined;
                  service_aces: number | undefined;
                  service_faults: number | undefined;
                  double_faults: number | undefined;
                  points: number | undefined;
                  returns_attempted: number | undefined;
                  return_points_won: number | undefined;
                  break_point_attempts: number | undefined;
                  break_points_won: number | undefined;
                  ejections: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                }
              | { points: number | undefined }
              | { points: number | undefined }
              | {
                  sets_played: number | undefined;
                  kills: number | undefined;
                  attack_errors: number | undefined;
                  total_attack_attempts: number | undefined;
                  attacking_percentage: number | undefined;
                  assists: number | undefined;
                  service_aces: number | undefined;
                  service_errors: number | undefined;
                  receiving_errors: number | undefined;
                  digs: number | undefined;
                  solo_blocks: number | undefined;
                  block_assists: number | undefined;
                  total_blocks: number | undefined;
                  blocking_errors: number | undefined;
                  ball_handling_errors: number | undefined;
                  points: number | undefined;
                }
              | {
                  shots: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shooting_percentage: number | undefined;
                  steals: number | undefined;
                  ejects_drawn: number | undefined;
                  ejects_given: number | undefined;
                  fast_break: number | undefined;
                  sprints_won: number | undefined;
                  sprints_attempted: number | undefined;
                  minutes_in_goal: number | undefined;
                  shots_against: number | undefined;
                  saves: number | undefined;
                  goals_against: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  points: number | undefined;
                };
          }>;
        }>;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        locationText: string | undefined;
        gameTypeLabel: string;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        genderSport: { sportId: number; slug: string; name: string };
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          linescore: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
          stat:
            | {
                at_bats: number | undefined;
                runs: number | undefined;
                hits: number | undefined;
                singles: number | undefined;
                doubles: number | undefined;
                triples: number | undefined;
                home_runs: number | undefined;
                runs_batted_in: number | undefined;
                total_bases: number | undefined;
                total_walks: number | undefined;
                strikeouts_off: number | undefined;
                stolen_bases: number | undefined;
                batting_average: string | undefined;
                on_base_percentage: string | undefined;
                slugging_percentage: string | undefined;
                on_base_plus_slugging: string | undefined;
                innings_pitched: string | undefined;
                hits_allowed: number | undefined;
                earned_runs_allowed: number | undefined;
                walks_allowed: number | undefined;
                strikeouts_def: number | undefined;
                home_runs_allowed: number | undefined;
                pitch_count: number | undefined;
                earned_run_average: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                holds: number | undefined;
                saves: number | undefined;
                put_outs: number | undefined;
                assists: number | undefined;
                fielding_errors: number | undefined;
                double_plays: number | undefined;
                pickoffs: number | undefined;
                stolen_base_attempts: number | undefined;
                runners_caught_stealing: number | undefined;
                points: number | undefined;
              }
            | {
                field_goals_made: number | undefined;
                field_goals_attempted: number | undefined;
                three_points_made: number | undefined;
                three_points_attempted: number | undefined;
                free_throws_made: number | undefined;
                free_throws_attempted: number | undefined;
                offensive_rebounds: number | undefined;
                defensive_rebounds: number | undefined;
                rebounds: number | undefined;
                assists: number | undefined;
                steals: number | undefined;
                blocks: number | undefined;
                turnovers: number | undefined;
                personal_fouls: number | undefined;
                points: number | undefined;
              }
            | {
                sets_played: number | undefined;
                kills: number | undefined;
                attack_errors: number | undefined;
                total_attack_attempts: number | undefined;
                attacking_percentage: number | undefined;
                assists: number | undefined;
                service_aces: number | undefined;
                service_errors: number | undefined;
                receiving_errors: number | undefined;
                digs: number | undefined;
                solo_blocks: number | undefined;
                block_assists: number | undefined;
                total_blocks: number | undefined;
                blocking_errors: number | undefined;
                ball_handling_errors: number | undefined;
                points: number | undefined;
              }
            | {
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shots: number | undefined;
                shots_on_goal: number | undefined;
                defensive_save: number | undefined;
                penalty_corners: number | undefined;
                fouls: number | undefined;
                minutes_played: number | undefined;
                goals_against: number | undefined;
                goalie_saves: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                ties: number | undefined;
                points: number | undefined;
              }
            | {
                passing_completions: number | undefined;
                passing_attempts: number | undefined;
                passing_yards: number | undefined;
                yards_per_attempt: number | undefined;
                passing_sacks: number | undefined;
                passing_touchdowns: number | undefined;
                passing_interceptions: number | undefined;
                rushing_attempts: number | undefined;
                rushing_yards: number | undefined;
                rushing_yards_per_attempt: number | undefined;
                rushing_longest: number | undefined;
                rushing_touchdowns: number | undefined;
                rushing_fumbles: number | undefined;
                receiving_receptions: number | undefined;
                targets: number | undefined;
                receiving_yards: number | undefined;
                yards_per_reception: string | undefined;
                receiving_longest: number | undefined;
                receiving_touchdowns: number | undefined;
                receiving_fumbles: number | undefined;
                solo_tackles: number | undefined;
                assisted_tackles: number | undefined;
                total_tackles: number | undefined;
                tackles_for_loss: number | undefined;
                defensive_sacks: number | undefined;
                yards_lost: number | undefined;
                hurries: number | undefined;
                qb_hits: number | undefined;
                defensive_passes_defended: number | undefined;
                defensive_interceptions: number | undefined;
                defensive_interception_yards: number | undefined;
                defensive_fumbles_forced: number | undefined;
                defensive_fumbles_recovered: number | undefined;
                defensive_blocked_punts: number | undefined;
                defensive_blocked_field_goals: number | undefined;
                defensive_touchdown: number | undefined;
                field_goals_made: number | undefined;
                field_goals_attempts: number | undefined;
                field_goal_percentage: number | undefined;
                field_goals_longest: number | undefined;
                pat_made: number | undefined;
                pat_attempts: number | undefined;
                points: number | undefined;
                punts: number | undefined;
                punt_yards: number | undefined;
                average_punt_yards: number | undefined;
                punt_long: number | undefined;
                punts_inside_20_yard_line: number | undefined;
                total_kick_returns: number | undefined;
                kickoff_return_yards: number | undefined;
                yards_per_kick_return: number | undefined;
                kick_return_long: number | undefined;
                kick_return_touchdowns: number | undefined;
                total_punt_returns: number | undefined;
                punt_return_yards: number | undefined;
                yards_per_punt_return: number | undefined;
                punt_return_long: number | undefined;
                punt_return_touchdowns: number | undefined;
              }
            | {
                passing_completions: number | undefined;
                passing_attempts: number | undefined;
                passing_yards: number | undefined;
                yards_per_attempt: number | undefined;
                passing_sacks: number | undefined;
                passing_touchdowns: number | undefined;
                passing_interceptions: number | undefined;
                rushing_attempts: number | undefined;
                rushing_yards: number | undefined;
                rushing_yards_per_attempt: number | undefined;
                rushing_longest: number | undefined;
                rushing_touchdowns: number | undefined;
                rushing_fumbles: number | undefined;
                receiving_receptions: number | undefined;
                targets: number | undefined;
                receiving_yards: number | undefined;
                yards_per_reception: string | undefined;
                receiving_longest: number | undefined;
                receiving_touchdowns: number | undefined;
                receiving_fumbles: number | undefined;
                solo_tackles: number | undefined;
                assisted_tackles: number | undefined;
                total_tackles: number | undefined;
                tackles_for_loss: number | undefined;
                defensive_sacks: number | undefined;
                yards_lost: number | undefined;
                hurries: number | undefined;
                qb_hits: number | undefined;
                defensive_passes_defended: number | undefined;
                defensive_interceptions: number | undefined;
                defensive_interception_yards: number | undefined;
                defensive_fumbles_forced: number | undefined;
                defensive_fumbles_recovered: number | undefined;
                defensive_blocked_punts: number | undefined;
                defensive_blocked_field_goals: number | undefined;
                defensive_touchdown: number | undefined;
                field_goals_made: number | undefined;
                field_goals_attempts: number | undefined;
                field_goal_percentage: number | undefined;
                field_goals_longest: number | undefined;
                pat_made: number | undefined;
                pat_attempts: number | undefined;
                points: number | undefined;
                punts: number | undefined;
                punt_yards: number | undefined;
                average_punt_yards: number | undefined;
                punt_long: number | undefined;
                punts_inside_20_yard_line: number | undefined;
                total_kick_returns: number | undefined;
                kickoff_return_yards: number | undefined;
                yards_per_kick_return: number | undefined;
                kick_return_long: number | undefined;
                kick_return_touchdowns: number | undefined;
                total_punt_returns: number | undefined;
                punt_return_yards: number | undefined;
                yards_per_punt_return: number | undefined;
                punt_return_long: number | undefined;
                punt_return_touchdowns: number | undefined;
              }
            | {
                rounds_played: number | undefined;
                total_strokes: number | undefined;
                average_score: number | undefined;
                vs_par: number | undefined;
                vs_par_average: number | undefined;
                points: number | undefined;
              }
            | {
                time_on_ice: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                penalties_in_minutes: number | undefined;
                power_play_goals: number | undefined;
                power_play_assists: number | undefined;
                short_hand_goals: number | undefined;
                short_hand_assists: number | undefined;
                shots: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                goals_against: number | undefined;
                shots_against: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                overtime_losses: number | undefined;
                shootout_shorts_against: number | undefined;
                shootout_saves: number | undefined;
                shootout_save_percentage: number | undefined;
                points: number | undefined;
              }
            | {
                minutes_played: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shots: number | undefined;
                shots_on_goal: number | undefined;
                ground_balls: number | undefined;
                faceoffs_won: number | undefined;
                total_faceoffs: number | undefined;
                turnovers: number | undefined;
                clears: number | undefined;
                penalties: number | undefined;
                minutes_in_goal: number | undefined;
                goals_against: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                ties: number | undefined;
                points: number | undefined;
              }
            | {
                minutes_played: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                shots: number | undefined;
                shot_percentage: number | undefined;
                shots_on_goal: number | undefined;
                shots_on_goal_percentage: number | undefined;
                penalty_kick_attempts: number | undefined;
                penalty_kick_goals: number | undefined;
                penalty_kick_percentage: number | undefined;
                goals_against: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                draws: number | undefined;
                points: number | undefined;
              }
            | {
                games_played: number | undefined;
                at_bats: number | undefined;
                runs: number | undefined;
                hits: number | undefined;
                singles: number | undefined;
                doubles: number | undefined;
                triples: number | undefined;
                home_runs: number | undefined;
                runs_batted_in: number | undefined;
                total_bases: number | undefined;
                total_walks: number | undefined;
                strikeouts_off: number | undefined;
                stolen_bases: number | undefined;
                batting_average: string | undefined;
                on_base_percentage: string | undefined;
                slugging_percentage: string | undefined;
                on_base_plus_slugging: string | undefined;
                games_started: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                saves: number | undefined;
                holds: number | undefined;
                innings_pitched: string | undefined;
                hits_allowed: number | undefined;
                earned_runs_allowed: number | undefined;
                home_runs_allowed: number | undefined;
                walks_allowed: number | undefined;
                strikeouts_def: number | undefined;
                strikeouts_per_9_innings: number | undefined;
                walks_hits_per_inning_pitched: number | undefined;
                earned_run_average: number | undefined;
                total_chances: number | undefined;
                put_outs: number | undefined;
                assists: number | undefined;
                fielding_percentage: string | undefined;
                fielding_errors: number | undefined;
                double_plays: number | undefined;
                pickoffs: number | undefined;
                runners_caught_stealing: number | undefined;
                caught_stealing_percentage: number | undefined;
                points: number | undefined;
              }
            | { points: number | undefined }
            | {
                sets_played: number | undefined;
                service_attempts: number | undefined;
                service_points_won: number | undefined;
                service_aces: number | undefined;
                service_faults: number | undefined;
                double_faults: number | undefined;
                points: number | undefined;
                returns_attempted: number | undefined;
                return_points_won: number | undefined;
                break_point_attempts: number | undefined;
                break_points_won: number | undefined;
                ejections: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
              }
            | { points: number | undefined }
            | { points: number | undefined }
            | {
                sets_played: number | undefined;
                kills: number | undefined;
                attack_errors: number | undefined;
                total_attack_attempts: number | undefined;
                attacking_percentage: number | undefined;
                assists: number | undefined;
                service_aces: number | undefined;
                service_errors: number | undefined;
                receiving_errors: number | undefined;
                digs: number | undefined;
                solo_blocks: number | undefined;
                block_assists: number | undefined;
                total_blocks: number | undefined;
                blocking_errors: number | undefined;
                ball_handling_errors: number | undefined;
                points: number | undefined;
              }
            | {
                shots: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shooting_percentage: number | undefined;
                steals: number | undefined;
                ejects_drawn: number | undefined;
                ejects_given: number | undefined;
                fast_break: number | undefined;
                sprints_won: number | undefined;
                sprints_attempted: number | undefined;
                minutes_in_goal: number | undefined;
                shots_against: number | undefined;
                saves: number | undefined;
                goals_against: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                points: number | undefined;
              };
          gamePlayers: Array<{
            id: string;
            jersey_number: string | undefined;
            first_name: string | undefined;
            last_name: string | undefined;
            web_path: string | undefined;
            image: string | undefined;
            stat:
              | {
                  at_bats: number | undefined;
                  runs: number | undefined;
                  hits: number | undefined;
                  singles: number | undefined;
                  doubles: number | undefined;
                  triples: number | undefined;
                  home_runs: number | undefined;
                  runs_batted_in: number | undefined;
                  total_bases: number | undefined;
                  total_walks: number | undefined;
                  strikeouts_off: number | undefined;
                  stolen_bases: number | undefined;
                  batting_average: string | undefined;
                  on_base_percentage: string | undefined;
                  slugging_percentage: string | undefined;
                  on_base_plus_slugging: string | undefined;
                  innings_pitched: string | undefined;
                  hits_allowed: number | undefined;
                  earned_runs_allowed: number | undefined;
                  walks_allowed: number | undefined;
                  strikeouts_def: number | undefined;
                  home_runs_allowed: number | undefined;
                  pitch_count: number | undefined;
                  earned_run_average: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  holds: number | undefined;
                  saves: number | undefined;
                  put_outs: number | undefined;
                  assists: number | undefined;
                  fielding_errors: number | undefined;
                  double_plays: number | undefined;
                  pickoffs: number | undefined;
                  stolen_base_attempts: number | undefined;
                  runners_caught_stealing: number | undefined;
                  points: number | undefined;
                }
              | {
                  field_goals_made: number | undefined;
                  field_goals_attempted: number | undefined;
                  three_points_made: number | undefined;
                  three_points_attempted: number | undefined;
                  free_throws_made: number | undefined;
                  free_throws_attempted: number | undefined;
                  offensive_rebounds: number | undefined;
                  defensive_rebounds: number | undefined;
                  rebounds: number | undefined;
                  assists: number | undefined;
                  steals: number | undefined;
                  blocks: number | undefined;
                  turnovers: number | undefined;
                  personal_fouls: number | undefined;
                  points: number | undefined;
                }
              | {
                  sets_played: number | undefined;
                  kills: number | undefined;
                  attack_errors: number | undefined;
                  total_attack_attempts: number | undefined;
                  attacking_percentage: number | undefined;
                  assists: number | undefined;
                  service_aces: number | undefined;
                  service_errors: number | undefined;
                  receiving_errors: number | undefined;
                  digs: number | undefined;
                  solo_blocks: number | undefined;
                  block_assists: number | undefined;
                  total_blocks: number | undefined;
                  blocking_errors: number | undefined;
                  ball_handling_errors: number | undefined;
                  points: number | undefined;
                }
              | {
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shots: number | undefined;
                  shots_on_goal: number | undefined;
                  defensive_save: number | undefined;
                  penalty_corners: number | undefined;
                  fouls: number | undefined;
                  minutes_played: number | undefined;
                  goals_against: number | undefined;
                  goalie_saves: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  ties: number | undefined;
                  points: number | undefined;
                }
              | {
                  passing_completions: number | undefined;
                  passing_attempts: number | undefined;
                  passing_yards: number | undefined;
                  yards_per_attempt: number | undefined;
                  passing_sacks: number | undefined;
                  passing_touchdowns: number | undefined;
                  passing_interceptions: number | undefined;
                  rushing_attempts: number | undefined;
                  rushing_yards: number | undefined;
                  rushing_yards_per_attempt: number | undefined;
                  rushing_longest: number | undefined;
                  rushing_touchdowns: number | undefined;
                  rushing_fumbles: number | undefined;
                  receiving_receptions: number | undefined;
                  targets: number | undefined;
                  receiving_yards: number | undefined;
                  yards_per_reception: string | undefined;
                  receiving_longest: number | undefined;
                  receiving_touchdowns: number | undefined;
                  receiving_fumbles: number | undefined;
                  solo_tackles: number | undefined;
                  assisted_tackles: number | undefined;
                  total_tackles: number | undefined;
                  tackles_for_loss: number | undefined;
                  defensive_sacks: number | undefined;
                  yards_lost: number | undefined;
                  hurries: number | undefined;
                  qb_hits: number | undefined;
                  defensive_passes_defended: number | undefined;
                  defensive_interceptions: number | undefined;
                  defensive_interception_yards: number | undefined;
                  defensive_fumbles_forced: number | undefined;
                  defensive_fumbles_recovered: number | undefined;
                  defensive_blocked_punts: number | undefined;
                  defensive_blocked_field_goals: number | undefined;
                  defensive_touchdown: number | undefined;
                  field_goals_made: number | undefined;
                  field_goals_attempts: number | undefined;
                  field_goal_percentage: number | undefined;
                  field_goals_longest: number | undefined;
                  pat_made: number | undefined;
                  pat_attempts: number | undefined;
                  points: number | undefined;
                  punts: number | undefined;
                  punt_yards: number | undefined;
                  average_punt_yards: number | undefined;
                  punt_long: number | undefined;
                  punts_inside_20_yard_line: number | undefined;
                  total_kick_returns: number | undefined;
                  kickoff_return_yards: number | undefined;
                  yards_per_kick_return: number | undefined;
                  kick_return_long: number | undefined;
                  kick_return_touchdowns: number | undefined;
                  total_punt_returns: number | undefined;
                  punt_return_yards: number | undefined;
                  yards_per_punt_return: number | undefined;
                  punt_return_long: number | undefined;
                  punt_return_touchdowns: number | undefined;
                }
              | {
                  passing_completions: number | undefined;
                  passing_attempts: number | undefined;
                  passing_yards: number | undefined;
                  yards_per_attempt: number | undefined;
                  passing_sacks: number | undefined;
                  passing_touchdowns: number | undefined;
                  passing_interceptions: number | undefined;
                  rushing_attempts: number | undefined;
                  rushing_yards: number | undefined;
                  rushing_yards_per_attempt: number | undefined;
                  rushing_longest: number | undefined;
                  rushing_touchdowns: number | undefined;
                  rushing_fumbles: number | undefined;
                  receiving_receptions: number | undefined;
                  targets: number | undefined;
                  receiving_yards: number | undefined;
                  yards_per_reception: string | undefined;
                  receiving_longest: number | undefined;
                  receiving_touchdowns: number | undefined;
                  receiving_fumbles: number | undefined;
                  solo_tackles: number | undefined;
                  assisted_tackles: number | undefined;
                  total_tackles: number | undefined;
                  tackles_for_loss: number | undefined;
                  defensive_sacks: number | undefined;
                  yards_lost: number | undefined;
                  hurries: number | undefined;
                  qb_hits: number | undefined;
                  defensive_passes_defended: number | undefined;
                  defensive_interceptions: number | undefined;
                  defensive_interception_yards: number | undefined;
                  defensive_fumbles_forced: number | undefined;
                  defensive_fumbles_recovered: number | undefined;
                  defensive_blocked_punts: number | undefined;
                  defensive_blocked_field_goals: number | undefined;
                  defensive_touchdown: number | undefined;
                  field_goals_made: number | undefined;
                  field_goals_attempts: number | undefined;
                  field_goal_percentage: number | undefined;
                  field_goals_longest: number | undefined;
                  pat_made: number | undefined;
                  pat_attempts: number | undefined;
                  points: number | undefined;
                  punts: number | undefined;
                  punt_yards: number | undefined;
                  average_punt_yards: number | undefined;
                  punt_long: number | undefined;
                  punts_inside_20_yard_line: number | undefined;
                  total_kick_returns: number | undefined;
                  kickoff_return_yards: number | undefined;
                  yards_per_kick_return: number | undefined;
                  kick_return_long: number | undefined;
                  kick_return_touchdowns: number | undefined;
                  total_punt_returns: number | undefined;
                  punt_return_yards: number | undefined;
                  yards_per_punt_return: number | undefined;
                  punt_return_long: number | undefined;
                  punt_return_touchdowns: number | undefined;
                }
              | {
                  rounds_played: number | undefined;
                  total_strokes: number | undefined;
                  average_score: number | undefined;
                  vs_par: number | undefined;
                  vs_par_average: number | undefined;
                  points: number | undefined;
                }
              | {
                  time_on_ice: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  penalties_in_minutes: number | undefined;
                  power_play_goals: number | undefined;
                  power_play_assists: number | undefined;
                  short_hand_goals: number | undefined;
                  short_hand_assists: number | undefined;
                  shots: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  goals_against: number | undefined;
                  shots_against: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  overtime_losses: number | undefined;
                  shootout_shorts_against: number | undefined;
                  shootout_saves: number | undefined;
                  shootout_save_percentage: number | undefined;
                  points: number | undefined;
                }
              | {
                  minutes_played: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shots: number | undefined;
                  shots_on_goal: number | undefined;
                  ground_balls: number | undefined;
                  faceoffs_won: number | undefined;
                  total_faceoffs: number | undefined;
                  turnovers: number | undefined;
                  clears: number | undefined;
                  penalties: number | undefined;
                  minutes_in_goal: number | undefined;
                  goals_against: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  ties: number | undefined;
                  points: number | undefined;
                }
              | {
                  minutes_played: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  shots: number | undefined;
                  shot_percentage: number | undefined;
                  shots_on_goal: number | undefined;
                  shots_on_goal_percentage: number | undefined;
                  penalty_kick_attempts: number | undefined;
                  penalty_kick_goals: number | undefined;
                  penalty_kick_percentage: number | undefined;
                  goals_against: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  draws: number | undefined;
                  points: number | undefined;
                }
              | {
                  games_played: number | undefined;
                  at_bats: number | undefined;
                  runs: number | undefined;
                  hits: number | undefined;
                  singles: number | undefined;
                  doubles: number | undefined;
                  triples: number | undefined;
                  home_runs: number | undefined;
                  runs_batted_in: number | undefined;
                  total_bases: number | undefined;
                  total_walks: number | undefined;
                  strikeouts_off: number | undefined;
                  stolen_bases: number | undefined;
                  batting_average: string | undefined;
                  on_base_percentage: string | undefined;
                  slugging_percentage: string | undefined;
                  on_base_plus_slugging: string | undefined;
                  games_started: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  saves: number | undefined;
                  holds: number | undefined;
                  innings_pitched: string | undefined;
                  hits_allowed: number | undefined;
                  earned_runs_allowed: number | undefined;
                  home_runs_allowed: number | undefined;
                  walks_allowed: number | undefined;
                  strikeouts_def: number | undefined;
                  strikeouts_per_9_innings: number | undefined;
                  walks_hits_per_inning_pitched: number | undefined;
                  earned_run_average: number | undefined;
                  total_chances: number | undefined;
                  put_outs: number | undefined;
                  assists: number | undefined;
                  fielding_percentage: string | undefined;
                  fielding_errors: number | undefined;
                  double_plays: number | undefined;
                  pickoffs: number | undefined;
                  runners_caught_stealing: number | undefined;
                  caught_stealing_percentage: number | undefined;
                  points: number | undefined;
                }
              | { points: number | undefined }
              | {
                  sets_played: number | undefined;
                  service_attempts: number | undefined;
                  service_points_won: number | undefined;
                  service_aces: number | undefined;
                  service_faults: number | undefined;
                  double_faults: number | undefined;
                  points: number | undefined;
                  returns_attempted: number | undefined;
                  return_points_won: number | undefined;
                  break_point_attempts: number | undefined;
                  break_points_won: number | undefined;
                  ejections: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                }
              | { points: number | undefined }
              | { points: number | undefined }
              | {
                  sets_played: number | undefined;
                  kills: number | undefined;
                  attack_errors: number | undefined;
                  total_attack_attempts: number | undefined;
                  attacking_percentage: number | undefined;
                  assists: number | undefined;
                  service_aces: number | undefined;
                  service_errors: number | undefined;
                  receiving_errors: number | undefined;
                  digs: number | undefined;
                  solo_blocks: number | undefined;
                  block_assists: number | undefined;
                  total_blocks: number | undefined;
                  blocking_errors: number | undefined;
                  ball_handling_errors: number | undefined;
                  points: number | undefined;
                }
              | {
                  shots: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shooting_percentage: number | undefined;
                  steals: number | undefined;
                  ejects_drawn: number | undefined;
                  ejects_given: number | undefined;
                  fast_break: number | undefined;
                  sprints_won: number | undefined;
                  sprints_attempted: number | undefined;
                  minutes_in_goal: number | undefined;
                  shots_against: number | undefined;
                  saves: number | undefined;
                  goals_against: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  points: number | undefined;
                };
          }>;
        }>;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        locationText: string | undefined;
        gameTypeLabel: string;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        genderSport: { sportId: number; slug: string; name: string };
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          linescore: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
          stat:
            | {
                at_bats: number | undefined;
                runs: number | undefined;
                hits: number | undefined;
                singles: number | undefined;
                doubles: number | undefined;
                triples: number | undefined;
                home_runs: number | undefined;
                runs_batted_in: number | undefined;
                total_bases: number | undefined;
                total_walks: number | undefined;
                strikeouts_off: number | undefined;
                stolen_bases: number | undefined;
                batting_average: string | undefined;
                on_base_percentage: string | undefined;
                slugging_percentage: string | undefined;
                on_base_plus_slugging: string | undefined;
                innings_pitched: string | undefined;
                hits_allowed: number | undefined;
                earned_runs_allowed: number | undefined;
                walks_allowed: number | undefined;
                strikeouts_def: number | undefined;
                home_runs_allowed: number | undefined;
                pitch_count: number | undefined;
                earned_run_average: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                holds: number | undefined;
                saves: number | undefined;
                put_outs: number | undefined;
                assists: number | undefined;
                fielding_errors: number | undefined;
                double_plays: number | undefined;
                pickoffs: number | undefined;
                stolen_base_attempts: number | undefined;
                runners_caught_stealing: number | undefined;
                points: number | undefined;
              }
            | {
                field_goals_made: number | undefined;
                field_goals_attempted: number | undefined;
                three_points_made: number | undefined;
                three_points_attempted: number | undefined;
                free_throws_made: number | undefined;
                free_throws_attempted: number | undefined;
                offensive_rebounds: number | undefined;
                defensive_rebounds: number | undefined;
                rebounds: number | undefined;
                assists: number | undefined;
                steals: number | undefined;
                blocks: number | undefined;
                turnovers: number | undefined;
                personal_fouls: number | undefined;
                points: number | undefined;
              }
            | {
                sets_played: number | undefined;
                kills: number | undefined;
                attack_errors: number | undefined;
                total_attack_attempts: number | undefined;
                attacking_percentage: number | undefined;
                assists: number | undefined;
                service_aces: number | undefined;
                service_errors: number | undefined;
                receiving_errors: number | undefined;
                digs: number | undefined;
                solo_blocks: number | undefined;
                block_assists: number | undefined;
                total_blocks: number | undefined;
                blocking_errors: number | undefined;
                ball_handling_errors: number | undefined;
                points: number | undefined;
              }
            | {
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shots: number | undefined;
                shots_on_goal: number | undefined;
                defensive_save: number | undefined;
                penalty_corners: number | undefined;
                fouls: number | undefined;
                minutes_played: number | undefined;
                goals_against: number | undefined;
                goalie_saves: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                ties: number | undefined;
                points: number | undefined;
              }
            | {
                passing_completions: number | undefined;
                passing_attempts: number | undefined;
                passing_yards: number | undefined;
                yards_per_attempt: number | undefined;
                passing_sacks: number | undefined;
                passing_touchdowns: number | undefined;
                passing_interceptions: number | undefined;
                rushing_attempts: number | undefined;
                rushing_yards: number | undefined;
                rushing_yards_per_attempt: number | undefined;
                rushing_longest: number | undefined;
                rushing_touchdowns: number | undefined;
                rushing_fumbles: number | undefined;
                receiving_receptions: number | undefined;
                targets: number | undefined;
                receiving_yards: number | undefined;
                yards_per_reception: string | undefined;
                receiving_longest: number | undefined;
                receiving_touchdowns: number | undefined;
                receiving_fumbles: number | undefined;
                solo_tackles: number | undefined;
                assisted_tackles: number | undefined;
                total_tackles: number | undefined;
                tackles_for_loss: number | undefined;
                defensive_sacks: number | undefined;
                yards_lost: number | undefined;
                hurries: number | undefined;
                qb_hits: number | undefined;
                defensive_passes_defended: number | undefined;
                defensive_interceptions: number | undefined;
                defensive_interception_yards: number | undefined;
                defensive_fumbles_forced: number | undefined;
                defensive_fumbles_recovered: number | undefined;
                defensive_blocked_punts: number | undefined;
                defensive_blocked_field_goals: number | undefined;
                defensive_touchdown: number | undefined;
                field_goals_made: number | undefined;
                field_goals_attempts: number | undefined;
                field_goal_percentage: number | undefined;
                field_goals_longest: number | undefined;
                pat_made: number | undefined;
                pat_attempts: number | undefined;
                points: number | undefined;
                punts: number | undefined;
                punt_yards: number | undefined;
                average_punt_yards: number | undefined;
                punt_long: number | undefined;
                punts_inside_20_yard_line: number | undefined;
                total_kick_returns: number | undefined;
                kickoff_return_yards: number | undefined;
                yards_per_kick_return: number | undefined;
                kick_return_long: number | undefined;
                kick_return_touchdowns: number | undefined;
                total_punt_returns: number | undefined;
                punt_return_yards: number | undefined;
                yards_per_punt_return: number | undefined;
                punt_return_long: number | undefined;
                punt_return_touchdowns: number | undefined;
              }
            | {
                passing_completions: number | undefined;
                passing_attempts: number | undefined;
                passing_yards: number | undefined;
                yards_per_attempt: number | undefined;
                passing_sacks: number | undefined;
                passing_touchdowns: number | undefined;
                passing_interceptions: number | undefined;
                rushing_attempts: number | undefined;
                rushing_yards: number | undefined;
                rushing_yards_per_attempt: number | undefined;
                rushing_longest: number | undefined;
                rushing_touchdowns: number | undefined;
                rushing_fumbles: number | undefined;
                receiving_receptions: number | undefined;
                targets: number | undefined;
                receiving_yards: number | undefined;
                yards_per_reception: string | undefined;
                receiving_longest: number | undefined;
                receiving_touchdowns: number | undefined;
                receiving_fumbles: number | undefined;
                solo_tackles: number | undefined;
                assisted_tackles: number | undefined;
                total_tackles: number | undefined;
                tackles_for_loss: number | undefined;
                defensive_sacks: number | undefined;
                yards_lost: number | undefined;
                hurries: number | undefined;
                qb_hits: number | undefined;
                defensive_passes_defended: number | undefined;
                defensive_interceptions: number | undefined;
                defensive_interception_yards: number | undefined;
                defensive_fumbles_forced: number | undefined;
                defensive_fumbles_recovered: number | undefined;
                defensive_blocked_punts: number | undefined;
                defensive_blocked_field_goals: number | undefined;
                defensive_touchdown: number | undefined;
                field_goals_made: number | undefined;
                field_goals_attempts: number | undefined;
                field_goal_percentage: number | undefined;
                field_goals_longest: number | undefined;
                pat_made: number | undefined;
                pat_attempts: number | undefined;
                points: number | undefined;
                punts: number | undefined;
                punt_yards: number | undefined;
                average_punt_yards: number | undefined;
                punt_long: number | undefined;
                punts_inside_20_yard_line: number | undefined;
                total_kick_returns: number | undefined;
                kickoff_return_yards: number | undefined;
                yards_per_kick_return: number | undefined;
                kick_return_long: number | undefined;
                kick_return_touchdowns: number | undefined;
                total_punt_returns: number | undefined;
                punt_return_yards: number | undefined;
                yards_per_punt_return: number | undefined;
                punt_return_long: number | undefined;
                punt_return_touchdowns: number | undefined;
              }
            | {
                rounds_played: number | undefined;
                total_strokes: number | undefined;
                average_score: number | undefined;
                vs_par: number | undefined;
                vs_par_average: number | undefined;
                points: number | undefined;
              }
            | {
                time_on_ice: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                penalties_in_minutes: number | undefined;
                power_play_goals: number | undefined;
                power_play_assists: number | undefined;
                short_hand_goals: number | undefined;
                short_hand_assists: number | undefined;
                shots: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                goals_against: number | undefined;
                shots_against: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                overtime_losses: number | undefined;
                shootout_shorts_against: number | undefined;
                shootout_saves: number | undefined;
                shootout_save_percentage: number | undefined;
                points: number | undefined;
              }
            | {
                minutes_played: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shots: number | undefined;
                shots_on_goal: number | undefined;
                ground_balls: number | undefined;
                faceoffs_won: number | undefined;
                total_faceoffs: number | undefined;
                turnovers: number | undefined;
                clears: number | undefined;
                penalties: number | undefined;
                minutes_in_goal: number | undefined;
                goals_against: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                ties: number | undefined;
                points: number | undefined;
              }
            | {
                minutes_played: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                shots: number | undefined;
                shot_percentage: number | undefined;
                shots_on_goal: number | undefined;
                shots_on_goal_percentage: number | undefined;
                penalty_kick_attempts: number | undefined;
                penalty_kick_goals: number | undefined;
                penalty_kick_percentage: number | undefined;
                goals_against: number | undefined;
                saves: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                draws: number | undefined;
                points: number | undefined;
              }
            | {
                games_played: number | undefined;
                at_bats: number | undefined;
                runs: number | undefined;
                hits: number | undefined;
                singles: number | undefined;
                doubles: number | undefined;
                triples: number | undefined;
                home_runs: number | undefined;
                runs_batted_in: number | undefined;
                total_bases: number | undefined;
                total_walks: number | undefined;
                strikeouts_off: number | undefined;
                stolen_bases: number | undefined;
                batting_average: string | undefined;
                on_base_percentage: string | undefined;
                slugging_percentage: string | undefined;
                on_base_plus_slugging: string | undefined;
                games_started: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                saves: number | undefined;
                holds: number | undefined;
                innings_pitched: string | undefined;
                hits_allowed: number | undefined;
                earned_runs_allowed: number | undefined;
                home_runs_allowed: number | undefined;
                walks_allowed: number | undefined;
                strikeouts_def: number | undefined;
                strikeouts_per_9_innings: number | undefined;
                walks_hits_per_inning_pitched: number | undefined;
                earned_run_average: number | undefined;
                total_chances: number | undefined;
                put_outs: number | undefined;
                assists: number | undefined;
                fielding_percentage: string | undefined;
                fielding_errors: number | undefined;
                double_plays: number | undefined;
                pickoffs: number | undefined;
                runners_caught_stealing: number | undefined;
                caught_stealing_percentage: number | undefined;
                points: number | undefined;
              }
            | { points: number | undefined }
            | {
                sets_played: number | undefined;
                service_attempts: number | undefined;
                service_points_won: number | undefined;
                service_aces: number | undefined;
                service_faults: number | undefined;
                double_faults: number | undefined;
                points: number | undefined;
                returns_attempted: number | undefined;
                return_points_won: number | undefined;
                break_point_attempts: number | undefined;
                break_points_won: number | undefined;
                ejections: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
              }
            | { points: number | undefined }
            | { points: number | undefined }
            | {
                sets_played: number | undefined;
                kills: number | undefined;
                attack_errors: number | undefined;
                total_attack_attempts: number | undefined;
                attacking_percentage: number | undefined;
                assists: number | undefined;
                service_aces: number | undefined;
                service_errors: number | undefined;
                receiving_errors: number | undefined;
                digs: number | undefined;
                solo_blocks: number | undefined;
                block_assists: number | undefined;
                total_blocks: number | undefined;
                blocking_errors: number | undefined;
                ball_handling_errors: number | undefined;
                points: number | undefined;
              }
            | {
                shots: number | undefined;
                goals: number | undefined;
                assists: number | undefined;
                total_points: number | undefined;
                shooting_percentage: number | undefined;
                steals: number | undefined;
                ejects_drawn: number | undefined;
                ejects_given: number | undefined;
                fast_break: number | undefined;
                sprints_won: number | undefined;
                sprints_attempted: number | undefined;
                minutes_in_goal: number | undefined;
                shots_against: number | undefined;
                saves: number | undefined;
                goals_against: number | undefined;
                save_percentage: number | undefined;
                wins: number | undefined;
                losses: number | undefined;
                points: number | undefined;
              };
          gamePlayers: Array<{
            id: string;
            jersey_number: string | undefined;
            first_name: string | undefined;
            last_name: string | undefined;
            web_path: string | undefined;
            image: string | undefined;
            stat:
              | {
                  at_bats: number | undefined;
                  runs: number | undefined;
                  hits: number | undefined;
                  singles: number | undefined;
                  doubles: number | undefined;
                  triples: number | undefined;
                  home_runs: number | undefined;
                  runs_batted_in: number | undefined;
                  total_bases: number | undefined;
                  total_walks: number | undefined;
                  strikeouts_off: number | undefined;
                  stolen_bases: number | undefined;
                  batting_average: string | undefined;
                  on_base_percentage: string | undefined;
                  slugging_percentage: string | undefined;
                  on_base_plus_slugging: string | undefined;
                  innings_pitched: string | undefined;
                  hits_allowed: number | undefined;
                  earned_runs_allowed: number | undefined;
                  walks_allowed: number | undefined;
                  strikeouts_def: number | undefined;
                  home_runs_allowed: number | undefined;
                  pitch_count: number | undefined;
                  earned_run_average: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  holds: number | undefined;
                  saves: number | undefined;
                  put_outs: number | undefined;
                  assists: number | undefined;
                  fielding_errors: number | undefined;
                  double_plays: number | undefined;
                  pickoffs: number | undefined;
                  stolen_base_attempts: number | undefined;
                  runners_caught_stealing: number | undefined;
                  points: number | undefined;
                }
              | {
                  field_goals_made: number | undefined;
                  field_goals_attempted: number | undefined;
                  three_points_made: number | undefined;
                  three_points_attempted: number | undefined;
                  free_throws_made: number | undefined;
                  free_throws_attempted: number | undefined;
                  offensive_rebounds: number | undefined;
                  defensive_rebounds: number | undefined;
                  rebounds: number | undefined;
                  assists: number | undefined;
                  steals: number | undefined;
                  blocks: number | undefined;
                  turnovers: number | undefined;
                  personal_fouls: number | undefined;
                  points: number | undefined;
                }
              | {
                  sets_played: number | undefined;
                  kills: number | undefined;
                  attack_errors: number | undefined;
                  total_attack_attempts: number | undefined;
                  attacking_percentage: number | undefined;
                  assists: number | undefined;
                  service_aces: number | undefined;
                  service_errors: number | undefined;
                  receiving_errors: number | undefined;
                  digs: number | undefined;
                  solo_blocks: number | undefined;
                  block_assists: number | undefined;
                  total_blocks: number | undefined;
                  blocking_errors: number | undefined;
                  ball_handling_errors: number | undefined;
                  points: number | undefined;
                }
              | {
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shots: number | undefined;
                  shots_on_goal: number | undefined;
                  defensive_save: number | undefined;
                  penalty_corners: number | undefined;
                  fouls: number | undefined;
                  minutes_played: number | undefined;
                  goals_against: number | undefined;
                  goalie_saves: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  ties: number | undefined;
                  points: number | undefined;
                }
              | {
                  passing_completions: number | undefined;
                  passing_attempts: number | undefined;
                  passing_yards: number | undefined;
                  yards_per_attempt: number | undefined;
                  passing_sacks: number | undefined;
                  passing_touchdowns: number | undefined;
                  passing_interceptions: number | undefined;
                  rushing_attempts: number | undefined;
                  rushing_yards: number | undefined;
                  rushing_yards_per_attempt: number | undefined;
                  rushing_longest: number | undefined;
                  rushing_touchdowns: number | undefined;
                  rushing_fumbles: number | undefined;
                  receiving_receptions: number | undefined;
                  targets: number | undefined;
                  receiving_yards: number | undefined;
                  yards_per_reception: string | undefined;
                  receiving_longest: number | undefined;
                  receiving_touchdowns: number | undefined;
                  receiving_fumbles: number | undefined;
                  solo_tackles: number | undefined;
                  assisted_tackles: number | undefined;
                  total_tackles: number | undefined;
                  tackles_for_loss: number | undefined;
                  defensive_sacks: number | undefined;
                  yards_lost: number | undefined;
                  hurries: number | undefined;
                  qb_hits: number | undefined;
                  defensive_passes_defended: number | undefined;
                  defensive_interceptions: number | undefined;
                  defensive_interception_yards: number | undefined;
                  defensive_fumbles_forced: number | undefined;
                  defensive_fumbles_recovered: number | undefined;
                  defensive_blocked_punts: number | undefined;
                  defensive_blocked_field_goals: number | undefined;
                  defensive_touchdown: number | undefined;
                  field_goals_made: number | undefined;
                  field_goals_attempts: number | undefined;
                  field_goal_percentage: number | undefined;
                  field_goals_longest: number | undefined;
                  pat_made: number | undefined;
                  pat_attempts: number | undefined;
                  points: number | undefined;
                  punts: number | undefined;
                  punt_yards: number | undefined;
                  average_punt_yards: number | undefined;
                  punt_long: number | undefined;
                  punts_inside_20_yard_line: number | undefined;
                  total_kick_returns: number | undefined;
                  kickoff_return_yards: number | undefined;
                  yards_per_kick_return: number | undefined;
                  kick_return_long: number | undefined;
                  kick_return_touchdowns: number | undefined;
                  total_punt_returns: number | undefined;
                  punt_return_yards: number | undefined;
                  yards_per_punt_return: number | undefined;
                  punt_return_long: number | undefined;
                  punt_return_touchdowns: number | undefined;
                }
              | {
                  passing_completions: number | undefined;
                  passing_attempts: number | undefined;
                  passing_yards: number | undefined;
                  yards_per_attempt: number | undefined;
                  passing_sacks: number | undefined;
                  passing_touchdowns: number | undefined;
                  passing_interceptions: number | undefined;
                  rushing_attempts: number | undefined;
                  rushing_yards: number | undefined;
                  rushing_yards_per_attempt: number | undefined;
                  rushing_longest: number | undefined;
                  rushing_touchdowns: number | undefined;
                  rushing_fumbles: number | undefined;
                  receiving_receptions: number | undefined;
                  targets: number | undefined;
                  receiving_yards: number | undefined;
                  yards_per_reception: string | undefined;
                  receiving_longest: number | undefined;
                  receiving_touchdowns: number | undefined;
                  receiving_fumbles: number | undefined;
                  solo_tackles: number | undefined;
                  assisted_tackles: number | undefined;
                  total_tackles: number | undefined;
                  tackles_for_loss: number | undefined;
                  defensive_sacks: number | undefined;
                  yards_lost: number | undefined;
                  hurries: number | undefined;
                  qb_hits: number | undefined;
                  defensive_passes_defended: number | undefined;
                  defensive_interceptions: number | undefined;
                  defensive_interception_yards: number | undefined;
                  defensive_fumbles_forced: number | undefined;
                  defensive_fumbles_recovered: number | undefined;
                  defensive_blocked_punts: number | undefined;
                  defensive_blocked_field_goals: number | undefined;
                  defensive_touchdown: number | undefined;
                  field_goals_made: number | undefined;
                  field_goals_attempts: number | undefined;
                  field_goal_percentage: number | undefined;
                  field_goals_longest: number | undefined;
                  pat_made: number | undefined;
                  pat_attempts: number | undefined;
                  points: number | undefined;
                  punts: number | undefined;
                  punt_yards: number | undefined;
                  average_punt_yards: number | undefined;
                  punt_long: number | undefined;
                  punts_inside_20_yard_line: number | undefined;
                  total_kick_returns: number | undefined;
                  kickoff_return_yards: number | undefined;
                  yards_per_kick_return: number | undefined;
                  kick_return_long: number | undefined;
                  kick_return_touchdowns: number | undefined;
                  total_punt_returns: number | undefined;
                  punt_return_yards: number | undefined;
                  yards_per_punt_return: number | undefined;
                  punt_return_long: number | undefined;
                  punt_return_touchdowns: number | undefined;
                }
              | {
                  rounds_played: number | undefined;
                  total_strokes: number | undefined;
                  average_score: number | undefined;
                  vs_par: number | undefined;
                  vs_par_average: number | undefined;
                  points: number | undefined;
                }
              | {
                  time_on_ice: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  penalties_in_minutes: number | undefined;
                  power_play_goals: number | undefined;
                  power_play_assists: number | undefined;
                  short_hand_goals: number | undefined;
                  short_hand_assists: number | undefined;
                  shots: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  goals_against: number | undefined;
                  shots_against: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  overtime_losses: number | undefined;
                  shootout_shorts_against: number | undefined;
                  shootout_saves: number | undefined;
                  shootout_save_percentage: number | undefined;
                  points: number | undefined;
                }
              | {
                  minutes_played: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shots: number | undefined;
                  shots_on_goal: number | undefined;
                  ground_balls: number | undefined;
                  faceoffs_won: number | undefined;
                  total_faceoffs: number | undefined;
                  turnovers: number | undefined;
                  clears: number | undefined;
                  penalties: number | undefined;
                  minutes_in_goal: number | undefined;
                  goals_against: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  ties: number | undefined;
                  points: number | undefined;
                }
              | {
                  minutes_played: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  shots: number | undefined;
                  shot_percentage: number | undefined;
                  shots_on_goal: number | undefined;
                  shots_on_goal_percentage: number | undefined;
                  penalty_kick_attempts: number | undefined;
                  penalty_kick_goals: number | undefined;
                  penalty_kick_percentage: number | undefined;
                  goals_against: number | undefined;
                  saves: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  draws: number | undefined;
                  points: number | undefined;
                }
              | {
                  games_played: number | undefined;
                  at_bats: number | undefined;
                  runs: number | undefined;
                  hits: number | undefined;
                  singles: number | undefined;
                  doubles: number | undefined;
                  triples: number | undefined;
                  home_runs: number | undefined;
                  runs_batted_in: number | undefined;
                  total_bases: number | undefined;
                  total_walks: number | undefined;
                  strikeouts_off: number | undefined;
                  stolen_bases: number | undefined;
                  batting_average: string | undefined;
                  on_base_percentage: string | undefined;
                  slugging_percentage: string | undefined;
                  on_base_plus_slugging: string | undefined;
                  games_started: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  saves: number | undefined;
                  holds: number | undefined;
                  innings_pitched: string | undefined;
                  hits_allowed: number | undefined;
                  earned_runs_allowed: number | undefined;
                  home_runs_allowed: number | undefined;
                  walks_allowed: number | undefined;
                  strikeouts_def: number | undefined;
                  strikeouts_per_9_innings: number | undefined;
                  walks_hits_per_inning_pitched: number | undefined;
                  earned_run_average: number | undefined;
                  total_chances: number | undefined;
                  put_outs: number | undefined;
                  assists: number | undefined;
                  fielding_percentage: string | undefined;
                  fielding_errors: number | undefined;
                  double_plays: number | undefined;
                  pickoffs: number | undefined;
                  runners_caught_stealing: number | undefined;
                  caught_stealing_percentage: number | undefined;
                  points: number | undefined;
                }
              | { points: number | undefined }
              | {
                  sets_played: number | undefined;
                  service_attempts: number | undefined;
                  service_points_won: number | undefined;
                  service_aces: number | undefined;
                  service_faults: number | undefined;
                  double_faults: number | undefined;
                  points: number | undefined;
                  returns_attempted: number | undefined;
                  return_points_won: number | undefined;
                  break_point_attempts: number | undefined;
                  break_points_won: number | undefined;
                  ejections: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                }
              | { points: number | undefined }
              | { points: number | undefined }
              | {
                  sets_played: number | undefined;
                  kills: number | undefined;
                  attack_errors: number | undefined;
                  total_attack_attempts: number | undefined;
                  attacking_percentage: number | undefined;
                  assists: number | undefined;
                  service_aces: number | undefined;
                  service_errors: number | undefined;
                  receiving_errors: number | undefined;
                  digs: number | undefined;
                  solo_blocks: number | undefined;
                  block_assists: number | undefined;
                  total_blocks: number | undefined;
                  blocking_errors: number | undefined;
                  ball_handling_errors: number | undefined;
                  points: number | undefined;
                }
              | {
                  shots: number | undefined;
                  goals: number | undefined;
                  assists: number | undefined;
                  total_points: number | undefined;
                  shooting_percentage: number | undefined;
                  steals: number | undefined;
                  ejects_drawn: number | undefined;
                  ejects_given: number | undefined;
                  fast_break: number | undefined;
                  sprints_won: number | undefined;
                  sprints_attempted: number | undefined;
                  minutes_in_goal: number | undefined;
                  shots_against: number | undefined;
                  saves: number | undefined;
                  goals_against: number | undefined;
                  save_percentage: number | undefined;
                  wins: number | undefined;
                  losses: number | undefined;
                  points: number | undefined;
                };
          }>;
        }>;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        level: { abbrev: string | undefined } | undefined;
      };
};

export type GamesBoxscoreQueryGameTeamFragment = {
  linescore: string | undefined;
  stat:
    | {
        at_bats: number | undefined;
        runs: number | undefined;
        hits: number | undefined;
        singles: number | undefined;
        doubles: number | undefined;
        triples: number | undefined;
        home_runs: number | undefined;
        runs_batted_in: number | undefined;
        total_bases: number | undefined;
        total_walks: number | undefined;
        strikeouts_off: number | undefined;
        stolen_bases: number | undefined;
        batting_average: string | undefined;
        on_base_percentage: string | undefined;
        slugging_percentage: string | undefined;
        on_base_plus_slugging: string | undefined;
        innings_pitched: string | undefined;
        hits_allowed: number | undefined;
        earned_runs_allowed: number | undefined;
        walks_allowed: number | undefined;
        strikeouts_def: number | undefined;
        home_runs_allowed: number | undefined;
        pitch_count: number | undefined;
        earned_run_average: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
        holds: number | undefined;
        saves: number | undefined;
        put_outs: number | undefined;
        assists: number | undefined;
        fielding_errors: number | undefined;
        double_plays: number | undefined;
        pickoffs: number | undefined;
        stolen_base_attempts: number | undefined;
        runners_caught_stealing: number | undefined;
        points: number | undefined;
      }
    | {
        field_goals_made: number | undefined;
        field_goals_attempted: number | undefined;
        three_points_made: number | undefined;
        three_points_attempted: number | undefined;
        free_throws_made: number | undefined;
        free_throws_attempted: number | undefined;
        offensive_rebounds: number | undefined;
        defensive_rebounds: number | undefined;
        rebounds: number | undefined;
        assists: number | undefined;
        steals: number | undefined;
        blocks: number | undefined;
        turnovers: number | undefined;
        personal_fouls: number | undefined;
        points: number | undefined;
      }
    | {
        sets_played: number | undefined;
        kills: number | undefined;
        attack_errors: number | undefined;
        total_attack_attempts: number | undefined;
        attacking_percentage: number | undefined;
        assists: number | undefined;
        service_aces: number | undefined;
        service_errors: number | undefined;
        receiving_errors: number | undefined;
        digs: number | undefined;
        solo_blocks: number | undefined;
        block_assists: number | undefined;
        total_blocks: number | undefined;
        blocking_errors: number | undefined;
        ball_handling_errors: number | undefined;
        points: number | undefined;
      }
    | {
        goals: number | undefined;
        assists: number | undefined;
        total_points: number | undefined;
        shots: number | undefined;
        shots_on_goal: number | undefined;
        defensive_save: number | undefined;
        penalty_corners: number | undefined;
        fouls: number | undefined;
        minutes_played: number | undefined;
        goals_against: number | undefined;
        goalie_saves: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
        ties: number | undefined;
        points: number | undefined;
      }
    | {
        passing_completions: number | undefined;
        passing_attempts: number | undefined;
        passing_yards: number | undefined;
        yards_per_attempt: number | undefined;
        passing_sacks: number | undefined;
        passing_touchdowns: number | undefined;
        passing_interceptions: number | undefined;
        rushing_attempts: number | undefined;
        rushing_yards: number | undefined;
        rushing_yards_per_attempt: number | undefined;
        rushing_longest: number | undefined;
        rushing_touchdowns: number | undefined;
        rushing_fumbles: number | undefined;
        receiving_receptions: number | undefined;
        targets: number | undefined;
        receiving_yards: number | undefined;
        yards_per_reception: string | undefined;
        receiving_longest: number | undefined;
        receiving_touchdowns: number | undefined;
        receiving_fumbles: number | undefined;
        solo_tackles: number | undefined;
        assisted_tackles: number | undefined;
        total_tackles: number | undefined;
        tackles_for_loss: number | undefined;
        defensive_sacks: number | undefined;
        yards_lost: number | undefined;
        hurries: number | undefined;
        qb_hits: number | undefined;
        defensive_passes_defended: number | undefined;
        defensive_interceptions: number | undefined;
        defensive_interception_yards: number | undefined;
        defensive_fumbles_forced: number | undefined;
        defensive_fumbles_recovered: number | undefined;
        defensive_blocked_punts: number | undefined;
        defensive_blocked_field_goals: number | undefined;
        defensive_touchdown: number | undefined;
        field_goals_made: number | undefined;
        field_goals_attempts: number | undefined;
        field_goal_percentage: number | undefined;
        field_goals_longest: number | undefined;
        pat_made: number | undefined;
        pat_attempts: number | undefined;
        points: number | undefined;
        punts: number | undefined;
        punt_yards: number | undefined;
        average_punt_yards: number | undefined;
        punt_long: number | undefined;
        punts_inside_20_yard_line: number | undefined;
        total_kick_returns: number | undefined;
        kickoff_return_yards: number | undefined;
        yards_per_kick_return: number | undefined;
        kick_return_long: number | undefined;
        kick_return_touchdowns: number | undefined;
        total_punt_returns: number | undefined;
        punt_return_yards: number | undefined;
        yards_per_punt_return: number | undefined;
        punt_return_long: number | undefined;
        punt_return_touchdowns: number | undefined;
      }
    | {
        passing_completions: number | undefined;
        passing_attempts: number | undefined;
        passing_yards: number | undefined;
        yards_per_attempt: number | undefined;
        passing_sacks: number | undefined;
        passing_touchdowns: number | undefined;
        passing_interceptions: number | undefined;
        rushing_attempts: number | undefined;
        rushing_yards: number | undefined;
        rushing_yards_per_attempt: number | undefined;
        rushing_longest: number | undefined;
        rushing_touchdowns: number | undefined;
        rushing_fumbles: number | undefined;
        receiving_receptions: number | undefined;
        targets: number | undefined;
        receiving_yards: number | undefined;
        yards_per_reception: string | undefined;
        receiving_longest: number | undefined;
        receiving_touchdowns: number | undefined;
        receiving_fumbles: number | undefined;
        solo_tackles: number | undefined;
        assisted_tackles: number | undefined;
        total_tackles: number | undefined;
        tackles_for_loss: number | undefined;
        defensive_sacks: number | undefined;
        yards_lost: number | undefined;
        hurries: number | undefined;
        qb_hits: number | undefined;
        defensive_passes_defended: number | undefined;
        defensive_interceptions: number | undefined;
        defensive_interception_yards: number | undefined;
        defensive_fumbles_forced: number | undefined;
        defensive_fumbles_recovered: number | undefined;
        defensive_blocked_punts: number | undefined;
        defensive_blocked_field_goals: number | undefined;
        defensive_touchdown: number | undefined;
        field_goals_made: number | undefined;
        field_goals_attempts: number | undefined;
        field_goal_percentage: number | undefined;
        field_goals_longest: number | undefined;
        pat_made: number | undefined;
        pat_attempts: number | undefined;
        points: number | undefined;
        punts: number | undefined;
        punt_yards: number | undefined;
        average_punt_yards: number | undefined;
        punt_long: number | undefined;
        punts_inside_20_yard_line: number | undefined;
        total_kick_returns: number | undefined;
        kickoff_return_yards: number | undefined;
        yards_per_kick_return: number | undefined;
        kick_return_long: number | undefined;
        kick_return_touchdowns: number | undefined;
        total_punt_returns: number | undefined;
        punt_return_yards: number | undefined;
        yards_per_punt_return: number | undefined;
        punt_return_long: number | undefined;
        punt_return_touchdowns: number | undefined;
      }
    | {
        rounds_played: number | undefined;
        total_strokes: number | undefined;
        average_score: number | undefined;
        vs_par: number | undefined;
        vs_par_average: number | undefined;
        points: number | undefined;
      }
    | {
        time_on_ice: number | undefined;
        goals: number | undefined;
        assists: number | undefined;
        total_points: number | undefined;
        penalties_in_minutes: number | undefined;
        power_play_goals: number | undefined;
        power_play_assists: number | undefined;
        short_hand_goals: number | undefined;
        short_hand_assists: number | undefined;
        shots: number | undefined;
        saves: number | undefined;
        save_percentage: number | undefined;
        goals_against: number | undefined;
        shots_against: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
        overtime_losses: number | undefined;
        shootout_shorts_against: number | undefined;
        shootout_saves: number | undefined;
        shootout_save_percentage: number | undefined;
        points: number | undefined;
      }
    | {
        minutes_played: number | undefined;
        goals: number | undefined;
        assists: number | undefined;
        total_points: number | undefined;
        shots: number | undefined;
        shots_on_goal: number | undefined;
        ground_balls: number | undefined;
        faceoffs_won: number | undefined;
        total_faceoffs: number | undefined;
        turnovers: number | undefined;
        clears: number | undefined;
        penalties: number | undefined;
        minutes_in_goal: number | undefined;
        goals_against: number | undefined;
        saves: number | undefined;
        save_percentage: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
        ties: number | undefined;
        points: number | undefined;
      }
    | {
        minutes_played: number | undefined;
        goals: number | undefined;
        assists: number | undefined;
        shots: number | undefined;
        shot_percentage: number | undefined;
        shots_on_goal: number | undefined;
        shots_on_goal_percentage: number | undefined;
        penalty_kick_attempts: number | undefined;
        penalty_kick_goals: number | undefined;
        penalty_kick_percentage: number | undefined;
        goals_against: number | undefined;
        saves: number | undefined;
        save_percentage: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
        draws: number | undefined;
        points: number | undefined;
      }
    | {
        games_played: number | undefined;
        at_bats: number | undefined;
        runs: number | undefined;
        hits: number | undefined;
        singles: number | undefined;
        doubles: number | undefined;
        triples: number | undefined;
        home_runs: number | undefined;
        runs_batted_in: number | undefined;
        total_bases: number | undefined;
        total_walks: number | undefined;
        strikeouts_off: number | undefined;
        stolen_bases: number | undefined;
        batting_average: string | undefined;
        on_base_percentage: string | undefined;
        slugging_percentage: string | undefined;
        on_base_plus_slugging: string | undefined;
        games_started: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
        saves: number | undefined;
        holds: number | undefined;
        innings_pitched: string | undefined;
        hits_allowed: number | undefined;
        earned_runs_allowed: number | undefined;
        home_runs_allowed: number | undefined;
        walks_allowed: number | undefined;
        strikeouts_def: number | undefined;
        strikeouts_per_9_innings: number | undefined;
        walks_hits_per_inning_pitched: number | undefined;
        earned_run_average: number | undefined;
        total_chances: number | undefined;
        put_outs: number | undefined;
        assists: number | undefined;
        fielding_percentage: string | undefined;
        fielding_errors: number | undefined;
        double_plays: number | undefined;
        pickoffs: number | undefined;
        runners_caught_stealing: number | undefined;
        caught_stealing_percentage: number | undefined;
        points: number | undefined;
      }
    | { points: number | undefined }
    | {
        sets_played: number | undefined;
        service_attempts: number | undefined;
        service_points_won: number | undefined;
        service_aces: number | undefined;
        service_faults: number | undefined;
        double_faults: number | undefined;
        points: number | undefined;
        returns_attempted: number | undefined;
        return_points_won: number | undefined;
        break_point_attempts: number | undefined;
        break_points_won: number | undefined;
        ejections: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
      }
    | { points: number | undefined }
    | { points: number | undefined }
    | {
        sets_played: number | undefined;
        kills: number | undefined;
        attack_errors: number | undefined;
        total_attack_attempts: number | undefined;
        attacking_percentage: number | undefined;
        assists: number | undefined;
        service_aces: number | undefined;
        service_errors: number | undefined;
        receiving_errors: number | undefined;
        digs: number | undefined;
        solo_blocks: number | undefined;
        block_assists: number | undefined;
        total_blocks: number | undefined;
        blocking_errors: number | undefined;
        ball_handling_errors: number | undefined;
        points: number | undefined;
      }
    | {
        shots: number | undefined;
        goals: number | undefined;
        assists: number | undefined;
        total_points: number | undefined;
        shooting_percentage: number | undefined;
        steals: number | undefined;
        ejects_drawn: number | undefined;
        ejects_given: number | undefined;
        fast_break: number | undefined;
        sprints_won: number | undefined;
        sprints_attempted: number | undefined;
        minutes_in_goal: number | undefined;
        shots_against: number | undefined;
        saves: number | undefined;
        goals_against: number | undefined;
        save_percentage: number | undefined;
        wins: number | undefined;
        losses: number | undefined;
        points: number | undefined;
      };
  gamePlayers: Array<{
    id: string;
    jersey_number: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    web_path: string | undefined;
    image: string | undefined;
    stat:
      | {
          at_bats: number | undefined;
          runs: number | undefined;
          hits: number | undefined;
          singles: number | undefined;
          doubles: number | undefined;
          triples: number | undefined;
          home_runs: number | undefined;
          runs_batted_in: number | undefined;
          total_bases: number | undefined;
          total_walks: number | undefined;
          strikeouts_off: number | undefined;
          stolen_bases: number | undefined;
          batting_average: string | undefined;
          on_base_percentage: string | undefined;
          slugging_percentage: string | undefined;
          on_base_plus_slugging: string | undefined;
          innings_pitched: string | undefined;
          hits_allowed: number | undefined;
          earned_runs_allowed: number | undefined;
          walks_allowed: number | undefined;
          strikeouts_def: number | undefined;
          home_runs_allowed: number | undefined;
          pitch_count: number | undefined;
          earned_run_average: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          holds: number | undefined;
          saves: number | undefined;
          put_outs: number | undefined;
          assists: number | undefined;
          fielding_errors: number | undefined;
          double_plays: number | undefined;
          pickoffs: number | undefined;
          stolen_base_attempts: number | undefined;
          runners_caught_stealing: number | undefined;
          points: number | undefined;
        }
      | {
          field_goals_made: number | undefined;
          field_goals_attempted: number | undefined;
          three_points_made: number | undefined;
          three_points_attempted: number | undefined;
          free_throws_made: number | undefined;
          free_throws_attempted: number | undefined;
          offensive_rebounds: number | undefined;
          defensive_rebounds: number | undefined;
          rebounds: number | undefined;
          assists: number | undefined;
          steals: number | undefined;
          blocks: number | undefined;
          turnovers: number | undefined;
          personal_fouls: number | undefined;
          points: number | undefined;
        }
      | {
          sets_played: number | undefined;
          kills: number | undefined;
          attack_errors: number | undefined;
          total_attack_attempts: number | undefined;
          attacking_percentage: number | undefined;
          assists: number | undefined;
          service_aces: number | undefined;
          service_errors: number | undefined;
          receiving_errors: number | undefined;
          digs: number | undefined;
          solo_blocks: number | undefined;
          block_assists: number | undefined;
          total_blocks: number | undefined;
          blocking_errors: number | undefined;
          ball_handling_errors: number | undefined;
          points: number | undefined;
        }
      | {
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          shots: number | undefined;
          shots_on_goal: number | undefined;
          defensive_save: number | undefined;
          penalty_corners: number | undefined;
          fouls: number | undefined;
          minutes_played: number | undefined;
          goals_against: number | undefined;
          goalie_saves: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          ties: number | undefined;
          points: number | undefined;
        }
      | {
          passing_completions: number | undefined;
          passing_attempts: number | undefined;
          passing_yards: number | undefined;
          yards_per_attempt: number | undefined;
          passing_sacks: number | undefined;
          passing_touchdowns: number | undefined;
          passing_interceptions: number | undefined;
          rushing_attempts: number | undefined;
          rushing_yards: number | undefined;
          rushing_yards_per_attempt: number | undefined;
          rushing_longest: number | undefined;
          rushing_touchdowns: number | undefined;
          rushing_fumbles: number | undefined;
          receiving_receptions: number | undefined;
          targets: number | undefined;
          receiving_yards: number | undefined;
          yards_per_reception: string | undefined;
          receiving_longest: number | undefined;
          receiving_touchdowns: number | undefined;
          receiving_fumbles: number | undefined;
          solo_tackles: number | undefined;
          assisted_tackles: number | undefined;
          total_tackles: number | undefined;
          tackles_for_loss: number | undefined;
          defensive_sacks: number | undefined;
          yards_lost: number | undefined;
          hurries: number | undefined;
          qb_hits: number | undefined;
          defensive_passes_defended: number | undefined;
          defensive_interceptions: number | undefined;
          defensive_interception_yards: number | undefined;
          defensive_fumbles_forced: number | undefined;
          defensive_fumbles_recovered: number | undefined;
          defensive_blocked_punts: number | undefined;
          defensive_blocked_field_goals: number | undefined;
          defensive_touchdown: number | undefined;
          field_goals_made: number | undefined;
          field_goals_attempts: number | undefined;
          field_goal_percentage: number | undefined;
          field_goals_longest: number | undefined;
          pat_made: number | undefined;
          pat_attempts: number | undefined;
          points: number | undefined;
          punts: number | undefined;
          punt_yards: number | undefined;
          average_punt_yards: number | undefined;
          punt_long: number | undefined;
          punts_inside_20_yard_line: number | undefined;
          total_kick_returns: number | undefined;
          kickoff_return_yards: number | undefined;
          yards_per_kick_return: number | undefined;
          kick_return_long: number | undefined;
          kick_return_touchdowns: number | undefined;
          total_punt_returns: number | undefined;
          punt_return_yards: number | undefined;
          yards_per_punt_return: number | undefined;
          punt_return_long: number | undefined;
          punt_return_touchdowns: number | undefined;
        }
      | {
          passing_completions: number | undefined;
          passing_attempts: number | undefined;
          passing_yards: number | undefined;
          yards_per_attempt: number | undefined;
          passing_sacks: number | undefined;
          passing_touchdowns: number | undefined;
          passing_interceptions: number | undefined;
          rushing_attempts: number | undefined;
          rushing_yards: number | undefined;
          rushing_yards_per_attempt: number | undefined;
          rushing_longest: number | undefined;
          rushing_touchdowns: number | undefined;
          rushing_fumbles: number | undefined;
          receiving_receptions: number | undefined;
          targets: number | undefined;
          receiving_yards: number | undefined;
          yards_per_reception: string | undefined;
          receiving_longest: number | undefined;
          receiving_touchdowns: number | undefined;
          receiving_fumbles: number | undefined;
          solo_tackles: number | undefined;
          assisted_tackles: number | undefined;
          total_tackles: number | undefined;
          tackles_for_loss: number | undefined;
          defensive_sacks: number | undefined;
          yards_lost: number | undefined;
          hurries: number | undefined;
          qb_hits: number | undefined;
          defensive_passes_defended: number | undefined;
          defensive_interceptions: number | undefined;
          defensive_interception_yards: number | undefined;
          defensive_fumbles_forced: number | undefined;
          defensive_fumbles_recovered: number | undefined;
          defensive_blocked_punts: number | undefined;
          defensive_blocked_field_goals: number | undefined;
          defensive_touchdown: number | undefined;
          field_goals_made: number | undefined;
          field_goals_attempts: number | undefined;
          field_goal_percentage: number | undefined;
          field_goals_longest: number | undefined;
          pat_made: number | undefined;
          pat_attempts: number | undefined;
          points: number | undefined;
          punts: number | undefined;
          punt_yards: number | undefined;
          average_punt_yards: number | undefined;
          punt_long: number | undefined;
          punts_inside_20_yard_line: number | undefined;
          total_kick_returns: number | undefined;
          kickoff_return_yards: number | undefined;
          yards_per_kick_return: number | undefined;
          kick_return_long: number | undefined;
          kick_return_touchdowns: number | undefined;
          total_punt_returns: number | undefined;
          punt_return_yards: number | undefined;
          yards_per_punt_return: number | undefined;
          punt_return_long: number | undefined;
          punt_return_touchdowns: number | undefined;
        }
      | {
          rounds_played: number | undefined;
          total_strokes: number | undefined;
          average_score: number | undefined;
          vs_par: number | undefined;
          vs_par_average: number | undefined;
          points: number | undefined;
        }
      | {
          time_on_ice: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          penalties_in_minutes: number | undefined;
          power_play_goals: number | undefined;
          power_play_assists: number | undefined;
          short_hand_goals: number | undefined;
          short_hand_assists: number | undefined;
          shots: number | undefined;
          saves: number | undefined;
          save_percentage: number | undefined;
          goals_against: number | undefined;
          shots_against: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          overtime_losses: number | undefined;
          shootout_shorts_against: number | undefined;
          shootout_saves: number | undefined;
          shootout_save_percentage: number | undefined;
          points: number | undefined;
        }
      | {
          minutes_played: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          shots: number | undefined;
          shots_on_goal: number | undefined;
          ground_balls: number | undefined;
          faceoffs_won: number | undefined;
          total_faceoffs: number | undefined;
          turnovers: number | undefined;
          clears: number | undefined;
          penalties: number | undefined;
          minutes_in_goal: number | undefined;
          goals_against: number | undefined;
          saves: number | undefined;
          save_percentage: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          ties: number | undefined;
          points: number | undefined;
        }
      | {
          minutes_played: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          shots: number | undefined;
          shot_percentage: number | undefined;
          shots_on_goal: number | undefined;
          shots_on_goal_percentage: number | undefined;
          penalty_kick_attempts: number | undefined;
          penalty_kick_goals: number | undefined;
          penalty_kick_percentage: number | undefined;
          goals_against: number | undefined;
          saves: number | undefined;
          save_percentage: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          draws: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          at_bats: number | undefined;
          runs: number | undefined;
          hits: number | undefined;
          singles: number | undefined;
          doubles: number | undefined;
          triples: number | undefined;
          home_runs: number | undefined;
          runs_batted_in: number | undefined;
          total_bases: number | undefined;
          total_walks: number | undefined;
          strikeouts_off: number | undefined;
          stolen_bases: number | undefined;
          batting_average: string | undefined;
          on_base_percentage: string | undefined;
          slugging_percentage: string | undefined;
          on_base_plus_slugging: string | undefined;
          games_started: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          saves: number | undefined;
          holds: number | undefined;
          innings_pitched: string | undefined;
          hits_allowed: number | undefined;
          earned_runs_allowed: number | undefined;
          home_runs_allowed: number | undefined;
          walks_allowed: number | undefined;
          strikeouts_def: number | undefined;
          strikeouts_per_9_innings: number | undefined;
          walks_hits_per_inning_pitched: number | undefined;
          earned_run_average: number | undefined;
          total_chances: number | undefined;
          put_outs: number | undefined;
          assists: number | undefined;
          fielding_percentage: string | undefined;
          fielding_errors: number | undefined;
          double_plays: number | undefined;
          pickoffs: number | undefined;
          runners_caught_stealing: number | undefined;
          caught_stealing_percentage: number | undefined;
          points: number | undefined;
        }
      | { points: number | undefined }
      | {
          sets_played: number | undefined;
          service_attempts: number | undefined;
          service_points_won: number | undefined;
          service_aces: number | undefined;
          service_faults: number | undefined;
          double_faults: number | undefined;
          points: number | undefined;
          returns_attempted: number | undefined;
          return_points_won: number | undefined;
          break_point_attempts: number | undefined;
          break_points_won: number | undefined;
          ejections: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
        }
      | { points: number | undefined }
      | { points: number | undefined }
      | {
          sets_played: number | undefined;
          kills: number | undefined;
          attack_errors: number | undefined;
          total_attack_attempts: number | undefined;
          attacking_percentage: number | undefined;
          assists: number | undefined;
          service_aces: number | undefined;
          service_errors: number | undefined;
          receiving_errors: number | undefined;
          digs: number | undefined;
          solo_blocks: number | undefined;
          block_assists: number | undefined;
          total_blocks: number | undefined;
          blocking_errors: number | undefined;
          ball_handling_errors: number | undefined;
          points: number | undefined;
        }
      | {
          shots: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          shooting_percentage: number | undefined;
          steals: number | undefined;
          ejects_drawn: number | undefined;
          ejects_given: number | undefined;
          fast_break: number | undefined;
          sprints_won: number | undefined;
          sprints_attempted: number | undefined;
          minutes_in_goal: number | undefined;
          shots_against: number | undefined;
          saves: number | undefined;
          goals_against: number | undefined;
          save_percentage: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          points: number | undefined;
        };
  }>;
};

type GamesBoxscoreQueryStatBaseballStatFragment = {
  at_bats: number | undefined;
  runs: number | undefined;
  hits: number | undefined;
  singles: number | undefined;
  doubles: number | undefined;
  triples: number | undefined;
  home_runs: number | undefined;
  runs_batted_in: number | undefined;
  total_bases: number | undefined;
  total_walks: number | undefined;
  strikeouts_off: number | undefined;
  stolen_bases: number | undefined;
  batting_average: string | undefined;
  on_base_percentage: string | undefined;
  slugging_percentage: string | undefined;
  on_base_plus_slugging: string | undefined;
  innings_pitched: string | undefined;
  hits_allowed: number | undefined;
  earned_runs_allowed: number | undefined;
  walks_allowed: number | undefined;
  strikeouts_def: number | undefined;
  home_runs_allowed: number | undefined;
  pitch_count: number | undefined;
  earned_run_average: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  holds: number | undefined;
  saves: number | undefined;
  put_outs: number | undefined;
  assists: number | undefined;
  fielding_errors: number | undefined;
  double_plays: number | undefined;
  pickoffs: number | undefined;
  stolen_base_attempts: number | undefined;
  runners_caught_stealing: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatBasketballStatFragment = {
  field_goals_made: number | undefined;
  field_goals_attempted: number | undefined;
  three_points_made: number | undefined;
  three_points_attempted: number | undefined;
  free_throws_made: number | undefined;
  free_throws_attempted: number | undefined;
  offensive_rebounds: number | undefined;
  defensive_rebounds: number | undefined;
  rebounds: number | undefined;
  assists: number | undefined;
  steals: number | undefined;
  blocks: number | undefined;
  turnovers: number | undefined;
  personal_fouls: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatBeachVolleyballStatFragment = {
  sets_played: number | undefined;
  kills: number | undefined;
  attack_errors: number | undefined;
  total_attack_attempts: number | undefined;
  attacking_percentage: number | undefined;
  assists: number | undefined;
  service_aces: number | undefined;
  service_errors: number | undefined;
  receiving_errors: number | undefined;
  digs: number | undefined;
  solo_blocks: number | undefined;
  block_assists: number | undefined;
  total_blocks: number | undefined;
  blocking_errors: number | undefined;
  ball_handling_errors: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatFieldHockeyStatFragment = {
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  shots: number | undefined;
  shots_on_goal: number | undefined;
  defensive_save: number | undefined;
  penalty_corners: number | undefined;
  fouls: number | undefined;
  minutes_played: number | undefined;
  goals_against: number | undefined;
  goalie_saves: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  ties: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatFlagFootballStatFragment = {
  passing_completions: number | undefined;
  passing_attempts: number | undefined;
  passing_yards: number | undefined;
  yards_per_attempt: number | undefined;
  passing_sacks: number | undefined;
  passing_touchdowns: number | undefined;
  passing_interceptions: number | undefined;
  rushing_attempts: number | undefined;
  rushing_yards: number | undefined;
  rushing_yards_per_attempt: number | undefined;
  rushing_longest: number | undefined;
  rushing_touchdowns: number | undefined;
  rushing_fumbles: number | undefined;
  receiving_receptions: number | undefined;
  targets: number | undefined;
  receiving_yards: number | undefined;
  yards_per_reception: string | undefined;
  receiving_longest: number | undefined;
  receiving_touchdowns: number | undefined;
  receiving_fumbles: number | undefined;
  solo_tackles: number | undefined;
  assisted_tackles: number | undefined;
  total_tackles: number | undefined;
  tackles_for_loss: number | undefined;
  defensive_sacks: number | undefined;
  yards_lost: number | undefined;
  hurries: number | undefined;
  qb_hits: number | undefined;
  defensive_passes_defended: number | undefined;
  defensive_interceptions: number | undefined;
  defensive_interception_yards: number | undefined;
  defensive_fumbles_forced: number | undefined;
  defensive_fumbles_recovered: number | undefined;
  defensive_blocked_punts: number | undefined;
  defensive_blocked_field_goals: number | undefined;
  defensive_touchdown: number | undefined;
  field_goals_made: number | undefined;
  field_goals_attempts: number | undefined;
  field_goal_percentage: number | undefined;
  field_goals_longest: number | undefined;
  pat_made: number | undefined;
  pat_attempts: number | undefined;
  points: number | undefined;
  punts: number | undefined;
  punt_yards: number | undefined;
  average_punt_yards: number | undefined;
  punt_long: number | undefined;
  punts_inside_20_yard_line: number | undefined;
  total_kick_returns: number | undefined;
  kickoff_return_yards: number | undefined;
  yards_per_kick_return: number | undefined;
  kick_return_long: number | undefined;
  kick_return_touchdowns: number | undefined;
  total_punt_returns: number | undefined;
  punt_return_yards: number | undefined;
  yards_per_punt_return: number | undefined;
  punt_return_long: number | undefined;
  punt_return_touchdowns: number | undefined;
};

type GamesBoxscoreQueryStatFootballStatFragment = {
  passing_completions: number | undefined;
  passing_attempts: number | undefined;
  passing_yards: number | undefined;
  yards_per_attempt: number | undefined;
  passing_sacks: number | undefined;
  passing_touchdowns: number | undefined;
  passing_interceptions: number | undefined;
  rushing_attempts: number | undefined;
  rushing_yards: number | undefined;
  rushing_yards_per_attempt: number | undefined;
  rushing_longest: number | undefined;
  rushing_touchdowns: number | undefined;
  rushing_fumbles: number | undefined;
  receiving_receptions: number | undefined;
  targets: number | undefined;
  receiving_yards: number | undefined;
  yards_per_reception: string | undefined;
  receiving_longest: number | undefined;
  receiving_touchdowns: number | undefined;
  receiving_fumbles: number | undefined;
  solo_tackles: number | undefined;
  assisted_tackles: number | undefined;
  total_tackles: number | undefined;
  tackles_for_loss: number | undefined;
  defensive_sacks: number | undefined;
  yards_lost: number | undefined;
  hurries: number | undefined;
  qb_hits: number | undefined;
  defensive_passes_defended: number | undefined;
  defensive_interceptions: number | undefined;
  defensive_interception_yards: number | undefined;
  defensive_fumbles_forced: number | undefined;
  defensive_fumbles_recovered: number | undefined;
  defensive_blocked_punts: number | undefined;
  defensive_blocked_field_goals: number | undefined;
  defensive_touchdown: number | undefined;
  field_goals_made: number | undefined;
  field_goals_attempts: number | undefined;
  field_goal_percentage: number | undefined;
  field_goals_longest: number | undefined;
  pat_made: number | undefined;
  pat_attempts: number | undefined;
  points: number | undefined;
  punts: number | undefined;
  punt_yards: number | undefined;
  average_punt_yards: number | undefined;
  punt_long: number | undefined;
  punts_inside_20_yard_line: number | undefined;
  total_kick_returns: number | undefined;
  kickoff_return_yards: number | undefined;
  yards_per_kick_return: number | undefined;
  kick_return_long: number | undefined;
  kick_return_touchdowns: number | undefined;
  total_punt_returns: number | undefined;
  punt_return_yards: number | undefined;
  yards_per_punt_return: number | undefined;
  punt_return_long: number | undefined;
  punt_return_touchdowns: number | undefined;
};

type GamesBoxscoreQueryStatGolfStatFragment = {
  rounds_played: number | undefined;
  total_strokes: number | undefined;
  average_score: number | undefined;
  vs_par: number | undefined;
  vs_par_average: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatIceHockeyStatFragment = {
  time_on_ice: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  penalties_in_minutes: number | undefined;
  power_play_goals: number | undefined;
  power_play_assists: number | undefined;
  short_hand_goals: number | undefined;
  short_hand_assists: number | undefined;
  shots: number | undefined;
  saves: number | undefined;
  save_percentage: number | undefined;
  goals_against: number | undefined;
  shots_against: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  overtime_losses: number | undefined;
  shootout_shorts_against: number | undefined;
  shootout_saves: number | undefined;
  shootout_save_percentage: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatLacrosseStatFragment = {
  minutes_played: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  shots: number | undefined;
  shots_on_goal: number | undefined;
  ground_balls: number | undefined;
  faceoffs_won: number | undefined;
  total_faceoffs: number | undefined;
  turnovers: number | undefined;
  clears: number | undefined;
  penalties: number | undefined;
  minutes_in_goal: number | undefined;
  goals_against: number | undefined;
  saves: number | undefined;
  save_percentage: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  ties: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatSoccerStatFragment = {
  minutes_played: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  shots: number | undefined;
  shot_percentage: number | undefined;
  shots_on_goal: number | undefined;
  shots_on_goal_percentage: number | undefined;
  penalty_kick_attempts: number | undefined;
  penalty_kick_goals: number | undefined;
  penalty_kick_percentage: number | undefined;
  goals_against: number | undefined;
  saves: number | undefined;
  save_percentage: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  draws: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatSoftballStatFragment = {
  games_played: number | undefined;
  at_bats: number | undefined;
  runs: number | undefined;
  hits: number | undefined;
  singles: number | undefined;
  doubles: number | undefined;
  triples: number | undefined;
  home_runs: number | undefined;
  runs_batted_in: number | undefined;
  total_bases: number | undefined;
  total_walks: number | undefined;
  strikeouts_off: number | undefined;
  stolen_bases: number | undefined;
  batting_average: string | undefined;
  on_base_percentage: string | undefined;
  slugging_percentage: string | undefined;
  on_base_plus_slugging: string | undefined;
  games_started: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  saves: number | undefined;
  holds: number | undefined;
  innings_pitched: string | undefined;
  hits_allowed: number | undefined;
  earned_runs_allowed: number | undefined;
  home_runs_allowed: number | undefined;
  walks_allowed: number | undefined;
  strikeouts_def: number | undefined;
  strikeouts_per_9_innings: number | undefined;
  walks_hits_per_inning_pitched: number | undefined;
  earned_run_average: number | undefined;
  total_chances: number | undefined;
  put_outs: number | undefined;
  assists: number | undefined;
  fielding_percentage: string | undefined;
  fielding_errors: number | undefined;
  double_plays: number | undefined;
  pickoffs: number | undefined;
  runners_caught_stealing: number | undefined;
  caught_stealing_percentage: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatStatFragment = { points: number | undefined };

type GamesBoxscoreQueryStatTennisStatFragment = {
  sets_played: number | undefined;
  service_attempts: number | undefined;
  service_points_won: number | undefined;
  service_aces: number | undefined;
  service_faults: number | undefined;
  double_faults: number | undefined;
  points: number | undefined;
  returns_attempted: number | undefined;
  return_points_won: number | undefined;
  break_point_attempts: number | undefined;
  break_points_won: number | undefined;
  ejections: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
};

type GamesBoxscoreQueryStatUltimateFrisbeeStatFragment = { points: number | undefined };

type GamesBoxscoreQueryStatUnifiedBasketballStatFragment = { points: number | undefined };

type GamesBoxscoreQueryStatVolleyballStatFragment = {
  sets_played: number | undefined;
  kills: number | undefined;
  attack_errors: number | undefined;
  total_attack_attempts: number | undefined;
  attacking_percentage: number | undefined;
  assists: number | undefined;
  service_aces: number | undefined;
  service_errors: number | undefined;
  receiving_errors: number | undefined;
  digs: number | undefined;
  solo_blocks: number | undefined;
  block_assists: number | undefined;
  total_blocks: number | undefined;
  blocking_errors: number | undefined;
  ball_handling_errors: number | undefined;
  points: number | undefined;
};

type GamesBoxscoreQueryStatWaterPoloStatFragment = {
  shots: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  shooting_percentage: number | undefined;
  steals: number | undefined;
  ejects_drawn: number | undefined;
  ejects_given: number | undefined;
  fast_break: number | undefined;
  sprints_won: number | undefined;
  sprints_attempted: number | undefined;
  minutes_in_goal: number | undefined;
  shots_against: number | undefined;
  saves: number | undefined;
  goals_against: number | undefined;
  save_percentage: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  points: number | undefined;
};

export type GamesBoxscoreQueryStatFragment =
  | GamesBoxscoreQueryStatBaseballStatFragment
  | GamesBoxscoreQueryStatBasketballStatFragment
  | GamesBoxscoreQueryStatBeachVolleyballStatFragment
  | GamesBoxscoreQueryStatFieldHockeyStatFragment
  | GamesBoxscoreQueryStatFlagFootballStatFragment
  | GamesBoxscoreQueryStatFootballStatFragment
  | GamesBoxscoreQueryStatGolfStatFragment
  | GamesBoxscoreQueryStatIceHockeyStatFragment
  | GamesBoxscoreQueryStatLacrosseStatFragment
  | GamesBoxscoreQueryStatSoccerStatFragment
  | GamesBoxscoreQueryStatSoftballStatFragment
  | GamesBoxscoreQueryStatStatFragment
  | GamesBoxscoreQueryStatTennisStatFragment
  | GamesBoxscoreQueryStatUltimateFrisbeeStatFragment
  | GamesBoxscoreQueryStatUnifiedBasketballStatFragment
  | GamesBoxscoreQueryStatVolleyballStatFragment
  | GamesBoxscoreQueryStatWaterPoloStatFragment;

export type GamesGenderSportIndexCalendarQueryVariables = Exact<{
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  genderSport: GenderSportEnum;
  level: LevelEnum;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  power25?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<StateEnum>;
}>;

export type GamesGenderSportIndexCalendarQuery = { scoreboardDates: Array<{ date: string; games: { totalCount: number } }> };

export type GamesGenderSportIndexFanAppQueryVariables = Exact<{
  date: Scalars['String']['input'];
  genderId: Scalars['Int']['input'];
  organizationId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  sportId: Scalars['Int']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GamesGenderSportIndexFanAppQuery = {
  games: {
    nodes: Array<
      | {
          id: string;
          date: string;
          gameTypeLabel: string;
          longStatusText: string;
          statusId: number;
          webPath: string;
          contest:
            | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
            | undefined;
          gameTeams: Array<{
            id: string;
            isLoser: boolean;
            isTbd: boolean;
            isWinner: boolean;
            scoreText: string | undefined;
            pregameStanding: { overallRecord: string } | undefined;
            team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
          }>;
        }
      | {
          id: string;
          date: string;
          gameTypeLabel: string;
          longStatusText: string;
          statusId: number;
          webPath: string;
          contest:
            | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
            | undefined;
          gameTeams: Array<{
            id: string;
            isLoser: boolean;
            isTbd: boolean;
            isWinner: boolean;
            scoreText: string | undefined;
            pregameStanding: { overallRecord: string } | undefined;
            team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
          }>;
        }
      | {
          id: string;
          date: string;
          gameTypeLabel: string;
          longStatusText: string;
          statusId: number;
          webPath: string;
          contest:
            | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
            | undefined;
          gameTeams: Array<{
            id: string;
            isLoser: boolean;
            isTbd: boolean;
            isWinner: boolean;
            scoreText: string | undefined;
            pregameStanding: { overallRecord: string } | undefined;
            team: { image: string | undefined; mascot: string | undefined; name: string; state: { abbrev: string } | undefined };
          }>;
        }
    >;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
};

export type GamesGenderSportIndexQueryVariables = Exact<{
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  genderSport: GenderSportEnum;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  level: LevelEnum;
  power25?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<StateEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  paginating: Scalars['Boolean']['input'];
  limitDates: Scalars['Boolean']['input'];
}>;

export type GamesGenderSportIndexQuery = {
  scoreboardDate: {
    blurb?: string | undefined;
    date?: string;
    hasPower25?: boolean;
    games: {
      totalCount?: number;
      nodes: Array<
        | {
            id: string;
            date: string;
            longStatusText: string;
            statusId: number;
            titleText: string;
            webPath: string;
            webPhotosPath: string | undefined;
            gameTeams: Array<{
              id: string;
              isLoser: boolean;
              isTbd: boolean;
              isWinner: boolean;
              scoreText: string | undefined;
              power25Ranking: { place: number } | undefined;
              pregameStanding: { overallRecord: string } | undefined;
              team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
            }>;
            contest:
              | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
              | undefined;
          }
        | {
            id: string;
            date: string;
            longStatusText: string;
            statusId: number;
            titleText: string;
            webPath: string;
            webPhotosPath: string | undefined;
            gameTeams: Array<{
              id: string;
              isLoser: boolean;
              isTbd: boolean;
              isWinner: boolean;
              scoreText: string | undefined;
              power25Ranking: { place: number } | undefined;
              pregameStanding: { overallRecord: string } | undefined;
              team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
            }>;
            contest:
              | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
              | undefined;
          }
        | {
            id: string;
            date: string;
            longStatusText: string;
            statusId: number;
            titleText: string;
            webPath: string;
            webPhotosPath: string | undefined;
            gameTeams: Array<{
              id: string;
              isLoser: boolean;
              isTbd: boolean;
              isWinner: boolean;
              scoreText: string | undefined;
              power25Ranking: { place: number } | undefined;
              pregameStanding: { overallRecord: string } | undefined;
              team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
            }>;
            contest:
              | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
              | undefined;
          }
      >;
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    };
    nextScoreboardDate?: { date: string; nextScoreboardDate?: { date: string } | undefined } | undefined;
    previousScoreboardDate?: { date: string; previousScoreboardDate?: { date: string } | undefined } | undefined;
  };
  stateGenderSportOrganizations?: Array<
    | {
        id: string;
        fullName: string | undefined;
        label: string;
        name: string;
        organizationTypeKey: OrganizationTypeEnum;
        parentId: string | undefined;
        slug: string;
        state: { slug: string };
      }
    | {
        id: string;
        fullName: string | undefined;
        label: string;
        name: string;
        organizationTypeKey: OrganizationTypeEnum;
        parentId: string | undefined;
        slug: string;
        state: { slug: string };
      }
  >;
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
  ranking?:
    | {
        formula: RankingFormulaEnum;
        teamRankings: {
          nodes: Array<{
            id: string;
            filteredPlace: number | undefined;
            record: string;
            filteredChange: number | undefined;
            team: { name: string; image: string | undefined; webPath: string };
          }>;
        };
      }
    | undefined;
};

export type GamesLiveQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GamesLiveQuery = {
  game:
    | {
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webScorebookCastPath: string | undefined;
        following_count: number | undefined;
        secondaryStatusId: number | undefined;
        gameTeams: Array<{
          id: string;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          picks: number;
          leaders:
            | Array<{
                category: string;
                game_player:
                  | {
                      id: string;
                      game_team_id: number | undefined;
                      image: string | undefined;
                      jersey_number: string | undefined;
                      first_name: string | undefined;
                      last_name: string | undefined;
                      web_path: string | undefined;
                      stat:
                        | {
                            field_goals_made: number | undefined;
                            three_points_made: number | undefined;
                            free_throws_made: number | undefined;
                            points: number | undefined;
                          }
                        | {
                            passing_completions: number | undefined;
                            passing_attempts: number | undefined;
                            passing_yards: number | undefined;
                            yards_per_attempt: number | undefined;
                            passing_touchdowns: number | undefined;
                            rushing_attempts: number | undefined;
                            rushing_yards: number | undefined;
                            rushing_yards_per_attempt: number | undefined;
                            rushing_touchdowns: number | undefined;
                            receiving_receptions: number | undefined;
                            receiving_yards: number | undefined;
                            receiving_touchdowns: number | undefined;
                            yards_per_reception: string | undefined;
                          }
                        | {};
                    }
                  | undefined;
              }>
            | undefined;
        }>;
        contest: { expectedStatus: ContestExpectedStatusEnum } | undefined;
        linescore:
          | { title: string; periods: Array<{ id: string; label: string; away: number | undefined; home: number | undefined }> }
          | undefined;
      }
    | {
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webScorebookCastPath: string | undefined;
        following_count: number | undefined;
        secondaryStatusId: number | undefined;
        scoringGameEventGroupings: Array<{
          id: string;
          periodText: string | undefined;
          image: string | undefined;
          name: string;
          eventText: string | undefined;
          teamName: string | undefined;
          awayScore: number | undefined;
          homeScore: number | undefined;
        }>;
        gameTeams: Array<{
          id: string;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          picks: number;
          leaders:
            | Array<{
                category: string;
                game_player:
                  | {
                      id: string;
                      game_team_id: number | undefined;
                      image: string | undefined;
                      jersey_number: string | undefined;
                      first_name: string | undefined;
                      last_name: string | undefined;
                      web_path: string | undefined;
                      stat:
                        | {
                            field_goals_made: number | undefined;
                            three_points_made: number | undefined;
                            free_throws_made: number | undefined;
                            points: number | undefined;
                          }
                        | {
                            passing_completions: number | undefined;
                            passing_attempts: number | undefined;
                            passing_yards: number | undefined;
                            yards_per_attempt: number | undefined;
                            passing_touchdowns: number | undefined;
                            rushing_attempts: number | undefined;
                            rushing_yards: number | undefined;
                            rushing_yards_per_attempt: number | undefined;
                            rushing_touchdowns: number | undefined;
                            receiving_receptions: number | undefined;
                            receiving_yards: number | undefined;
                            receiving_touchdowns: number | undefined;
                            yards_per_reception: string | undefined;
                          }
                        | {};
                    }
                  | undefined;
              }>
            | undefined;
        }>;
        contest: { expectedStatus: ContestExpectedStatusEnum } | undefined;
        linescore:
          | { title: string; periods: Array<{ id: string; label: string; away: number | undefined; home: number | undefined }> }
          | undefined;
      }
    | {
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webScorebookCastPath: string | undefined;
        following_count: number | undefined;
        secondaryStatusId: number | undefined;
        gameTeams: Array<{
          id: string;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          picks: number;
          leaders:
            | Array<{
                category: string;
                game_player:
                  | {
                      id: string;
                      game_team_id: number | undefined;
                      image: string | undefined;
                      jersey_number: string | undefined;
                      first_name: string | undefined;
                      last_name: string | undefined;
                      web_path: string | undefined;
                      stat:
                        | {
                            field_goals_made: number | undefined;
                            three_points_made: number | undefined;
                            free_throws_made: number | undefined;
                            points: number | undefined;
                          }
                        | {
                            passing_completions: number | undefined;
                            passing_attempts: number | undefined;
                            passing_yards: number | undefined;
                            yards_per_attempt: number | undefined;
                            passing_touchdowns: number | undefined;
                            rushing_attempts: number | undefined;
                            rushing_yards: number | undefined;
                            rushing_yards_per_attempt: number | undefined;
                            rushing_touchdowns: number | undefined;
                            receiving_receptions: number | undefined;
                            receiving_yards: number | undefined;
                            receiving_touchdowns: number | undefined;
                            yards_per_reception: string | undefined;
                          }
                        | {};
                    }
                  | undefined;
              }>
            | undefined;
        }>;
        contest: { expectedStatus: ContestExpectedStatusEnum } | undefined;
        linescore:
          | { title: string; periods: Array<{ id: string; label: string; away: number | undefined; home: number | undefined }> }
          | undefined;
      };
};

export type GamesPhotosQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GamesPhotosQuery = {
  game:
    | {
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        photoGalleries: {
          nodes: Array<
            | {
                __typename: 'ContentItemPhotoGallery';
                name: string;
                webPath: string;
                photoCount: number;
                id: string;
                date: string;
                externalId: string;
                teams: Array<{ id: string; name: string }>;
                photos: Array<{ filename: string; watermark: string }>;
              }
            | { __typename: 'ContentItemPoll'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemTempestArticle'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemTweet'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemVoltaxArticle'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string; externalId: string }
          >;
        };
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        photoGalleries: {
          nodes: Array<
            | {
                __typename: 'ContentItemPhotoGallery';
                name: string;
                webPath: string;
                photoCount: number;
                id: string;
                date: string;
                externalId: string;
                teams: Array<{ id: string; name: string }>;
                photos: Array<{ filename: string; watermark: string }>;
              }
            | { __typename: 'ContentItemPoll'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemTempestArticle'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemTweet'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemVoltaxArticle'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string; externalId: string }
          >;
        };
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        photoGalleries: {
          nodes: Array<
            | {
                __typename: 'ContentItemPhotoGallery';
                name: string;
                webPath: string;
                photoCount: number;
                id: string;
                date: string;
                externalId: string;
                teams: Array<{ id: string; name: string }>;
                photos: Array<{ filename: string; watermark: string }>;
              }
            | { __typename: 'ContentItemPoll'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemTempestArticle'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemTweet'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemVoltaxArticle'; id: string; date: string; externalId: string }
            | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string; externalId: string }
          >;
        };
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      };
};

export type GamesPreviewQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GamesPreviewQuery = {
  game:
    | {
        venue: string | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gamePreview: { headline: string; paragraphs: Array<string> } | undefined;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        venue: string | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gamePreview: { headline: string; paragraphs: Array<string> } | undefined;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        venue: string | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gamePreview: { headline: string; paragraphs: Array<string> } | undefined;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      };
};

export type GamesRecapQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GamesRecapQuery = {
  game:
    | {
        venue: string | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gameRecap: { headline: string; paragraphs: Array<string> } | undefined;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        venue: string | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gameRecap: { headline: string; paragraphs: Array<string> } | undefined;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        venue: string | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gameRecap: { headline: string; paragraphs: Array<string> } | undefined;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      };
};

export type GamesScorebookcastQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GamesScorebookcastQuery = {
  game:
    | {
        __typename: 'BasketballGame';
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gameSnapshot:
          | {
              eventText: string | undefined;
              gamePlayerImage: string | undefined;
              points: number | undefined;
              possessionGameTeamId: string | undefined;
            }
          | undefined;
        gameEventGroupings: Array<
          | {
              __typename: 'FootballGameEventGrouping';
              image: string | undefined;
              metaText: string | undefined;
              periodText: string | undefined;
              teamName: string | undefined;
              id: string;
              name: string;
              awayScore: number | undefined;
              homeScore: number | undefined;
              gameEvents: Array<
                | {
                    __typename: 'BasketballGameEvent';
                    gameTeamId: string | undefined;
                    points: number | undefined;
                    awayScore: number | undefined;
                    homeScore: number | undefined;
                    id: string;
                    text: string;
                  }
                | {
                    __typename: 'FootballGameEvent';
                    statusText: string | undefined;
                    id: string;
                    text: string;
                    gamePlayer: { name: string | undefined; image: string | undefined } | undefined;
                  }
                | { __typename: 'GameEvent'; id: string; text: string }
              >;
            }
          | {
              __typename: 'GameEventGrouping';
              id: string;
              name: string;
              awayScore: number | undefined;
              homeScore: number | undefined;
              gameEvents: Array<
                | {
                    __typename: 'BasketballGameEvent';
                    gameTeamId: string | undefined;
                    points: number | undefined;
                    awayScore: number | undefined;
                    homeScore: number | undefined;
                    id: string;
                    text: string;
                  }
                | {
                    __typename: 'FootballGameEvent';
                    statusText: string | undefined;
                    id: string;
                    text: string;
                    gamePlayer: { name: string | undefined; image: string | undefined } | undefined;
                  }
                | { __typename: 'GameEvent'; id: string; text: string }
              >;
            }
        >;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        __typename: 'FootballGame';
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gameSnapshot:
          | {
              distance: number | undefined;
              down: number | undefined;
              gamePlayerImage: string | undefined;
              lastEventText: string | undefined;
              possessionGameTeamId: string | undefined;
              toGoText: string | undefined;
              yardline: number | undefined;
            }
          | undefined;
        scoringGameEventGroupings: Array<{
          id: string;
          periodText: string | undefined;
          image: string | undefined;
          name: string;
          eventText: string | undefined;
          teamName: string | undefined;
        }>;
        gameEventGroupings: Array<
          | {
              __typename: 'FootballGameEventGrouping';
              image: string | undefined;
              metaText: string | undefined;
              periodText: string | undefined;
              teamName: string | undefined;
              id: string;
              name: string;
              awayScore: number | undefined;
              homeScore: number | undefined;
              gameEvents: Array<
                | {
                    __typename: 'BasketballGameEvent';
                    gameTeamId: string | undefined;
                    points: number | undefined;
                    awayScore: number | undefined;
                    homeScore: number | undefined;
                    id: string;
                    text: string;
                  }
                | {
                    __typename: 'FootballGameEvent';
                    statusText: string | undefined;
                    id: string;
                    text: string;
                    gamePlayer: { name: string | undefined; image: string | undefined } | undefined;
                  }
                | { __typename: 'GameEvent'; id: string; text: string }
              >;
            }
          | {
              __typename: 'GameEventGrouping';
              id: string;
              name: string;
              awayScore: number | undefined;
              homeScore: number | undefined;
              gameEvents: Array<
                | {
                    __typename: 'BasketballGameEvent';
                    gameTeamId: string | undefined;
                    points: number | undefined;
                    awayScore: number | undefined;
                    homeScore: number | undefined;
                    id: string;
                    text: string;
                  }
                | {
                    __typename: 'FootballGameEvent';
                    statusText: string | undefined;
                    id: string;
                    text: string;
                    gamePlayer: { name: string | undefined; image: string | undefined } | undefined;
                  }
                | { __typename: 'GameEvent'; id: string; text: string }
              >;
            }
        >;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      }
    | {
        __typename: 'Game';
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        gameEventGroupings: Array<
          | {
              __typename: 'FootballGameEventGrouping';
              image: string | undefined;
              metaText: string | undefined;
              periodText: string | undefined;
              teamName: string | undefined;
              id: string;
              name: string;
              awayScore: number | undefined;
              homeScore: number | undefined;
              gameEvents: Array<
                | {
                    __typename: 'BasketballGameEvent';
                    gameTeamId: string | undefined;
                    points: number | undefined;
                    awayScore: number | undefined;
                    homeScore: number | undefined;
                    id: string;
                    text: string;
                  }
                | {
                    __typename: 'FootballGameEvent';
                    statusText: string | undefined;
                    id: string;
                    text: string;
                    gamePlayer: { name: string | undefined; image: string | undefined } | undefined;
                  }
                | { __typename: 'GameEvent'; id: string; text: string }
              >;
            }
          | {
              __typename: 'GameEventGrouping';
              id: string;
              name: string;
              awayScore: number | undefined;
              homeScore: number | undefined;
              gameEvents: Array<
                | {
                    __typename: 'BasketballGameEvent';
                    gameTeamId: string | undefined;
                    points: number | undefined;
                    awayScore: number | undefined;
                    homeScore: number | undefined;
                    id: string;
                    text: string;
                  }
                | {
                    __typename: 'FootballGameEvent';
                    statusText: string | undefined;
                    id: string;
                    text: string;
                    gamePlayer: { name: string | undefined; image: string | undefined } | undefined;
                  }
                | { __typename: 'GameEvent'; id: string; text: string }
              >;
            }
        >;
        contest:
          | {
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket: { webPath: string } | undefined;
            }
          | undefined;
        gameTeams: Array<{
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          standing: { overallRecord: string };
          team: {
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            state: { name: string } | undefined;
          };
        }>;
        genderSport: { name: string };
        level: { abbrev: string | undefined } | undefined;
      };
};

export type GamesShowQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GamesShowQuery = {
  game:
    | {
        locationText: string | undefined;
        venue: string | undefined;
        latitude: number | undefined;
        longitude: number | undefined;
        following_count: number | undefined;
        gameTypeLabel: string;
        secondaryStatusId: number | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        contest:
          | {
              expectedStatus: ContestExpectedStatusEnum;
              bracketRound: string | undefined;
              id: string;
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket:
                | {
                    logoImage: string | undefined;
                    name: string;
                    webPath: string;
                    bracketContestTeams: Array<{
                      team:
                        | {
                            id: string;
                            image: string | undefined;
                            name: string;
                            webPath: string;
                            standing: { leagueRecord: string; overallRecord: string };
                          }
                        | undefined;
                    }>;
                  }
                | undefined;
            }
          | undefined;
        gameTeams: Array<{
          picks: number;
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          team: {
            id: string;
            coverImage: string | undefined;
            webSchedulePath: string | undefined;
            primaryColor: string | undefined;
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            nextGames: {
              nodes: Array<
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
              >;
            };
            previousGames: {
              nodes: Array<
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
              >;
            };
            state: { name: string } | undefined;
          };
          leaders:
            | Array<{
                category: string;
                game_player:
                  | {
                      id: string;
                      game_team_id: number | undefined;
                      image: string | undefined;
                      jersey_number: string | undefined;
                      first_name: string | undefined;
                      last_name: string | undefined;
                      web_path: string | undefined;
                      stat:
                        | {
                            field_goals_made: number | undefined;
                            three_points_made: number | undefined;
                            free_throws_made: number | undefined;
                            points: number | undefined;
                          }
                        | {
                            passing_completions: number | undefined;
                            passing_attempts: number | undefined;
                            passing_yards: number | undefined;
                            yards_per_attempt: number | undefined;
                            passing_touchdowns: number | undefined;
                            rushing_attempts: number | undefined;
                            rushing_yards: number | undefined;
                            rushing_yards_per_attempt: number | undefined;
                            rushing_touchdowns: number | undefined;
                            receiving_receptions: number | undefined;
                            receiving_yards: number | undefined;
                            receiving_touchdowns: number | undefined;
                            yards_per_reception: string | undefined;
                          }
                        | {};
                    }
                  | undefined;
              }>
            | undefined;
          standing: { overallRecord: string; overallWinPercentage: string; homeRecord: string; awayRecord: string };
        }>;
        series:
          | {
              id: string;
              webPath: string | undefined;
              bestOutOf: number | undefined;
              seriesParticipants: Array<{ wins: number | undefined; participant: { id: string } | {} }>;
              games: Array<{
                id: string;
                webPath: string;
                date: string;
                longStatusText: string;
                gameTeams: Array<{ scoreText: string | undefined; team: { id: string; image: string | undefined; name: string } }>;
              }>;
            }
          | undefined;
        level: { name: string; abbrev: string | undefined } | undefined;
        weather_forecast_data:
          | {
              date: string | undefined;
              maximum_temperature: string | undefined;
              minimum_temperature: string | undefined;
              day_icon: string | undefined;
              night_icon: string | undefined;
              link: string | undefined;
            }
          | undefined;
        current_weather_conditions:
          | {
              date: string | undefined;
              weather_text: string | undefined;
              weather_icon: string | undefined;
              temperature: string | undefined;
              link: string | undefined;
            }
          | undefined;
        league:
          | {
              fullName: string | undefined;
              label: string;
              name: string;
              webPath: string;
              teamStandings: Array<{
                place: number | undefined;
                team: { id: string; image: string | undefined; name: string; webPath: string };
                standing: { leagueRecord: string; leagueGamesBack: string; overallRecord: string };
              }>;
            }
          | undefined;
        genderSport: {
          sportId: number;
          slug: string;
          key: GenderSportEnum;
          name: string;
          gender: { name: string };
          sport: { name: string };
        };
        linescore:
          | { title: string; periods: Array<{ id: string; label: string; away: number | undefined; home: number | undefined }> }
          | undefined;
      }
    | {
        locationText: string | undefined;
        venue: string | undefined;
        latitude: number | undefined;
        longitude: number | undefined;
        following_count: number | undefined;
        gameTypeLabel: string;
        secondaryStatusId: number | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        scoringGameEventGroupings: Array<{
          id: string;
          periodText: string | undefined;
          image: string | undefined;
          name: string;
          eventText: string | undefined;
          teamName: string | undefined;
          awayScore: number | undefined;
          homeScore: number | undefined;
        }>;
        contest:
          | {
              expectedStatus: ContestExpectedStatusEnum;
              bracketRound: string | undefined;
              id: string;
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket:
                | {
                    logoImage: string | undefined;
                    name: string;
                    webPath: string;
                    bracketContestTeams: Array<{
                      team:
                        | {
                            id: string;
                            image: string | undefined;
                            name: string;
                            webPath: string;
                            standing: { leagueRecord: string; overallRecord: string };
                          }
                        | undefined;
                    }>;
                  }
                | undefined;
            }
          | undefined;
        gameTeams: Array<{
          picks: number;
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          team: {
            id: string;
            coverImage: string | undefined;
            webSchedulePath: string | undefined;
            primaryColor: string | undefined;
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            nextGames: {
              nodes: Array<
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
              >;
            };
            previousGames: {
              nodes: Array<
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
              >;
            };
            state: { name: string } | undefined;
          };
          leaders:
            | Array<{
                category: string;
                game_player:
                  | {
                      id: string;
                      game_team_id: number | undefined;
                      image: string | undefined;
                      jersey_number: string | undefined;
                      first_name: string | undefined;
                      last_name: string | undefined;
                      web_path: string | undefined;
                      stat:
                        | {
                            field_goals_made: number | undefined;
                            three_points_made: number | undefined;
                            free_throws_made: number | undefined;
                            points: number | undefined;
                          }
                        | {
                            passing_completions: number | undefined;
                            passing_attempts: number | undefined;
                            passing_yards: number | undefined;
                            yards_per_attempt: number | undefined;
                            passing_touchdowns: number | undefined;
                            rushing_attempts: number | undefined;
                            rushing_yards: number | undefined;
                            rushing_yards_per_attempt: number | undefined;
                            rushing_touchdowns: number | undefined;
                            receiving_receptions: number | undefined;
                            receiving_yards: number | undefined;
                            receiving_touchdowns: number | undefined;
                            yards_per_reception: string | undefined;
                          }
                        | {};
                    }
                  | undefined;
              }>
            | undefined;
          standing: { overallRecord: string; overallWinPercentage: string; homeRecord: string; awayRecord: string };
        }>;
        series:
          | {
              id: string;
              webPath: string | undefined;
              bestOutOf: number | undefined;
              seriesParticipants: Array<{ wins: number | undefined; participant: { id: string } | {} }>;
              games: Array<{
                id: string;
                webPath: string;
                date: string;
                longStatusText: string;
                gameTeams: Array<{ scoreText: string | undefined; team: { id: string; image: string | undefined; name: string } }>;
              }>;
            }
          | undefined;
        level: { name: string; abbrev: string | undefined } | undefined;
        weather_forecast_data:
          | {
              date: string | undefined;
              maximum_temperature: string | undefined;
              minimum_temperature: string | undefined;
              day_icon: string | undefined;
              night_icon: string | undefined;
              link: string | undefined;
            }
          | undefined;
        current_weather_conditions:
          | {
              date: string | undefined;
              weather_text: string | undefined;
              weather_icon: string | undefined;
              temperature: string | undefined;
              link: string | undefined;
            }
          | undefined;
        league:
          | {
              fullName: string | undefined;
              label: string;
              name: string;
              webPath: string;
              teamStandings: Array<{
                place: number | undefined;
                team: { id: string; image: string | undefined; name: string; webPath: string };
                standing: { leagueRecord: string; leagueGamesBack: string; overallRecord: string };
              }>;
            }
          | undefined;
        genderSport: {
          sportId: number;
          slug: string;
          key: GenderSportEnum;
          name: string;
          gender: { name: string };
          sport: { name: string };
        };
        linescore:
          | { title: string; periods: Array<{ id: string; label: string; away: number | undefined; home: number | undefined }> }
          | undefined;
      }
    | {
        locationText: string | undefined;
        venue: string | undefined;
        latitude: number | undefined;
        longitude: number | undefined;
        following_count: number | undefined;
        gameTypeLabel: string;
        secondaryStatusId: number | undefined;
        id: string;
        clockText: string | undefined;
        date: string;
        mediumStatusText: string;
        statusId: number;
        webBoxscorePath: string | undefined;
        webPath: string;
        webPhotosPath: string | undefined;
        webPreviewPath: string | undefined;
        webRecapPath: string | undefined;
        webScorebookCastPath: string | undefined;
        image: string;
        titleText: string;
        contest:
          | {
              expectedStatus: ContestExpectedStatusEnum;
              bracketRound: string | undefined;
              id: string;
              hometownTicketingUrl: string | undefined;
              gofanTicketingUrl: string | undefined;
              nfhsNetworkUrl: string | undefined;
              bracket:
                | {
                    logoImage: string | undefined;
                    name: string;
                    webPath: string;
                    bracketContestTeams: Array<{
                      team:
                        | {
                            id: string;
                            image: string | undefined;
                            name: string;
                            webPath: string;
                            standing: { leagueRecord: string; overallRecord: string };
                          }
                        | undefined;
                    }>;
                  }
                | undefined;
            }
          | undefined;
        gameTeams: Array<{
          picks: number;
          id: string;
          isHome: boolean;
          isTbd: boolean;
          result: GameTeamResultEnum | undefined;
          scoreText: string | undefined;
          team: {
            id: string;
            coverImage: string | undefined;
            webSchedulePath: string | undefined;
            primaryColor: string | undefined;
            abbrev: string;
            image: string | undefined;
            mascot: string | undefined;
            name: string;
            webPath: string;
            nextGames: {
              nodes: Array<
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    gameTeams: Array<{ id: string; isHome: boolean; team: { id: string; image: string | undefined; name: string } }>;
                  }
              >;
            };
            previousGames: {
              nodes: Array<
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
                | {
                    id: string;
                    date: string;
                    longStatusText: string;
                    statusId: number;
                    webPath: string;
                    headline: string | undefined;
                    gameTeams: Array<{
                      id: string;
                      isHome: boolean;
                      result: GameTeamResultEnum | undefined;
                      scoreText: string | undefined;
                      team: { id: string; image: string | undefined; name: string };
                    }>;
                  }
              >;
            };
            state: { name: string } | undefined;
          };
          leaders:
            | Array<{
                category: string;
                game_player:
                  | {
                      id: string;
                      game_team_id: number | undefined;
                      image: string | undefined;
                      jersey_number: string | undefined;
                      first_name: string | undefined;
                      last_name: string | undefined;
                      web_path: string | undefined;
                      stat:
                        | {
                            field_goals_made: number | undefined;
                            three_points_made: number | undefined;
                            free_throws_made: number | undefined;
                            points: number | undefined;
                          }
                        | {
                            passing_completions: number | undefined;
                            passing_attempts: number | undefined;
                            passing_yards: number | undefined;
                            yards_per_attempt: number | undefined;
                            passing_touchdowns: number | undefined;
                            rushing_attempts: number | undefined;
                            rushing_yards: number | undefined;
                            rushing_yards_per_attempt: number | undefined;
                            rushing_touchdowns: number | undefined;
                            receiving_receptions: number | undefined;
                            receiving_yards: number | undefined;
                            receiving_touchdowns: number | undefined;
                            yards_per_reception: string | undefined;
                          }
                        | {};
                    }
                  | undefined;
              }>
            | undefined;
          standing: { overallRecord: string; overallWinPercentage: string; homeRecord: string; awayRecord: string };
        }>;
        series:
          | {
              id: string;
              webPath: string | undefined;
              bestOutOf: number | undefined;
              seriesParticipants: Array<{ wins: number | undefined; participant: { id: string } | {} }>;
              games: Array<{
                id: string;
                webPath: string;
                date: string;
                longStatusText: string;
                gameTeams: Array<{ scoreText: string | undefined; team: { id: string; image: string | undefined; name: string } }>;
              }>;
            }
          | undefined;
        level: { name: string; abbrev: string | undefined } | undefined;
        weather_forecast_data:
          | {
              date: string | undefined;
              maximum_temperature: string | undefined;
              minimum_temperature: string | undefined;
              day_icon: string | undefined;
              night_icon: string | undefined;
              link: string | undefined;
            }
          | undefined;
        current_weather_conditions:
          | {
              date: string | undefined;
              weather_text: string | undefined;
              weather_icon: string | undefined;
              temperature: string | undefined;
              link: string | undefined;
            }
          | undefined;
        league:
          | {
              fullName: string | undefined;
              label: string;
              name: string;
              webPath: string;
              teamStandings: Array<{
                place: number | undefined;
                team: { id: string; image: string | undefined; name: string; webPath: string };
                standing: { leagueRecord: string; leagueGamesBack: string; overallRecord: string };
              }>;
            }
          | undefined;
        genderSport: {
          sportId: number;
          slug: string;
          key: GenderSportEnum;
          name: string;
          gender: { name: string };
          sport: { name: string };
        };
        linescore:
          | { title: string; periods: Array<{ id: string; label: string; away: number | undefined; home: number | undefined }> }
          | undefined;
      };
};

export type OrganizationsScoresQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  paginating: Scalars['Boolean']['input'];
}>;

export type OrganizationsScoresQuery = {
  organization:
    | {
        __typename: 'SchoolOrganization';
        slug: string;
        fullName: string | undefined;
        image: string | undefined;
        name: string;
        state: { slug: string; name: string };
        scoreboardDate: {
          date: string;
          games: {
            nodes: Array<
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
            >;
            pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
          };
          nextScoreboardDate?: { date: string } | undefined;
          previousScoreboardDate?: { date: string } | undefined;
        };
      }
    | {
        __typename: 'TeamOrganization';
        legacyWebScoresPath: string | undefined;
        migratedWebScoresPath: string | undefined;
        slug: string;
        webPath: string;
        webScoresPath: string;
        webStandingsPath: string;
        fullName: string | undefined;
        image: string | undefined;
        name: string;
        genderSport: { slug: string; name: string };
        state: { slug: string; name: string };
        scoreboardDate: {
          date: string;
          games: {
            nodes: Array<
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    pregameStanding: { overallRecord: string } | undefined;
                    team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
                  }>;
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
            >;
            pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
          };
          nextScoreboardDate?: { date: string } | undefined;
          previousScoreboardDate?: { date: string } | undefined;
        };
      };
};

export type OrganizationsShowQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type OrganizationsShowQuery = {
  organization:
    | {
        __typename: 'SchoolOrganization';
        label: string;
        coverImage: string | undefined;
        fullName: string | undefined;
        image: string | undefined;
        name: string;
        ancestors: Array<
          | { id: string; fullName: string | undefined; label: string; name: string }
          | { id: string; fullName: string | undefined; label: string; name: string }
        >;
        state: { name: string };
      }
    | {
        __typename: 'TeamOrganization';
        legacyWebPath: string | undefined;
        migratedWebPath: string | undefined;
        label: string;
        coverImage: string | undefined;
        webPath: string;
        webScoresPath: string;
        webStandingsPath: string;
        fullName: string | undefined;
        image: string | undefined;
        name: string;
        genderSport: { key: GenderSportEnum; name: string };
        teamStandings: Array<{
          team: { id: string; image: string | undefined; name: string; webPath: string };
          standing: {
            leagueRecord: string;
            leagueGamesBack: string;
            overallRecord: string;
            overallWinPercentage: string;
            homeRecord: string;
            awayRecord: string;
          };
        }>;
        ancestors: Array<
          | { id: string; fullName: string | undefined; label: string; name: string }
          | { id: string; fullName: string | undefined; label: string; name: string }
        >;
        state: { name: string };
      };
};

export type OrganizationsStandingsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  schoolYear?: InputMaybe<Scalars['String']['input']>;
}>;

export type OrganizationsStandingsQuery = {
  organization:
    | {
        __typename: 'SchoolOrganization';
        fullName: string | undefined;
        image: string | undefined;
        name: string;
        slug: string;
        state: { name: string; slug: string };
      }
    | {
        __typename: 'TeamOrganization';
        legacyWebStandingsPath: string | undefined;
        migratedWebStandingsPath: string | undefined;
        webPath: string;
        webScoresPath: string;
        webStandingsPath: string;
        fullName: string | undefined;
        image: string | undefined;
        name: string;
        slug: string;
        genderSport: { name: string; slug: string };
        teamStandings: Array<{
          team: { id: string; image: string | undefined; name: string; webPath: string };
          standing: {
            leagueRecord: string;
            leagueGamesBack: string;
            overallRecord: string;
            overallWinPercentage: string;
            homeRecord: string;
            awayRecord: string;
          };
        }>;
        state: { name: string; slug: string };
      };
};

export type PhotoGalleriesIndexQueryVariables = Exact<{
  state?: InputMaybe<StateEnum>;
  genderSport?: InputMaybe<GenderSportEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  afterDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  photographerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirectionEnum>;
  skipCollections: Scalars['Boolean']['input'];
}>;

export type PhotoGalleriesIndexQuery = {
  galleries: {
    totalCount: number;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
  };
  photographers: Array<{ id: string; email: string; firstName: string; lastName: string }>;
  genderSports: Array<{ sportId: number; genderId: number; slug: string; name: string; key: GenderSportEnum }>;
  recent: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  football: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  boysBasketball: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  girlsBasketball: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  baseball: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  softball: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  girlsVolleyball: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  flagFootall: {
    totalCount: number;
    nodes: Array<{
      __typename: 'ContentItemPhotoGallery';
      id: string;
      date: string;
      name: string;
      webPath: string;
      photoCount: number;
      coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      teams: Array<{
        id: string;
        abbrev: string;
        name: string;
        image: string | undefined;
        webPhotosPath: string | undefined;
        state: { name: string; abbrev: string } | undefined;
      }>;
      genderSport: { key: GenderSportEnum; slug: string } | undefined;
      photographer: { firstName: string; lastName: string; email: string };
    }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
};

export type PhotoGalleryQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type PhotoGalleryQuery = {
  contentItem:
    | {
        __typename: 'ContentItemPhotoGallery';
        name: string;
        webPath: string;
        type: string;
        highResolutionPriceInCents: number;
        lowResolutionPriceInCents: number;
        highResolutionDisplayPrice: string;
        lowResolutionDisplayPrice: string;
        monetizable: boolean;
        id: string;
        date: string;
        externalId: string;
        teams: Array<{ id: string; abbrev: string; name: string; image: string | undefined }>;
        sport: { key: SportEnum } | undefined;
        photos: Array<{
          id: string;
          filename: string;
          isAvailableToMonetize: boolean | undefined;
          players: Array<string>;
          uploadedHeight: string;
          uploadedWidth: string;
          watermark: string;
          highResolutionPriceInCents: number | undefined;
          lowResolutionPriceInCents: number | undefined;
          highResolutionDisplayPrice: string | undefined;
          lowResolutionDisplayPrice: string | undefined;
        }>;
        photographer: { firstName: string; lastName: string; email: string };
        coverPhoto: { watermark: string; uploadedHeight: string; uploadedWidth: string };
      }
    | { __typename: 'ContentItemPoll'; id: string; date: string; externalId: string }
    | { __typename: 'ContentItemTempestArticle'; id: string; date: string; externalId: string }
    | { __typename: 'ContentItemTweet'; id: string; date: string; externalId: string }
    | { __typename: 'ContentItemVoltaxArticle'; id: string; date: string; externalId: string }
    | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string; externalId: string };
};

export type RankingsIndexQueryVariables = Exact<{
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  genderSport: GenderSportEnum;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  paginating?: Scalars['Boolean']['input'];
  state?: InputMaybe<StateEnum>;
  formula?: InputMaybe<RankingFormulaEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type RankingsIndexQuery = {
  dates: Array<{ date: string; formula: RankingFormulaEnum; schoolYear: string }>;
  ranking:
    | {
        id?: string;
        date?: string;
        hasStatewide?: boolean;
        formula?: RankingFormulaEnum;
        formulaText?: string;
        schoolYear?: string;
        organizations?: Array<{ id: string; name: string; slug: string } | { id: string; name: string; slug: string }>;
        teamRankings: {
          organization?: { id: string; name: string; slug: string } | { id: string; name: string; slug: string } | undefined;
          nodes: Array<{
            id: string;
            filteredChange: number | undefined;
            filteredPlace: number | undefined;
            points: number | undefined;
            record: string;
            streak: string | undefined;
            strengthOfSchedule: number | undefined;
            top100Record: string | undefined;
            team: {
              abbrev: string;
              image: string | undefined;
              locationText: string;
              mascot: string | undefined;
              name: string;
              webPath: string;
            };
          }>;
          pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
        };
      }
    | undefined;
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type RankingsOrganizationsQueryVariables = Exact<{
  gender_sport: GenderSportEnum;
  state?: InputMaybe<StateEnum>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
}>;

export type RankingsOrganizationsQuery = {
  organizations: Array<{ id: string; name: string } | { id: string; name: string }>;
  ranking: { formula: RankingFormulaEnum } | undefined;
};

export type SchoolsIndexQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_enum?: InputMaybe<StateEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  paginating?: Scalars['Boolean']['input'];
}>;

export type SchoolsIndexQuery = {
  results: {
    nodes: Array<{ id: string; image: string | undefined; locationText: string; name: string; webPath: string }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type SchoolsNewsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SchoolsNewsQuery = {
  school: {
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webTeamsPath: string | undefined;
    webScoresPath: string | undefined;
    webNewsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    articles: {
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    owner: { id: string; username: string } | undefined;
  };
};

export type SchoolsScoresQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  genderSport?: InputMaybe<GenderSportEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  paginating: Scalars['Boolean']['input'];
}>;

export type SchoolsScoresQuery = {
  school: {
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webTeamsPath: string | undefined;
    webScoresPath: string | undefined;
    webNewsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    scoreboardDate: {
      date: string;
      games: {
        nodes: Array<
          | {
              divisionText: string;
              id: string;
              date: string;
              longStatusText: string;
              statusId: number;
              titleText: string;
              webPath: string;
              webPhotosPath: string | undefined;
              genderSport: { key: GenderSportEnum; name: string };
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
            }
          | {
              divisionText: string;
              id: string;
              date: string;
              longStatusText: string;
              statusId: number;
              titleText: string;
              webPath: string;
              webPhotosPath: string | undefined;
              genderSport: { key: GenderSportEnum; name: string };
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
            }
          | {
              divisionText: string;
              id: string;
              date: string;
              longStatusText: string;
              statusId: number;
              titleText: string;
              webPath: string;
              webPhotosPath: string | undefined;
              genderSport: { key: GenderSportEnum; name: string };
              gameTeams: Array<{
                id: string;
                isLoser: boolean;
                isTbd: boolean;
                isWinner: boolean;
                scoreText: string | undefined;
                pregameStanding: { overallRecord: string } | undefined;
                team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
              }>;
              contest:
                | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
                | undefined;
            }
        >;
        pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
      };
      nextScoreboardDate?: { date: string } | undefined;
      previousScoreboardDate?: { date: string } | undefined;
    };
    owner: { id: string; username: string } | undefined;
  };
};

export type SchoolsShowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SchoolsShowQuery = {
  school: {
    coverImage: string | undefined;
    isOwned: boolean;
    fansCount: number;
    primaryColor: string | undefined;
    secondaryColor: string | undefined;
    schoolTypeEnum: SchoolTypeEnum | undefined;
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webTeamsPath: string | undefined;
    webScoresPath: string | undefined;
    webNewsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    athleticDirector: { name: string | undefined } | undefined;
    popularTeams: {
      nodes: Array<{
        id: string;
        fansCount: number;
        webPath: string;
        genderSport: { key: GenderSportEnum; name: string };
        level: { abbrev: string | undefined };
      }>;
    };
    owner: { id: string; username: string } | undefined;
  };
};

export type SchoolsTeamsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  level: LevelEnum;
}>;

export type SchoolsTeamsQuery = {
  school: {
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webTeamsPath: string | undefined;
    webScoresPath: string | undefined;
    webNewsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    levels: Array<{ key: LevelEnum; name: string }>;
    teams: {
      nodes: Array<{
        id: string;
        webPath: string;
        genderSport: { key: GenderSportEnum; name: string; sport: { name: string } };
        standing: { overallRecord: string; overallWinPercentage: string };
        league: { name: string; webPath: string } | undefined;
      }>;
    };
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    owner: { id: string; username: string } | undefined;
  };
};

export type SeriesShowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  gender_sport?: InputMaybe<GenderSportEnum>;
  state?: InputMaybe<StateEnum>;
}>;

export type SeriesShowQuery = {
  series: {
    id: string;
    webPath: string | undefined;
    seriesParticipants: Array<{ id: string; participant: { name: string } | { name: string; state: { name: string } | undefined } }>;
    games: Array<{
      id: string;
      date: string;
      longStatusText: string;
      statusId: number;
      titleText: string;
      webPath: string;
      webPhotosPath: string | undefined;
      gameTeams: Array<{
        id: string;
        isLoser: boolean;
        isTbd: boolean;
        isWinner: boolean;
        scoreText: string | undefined;
        pregameStanding: { overallRecord: string } | undefined;
        team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
      }>;
      contest:
        | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
        | undefined;
    }>;
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type StandingsIndexQueryVariables = Exact<{
  gender_sport: GenderSportEnum;
  state_array?: InputMaybe<Array<StateEnum> | StateEnum>;
  state?: InputMaybe<StateEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  paginating?: Scalars['Boolean']['input'];
  parentOrganizationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type StandingsIndexQuery = {
  organizations: {
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    nodes: Array<
      | {
          __typename: 'SchoolOrganization';
          id: string;
          fullName: string | undefined;
          name: string;
          state: { name: string; abbrev: string; key: StateEnum };
        }
      | {
          __typename: 'TeamOrganization';
          webPath: string;
          id: string;
          fullName: string | undefined;
          name: string;
          teamStandings: Array<{
            team: { id: string; image: string | undefined; name: string; webPath: string };
            standing: {
              leagueRecord: string;
              leagueGamesBack: string;
              overallRecord: string;
              overallWinPercentage: string;
              homeRecord: string;
              awayRecord: string;
            };
          }>;
          state: { name: string; abbrev: string; key: StateEnum };
        }
    >;
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
  parentOrganizations: {
    nodes: Array<
      | { id: string; name: string; organizationTypeKey: OrganizationTypeEnum; slug: string }
      | { id: string; name: string; organizationTypeKey: OrganizationTypeEnum; slug: string }
    >;
  };
};

export type StandingsShowQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type StandingsShowQuery = {
  organization:
    | {
        __typename: 'SchoolOrganization';
        id: string;
        fullName: string | undefined;
        name: string;
        state: { key: StateEnum; name: string; abbrev: string };
      }
    | {
        __typename: 'TeamOrganization';
        webPath: string;
        id: string;
        fullName: string | undefined;
        name: string;
        teamStandings: Array<{
          team: { id: string; image: string | undefined; name: string; webPath: string };
          standing: {
            leagueRecord: string;
            leagueGamesBack: string;
            overallRecord: string;
            overallWinPercentage: string;
            homeRecord: string;
            awayRecord: string;
          };
        }>;
        state: { key: StateEnum; name: string; abbrev: string };
      };
};

export type TeamPhotoGalleriesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type TeamPhotoGalleriesQuery = {
  team: {
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    contentItems: {
      nodes: Array<
        | {
            __typename: 'ContentItemPhotoGallery';
            name: string;
            webPath: string;
            photoCount: number;
            id: string;
            date: string;
            externalId: string;
            coverPhoto: { filename: string; watermark: string };
            teams: Array<{
              id: string;
              name: string;
              abbrev: string;
              image: string | undefined;
              webPhotosPath: string | undefined;
              state: { name: string; abbrev: string } | undefined;
            }>;
            photographer: { firstName: string; lastName: string };
          }
        | { __typename: 'ContentItemPoll'; id: string; date: string; externalId: string }
        | { __typename: 'ContentItemTempestArticle'; id: string; date: string; externalId: string }
        | { __typename: 'ContentItemTweet'; id: string; date: string; externalId: string }
        | { __typename: 'ContentItemVoltaxArticle'; id: string; date: string; externalId: string }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string; externalId: string }
      >;
    };
    level: { name: string };
    genderSport: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

export type TeamPlayersIndexQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schoolYear: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  paginating: Scalars['Boolean']['input'];
}>;

export type TeamPlayersIndexQuery = {
  team: {
    divisionText?: string;
    schoolYears: Array<string>;
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    genderSport: { slug: string; name: string };
    teamCoaches?: Array<{
      id: string;
      year_hired_text: string | undefined;
      coachRoleText: string;
      coach: { id: string; firstName: string; lastName: string; image: string | undefined; cover_image: string | undefined };
    }>;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    teamPlayers: {
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
      nodes: Array<{
        id: string;
        jerseyNumber: string | undefined;
        webPath: string | undefined;
        player: {
          id: string;
          classYearAbbrev: string | undefined;
          classYear: number | undefined;
          formattedClassYearAbbrev: string | undefined;
          heightText: string | undefined;
          image: string | undefined;
          name: string | undefined;
          weightText: string | undefined;
        };
        position: { abbrev: string } | undefined;
        secondaryPosition: { abbrev: string } | undefined;
      }>;
    };
    level: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

export type TeamPlayersShowQueryVariables = Exact<{
  playerId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
  schoolYear: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  paginating?: Scalars['Boolean']['input'];
}>;

export type TeamPlayersShowQuery = {
  team: {
    coverImage: string | undefined;
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    genderSport: { key: GenderSportEnum; slug: string; sportId: number; name: string };
    level: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
  teamPlayer: {
    id: string;
    jerseyNumber: string | undefined;
    schoolYears: Array<string>;
    webPath: string | undefined;
    gamePlayers: {
      nodes: Array<{
        id: string;
        opponent: { isHome: boolean; team: { name: string; abbrev: string; image: string | undefined } };
        game: { date: string; webPath: string };
        stat:
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | {
              points_per_game: number | undefined;
              free_throws: string | undefined;
              free_throw_percentage: number | undefined;
              two_points_made: number | undefined;
              three_points_made: number | undefined;
              personal_fouls: number | undefined;
              games_played: number | undefined;
              points: number | undefined;
            }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | {
              passing: string | undefined;
              passing_yards: number | undefined;
              passing_touchdowns: number | undefined;
              passing_interceptions: number | undefined;
              rushing_attempts: number | undefined;
              rushing_yards: number | undefined;
              yards_per_carry: string | undefined;
              rushing_touchdowns: number | undefined;
              receiving_receptions: number | undefined;
              receiving_yards: number | undefined;
              yards_per_reception: string | undefined;
              receiving_touchdowns: number | undefined;
              primary_stat_group: string | undefined;
              games_played: number | undefined;
              points: number | undefined;
              points_per_game: number | undefined;
            }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
          | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined };
      }>;
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    };
    player: {
      classYear: number | undefined;
      coverImage: string | undefined;
      heightText: string | undefined;
      image: string | undefined;
      name: string | undefined;
      weightText: string | undefined;
    };
    position: { name: string } | undefined;
    secondaryPosition: { name: string } | undefined;
    stat:
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | {
          points_per_game: number | undefined;
          free_throws: string | undefined;
          free_throw_percentage: number | undefined;
          two_points_made: number | undefined;
          three_points_made: number | undefined;
          personal_fouls: number | undefined;
          games_played: number | undefined;
          points: number | undefined;
        }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | {
          passing: string | undefined;
          passing_yards: number | undefined;
          passing_touchdowns: number | undefined;
          passing_interceptions: number | undefined;
          rushing_attempts: number | undefined;
          rushing_yards: number | undefined;
          yards_per_carry: string | undefined;
          rushing_touchdowns: number | undefined;
          receiving_receptions: number | undefined;
          receiving_yards: number | undefined;
          yards_per_reception: string | undefined;
          receiving_touchdowns: number | undefined;
          primary_stat_group: string | undefined;
          games_played: number | undefined;
          points: number | undefined;
          points_per_game: number | undefined;
        }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | { games_played: number | undefined; points: number | undefined; points_per_game: number | undefined }
      | undefined;
  };
};

type TeamPlayersShowQueryTeamPlayerStatsBaseballStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsBasketballStatFragment = {
  points_per_game: number | undefined;
  free_throws: string | undefined;
  free_throw_percentage: number | undefined;
  two_points_made: number | undefined;
  three_points_made: number | undefined;
  personal_fouls: number | undefined;
  games_played: number | undefined;
  points: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsBeachVolleyballStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsFieldHockeyStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsFlagFootballStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsFootballStatFragment = {
  passing: string | undefined;
  passing_yards: number | undefined;
  passing_touchdowns: number | undefined;
  passing_interceptions: number | undefined;
  rushing_attempts: number | undefined;
  rushing_yards: number | undefined;
  yards_per_carry: string | undefined;
  rushing_touchdowns: number | undefined;
  receiving_receptions: number | undefined;
  receiving_yards: number | undefined;
  yards_per_reception: string | undefined;
  receiving_touchdowns: number | undefined;
  primary_stat_group: string | undefined;
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsGolfStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsIceHockeyStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsLacrosseStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsSoccerStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsSoftballStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsTennisStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsUltimateFrisbeeStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsUnifiedBasketballStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsVolleyballStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

type TeamPlayersShowQueryTeamPlayerStatsWaterPoloStatFragment = {
  games_played: number | undefined;
  points: number | undefined;
  points_per_game: number | undefined;
};

export type TeamPlayersShowQueryTeamPlayerStatsFragment =
  | TeamPlayersShowQueryTeamPlayerStatsBaseballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsBasketballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsBeachVolleyballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsFieldHockeyStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsFlagFootballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsFootballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsGolfStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsIceHockeyStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsLacrosseStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsSoccerStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsSoftballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsTennisStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsUltimateFrisbeeStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsUnifiedBasketballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsVolleyballStatFragment
  | TeamPlayersShowQueryTeamPlayerStatsWaterPoloStatFragment;

export type TeamsGamesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schoolYear?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  paginating?: Scalars['Boolean']['input'];
}>;

export type TeamsGamesQuery = {
  team: {
    schoolYears?: Array<string>;
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    sport?: { name: string } | undefined;
    nextGames?: {
      nodes: Array<
        | {
            date: string;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string };
            opponent: { team: { image: string | undefined; name: string; mascot: string | undefined } };
          }
        | {
            date: string;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string };
            opponent: { team: { image: string | undefined; name: string; mascot: string | undefined } };
          }
        | {
            date: string;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string };
            opponent: { team: { image: string | undefined; name: string; mascot: string | undefined } };
          }
      >;
    };
    games: {
      nodes: Array<
        | {
            id: string;
            date: string;
            gameTypeLabel: string;
            locationText: string | undefined;
            secondaryStatusId: number | undefined;
            shortStatusText: string;
            statusId: number;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined } | undefined;
            featured: {
              locationDescriptor: string;
              result: GameTeamResultEnum | undefined;
              scoreText: string | undefined;
              standing: { overallRecord: string; leagueRecord: string };
            };
            opponent: { scoreText: string | undefined; team: { image: string | undefined; name: string; webPath: string } };
          }
        | {
            id: string;
            date: string;
            gameTypeLabel: string;
            locationText: string | undefined;
            secondaryStatusId: number | undefined;
            shortStatusText: string;
            statusId: number;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined } | undefined;
            featured: {
              locationDescriptor: string;
              result: GameTeamResultEnum | undefined;
              scoreText: string | undefined;
              standing: { overallRecord: string; leagueRecord: string };
            };
            opponent: { scoreText: string | undefined; team: { image: string | undefined; name: string; webPath: string } };
          }
        | {
            id: string;
            date: string;
            gameTypeLabel: string;
            locationText: string | undefined;
            secondaryStatusId: number | undefined;
            shortStatusText: string;
            statusId: number;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined } | undefined;
            featured: {
              locationDescriptor: string;
              result: GameTeamResultEnum | undefined;
              scoreText: string | undefined;
              standing: { overallRecord: string; leagueRecord: string };
            };
            opponent: { scoreText: string | undefined; team: { image: string | undefined; name: string; webPath: string } };
          }
      >;
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
    };
    level: { name: string };
    genderSport: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

export type TeamsIndexQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  gender_sport?: InputMaybe<Array<GenderSportEnum> | GenderSportEnum>;
  state_enum?: InputMaybe<StateEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  paginating?: Scalars['Boolean']['input'];
}>;

export type TeamsIndexQuery = {
  results: {
    nodes: Array<{ id: string; divisionText: string; image: string | undefined; locationText: string; name: string; webPath: string }>;
    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  };
  articles: {
    nodes: Array<
      | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
      | { __typename: 'ContentItemPoll'; id: string; date: string }
      | {
          __typename: 'ContentItemTempestArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemTweet'; id: string; date: string }
      | {
          __typename: 'ContentItemVoltaxArticle';
          authorImage: string | undefined;
          authorName: string;
          description: string | undefined;
          imageAlt: string | undefined;
          imageUrl: string | undefined;
          path: string;
          title: string;
          id: string;
          date: string;
          genderSport: { name: string } | undefined;
        }
      | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
    >;
  };
};

export type TeamsNewsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type TeamsNewsQuery = {
  team: {
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    articles: {
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    level: { name: string };
    genderSport: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

export type TeamsRankingsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type TeamsRankingsQuery = {
  team: {
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    genderSport: { slug: string; name: string };
    state: { slug: string; name: string } | undefined;
    teamRankingWithNeighbors:
      | Array<{
          id: string;
          filteredChange: number | undefined;
          filteredPlace: number | undefined;
          points: number | undefined;
          record: string;
          streak: string | undefined;
          strengthOfSchedule: number | undefined;
          top100Record: string | undefined;
          team: {
            id: string;
            abbrev: string;
            image: string | undefined;
            locationText: string;
            mascot: string | undefined;
            name: string;
            webPath: string;
          };
        }>
      | undefined;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    level: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

export type TeamsShowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TeamsShowQuery = {
  team: {
    coverImage: string | undefined;
    fansCount: number;
    hide: boolean | undefined;
    isOwned: boolean;
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    genderSport: { key: GenderSportEnum; name: string };
    headCoach: { coach: { name: string } } | undefined;
    league:
      | {
          fullName: string | undefined;
          label: string;
          name: string;
          webPath: string;
          teamStandings: Array<{
            place: number | undefined;
            team: { id: string; image: string | undefined; name: string; webPath: string };
            standing: {
              leagueRecord: string;
              leagueGamesBack: string;
              overallRecord: string;
              overallWinPercentage: string;
              homeRecord: string;
              awayRecord: string;
            };
          }>;
        }
      | undefined;
    nextGames: {
      nodes: Array<
        | {
            id: string;
            date: string;
            shortStatusText: string;
            statusId: number;
            titleText: string;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string; result: GameTeamResultEnum | undefined; scoreText: string | undefined };
            opponent: {
              result: GameTeamResultEnum | undefined;
              scoreText: string | undefined;
              team: { image: string | undefined; mascot: string | undefined; name: string };
            };
          }
        | {
            id: string;
            date: string;
            shortStatusText: string;
            statusId: number;
            titleText: string;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string; result: GameTeamResultEnum | undefined; scoreText: string | undefined };
            opponent: {
              result: GameTeamResultEnum | undefined;
              scoreText: string | undefined;
              team: { image: string | undefined; mascot: string | undefined; name: string };
            };
          }
        | {
            id: string;
            date: string;
            shortStatusText: string;
            statusId: number;
            titleText: string;
            webPath: string;
            contest: { gofanTicketingUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string; result: GameTeamResultEnum | undefined; scoreText: string | undefined };
            opponent: {
              result: GameTeamResultEnum | undefined;
              scoreText: string | undefined;
              team: { image: string | undefined; mascot: string | undefined; name: string };
            };
          }
      >;
    };
    previousGames: {
      nodes: Array<
        | {
            id: string;
            date: string;
            headline: string | undefined;
            shortStatusText: string;
            statusId: number;
            webPath: string;
            contest: { nfhsNetworkUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string; result: GameTeamResultEnum | undefined; scoreText: string | undefined };
            opponent: { scoreText: string | undefined; team: { image: string | undefined; mascot: string | undefined; name: string } };
          }
        | {
            id: string;
            date: string;
            headline: string | undefined;
            shortStatusText: string;
            statusId: number;
            webPath: string;
            contest: { nfhsNetworkUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string; result: GameTeamResultEnum | undefined; scoreText: string | undefined };
            opponent: { scoreText: string | undefined; team: { image: string | undefined; mascot: string | undefined; name: string } };
          }
        | {
            id: string;
            date: string;
            headline: string | undefined;
            shortStatusText: string;
            statusId: number;
            webPath: string;
            contest: { nfhsNetworkUrl: string | undefined } | undefined;
            featured: { locationDescriptor: string; result: GameTeamResultEnum | undefined; scoreText: string | undefined };
            opponent: { scoreText: string | undefined; team: { image: string | undefined; mascot: string | undefined; name: string } };
          }
      >;
    };
    rival: { image: string | undefined; name: string; webPath: string } | undefined;
    level: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

export type TeamsStandingsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  schoolYear?: InputMaybe<Scalars['String']['input']>;
}>;

export type TeamsStandingsQuery = {
  team: {
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    league:
      | {
          label: string;
          webPath: string;
          teamStandings: Array<{
            place: number | undefined;
            team: { id: string; image: string | undefined; name: string; webPath: string };
            standing: {
              leagueRecord: string;
              leagueGamesBack: string;
              overallRecord: string;
              overallWinPercentage: string;
              homeRecord: string;
              awayRecord: string;
            };
          }>;
        }
      | undefined;
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    level: { name: string };
    genderSport: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

export type TeamsStatsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  schoolYear: Scalars['String']['input'];
}>;

export type TeamsStatsQuery = {
  team: {
    schoolYears: Array<string>;
    id: string;
    image: string | undefined;
    name: string;
    mascot: string | undefined;
    locationText: string;
    webPath: string;
    webSchedulePath: string | undefined;
    webRosterPath: string | undefined;
    webStandingsPath: string | undefined;
    webRankingsPath: string | undefined;
    webNewsPath: string | undefined;
    webPhotosPath: string | undefined;
    webStatsPath: string | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    genderSport: { sportId: number; slug: string; name: string };
    articles: {
      nodes: Array<
        | { __typename: 'ContentItemPhotoGallery'; id: string; date: string }
        | { __typename: 'ContentItemPoll'; id: string; date: string }
        | {
            __typename: 'ContentItemTempestArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemTweet'; id: string; date: string }
        | {
            __typename: 'ContentItemVoltaxArticle';
            authorImage: string | undefined;
            authorName: string;
            description: string | undefined;
            imageAlt: string | undefined;
            imageUrl: string | undefined;
            path: string;
            title: string;
            id: string;
            date: string;
            genderSport: { name: string } | undefined;
          }
        | { __typename: 'ContentItemYouTubeVideo'; id: string; date: string }
      >;
    };
    teamPlayers: {
      nodes: Array<{
        id: string;
        jerseyNumber: string | undefined;
        webPath: string | undefined;
        player: { firstName: string | undefined; lastName: string | undefined; name: string | undefined; image: string | undefined };
        stat:
          | {
              games_played: number | undefined;
              at_bats: number | undefined;
              runs: number | undefined;
              hits: number | undefined;
              singles: number | undefined;
              doubles: number | undefined;
              triples: number | undefined;
              home_runs: number | undefined;
              runs_batted_in: number | undefined;
              total_bases: number | undefined;
              total_walks: number | undefined;
              strikeouts_off: number | undefined;
              stolen_bases: number | undefined;
              batting_average: string | undefined;
              on_base_percentage: string | undefined;
              slugging_percentage: string | undefined;
              on_base_plus_slugging: string | undefined;
              games_started: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              saves: number | undefined;
              holds: number | undefined;
              innings_pitched: string | undefined;
              hits_allowed: number | undefined;
              earned_runs_allowed: number | undefined;
              home_runs_allowed: number | undefined;
              walks_allowed: number | undefined;
              strikeouts_def: number | undefined;
              strikeouts_per_9_innings: number | undefined;
              walks_hits_per_inning_pitched: number | undefined;
              earned_run_average: number | undefined;
              total_chances: number | undefined;
              put_outs: number | undefined;
              assists: number | undefined;
              fielding_percentage: string | undefined;
              fielding_errors: number | undefined;
              double_plays: number | undefined;
              pickoffs: number | undefined;
              runners_caught_stealing: number | undefined;
              stolen_base_attempts: number | undefined;
              caught_stealing_percentage: number | undefined;
              pitch_count: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              points_per_game: number | undefined;
              offensive_rebounds_per_game: number | undefined;
              defensive_rebounds_per_game: number | undefined;
              rebounds_per_game: number | undefined;
              assists_per_game: number | undefined;
              steals_per_game: number | undefined;
              blocks_per_game: number | undefined;
              turnovers_per_game: number | undefined;
              fouls_per_game: number | undefined;
              assist_to_turnover_ratio: number | undefined;
              points: number | undefined;
              two_points_made: number | undefined;
              two_points_attempted: number | undefined;
              three_points_made: number | undefined;
              three_points_attempted: number | undefined;
              free_throws_made: number | undefined;
              free_throws_attempted: number | undefined;
              offensive_rebounds: number | undefined;
              defensive_rebounds: number | undefined;
              rebounds: number | undefined;
              assists: number | undefined;
              steals: number | undefined;
              blocks: number | undefined;
              turnovers: number | undefined;
              personal_fouls: number | undefined;
              field_goals_made_per_game: number | undefined;
              field_goals_attempted_per_game: number | undefined;
              field_goal_percentage: number | undefined;
              three_points_field_goals_made_per_game: number | undefined;
              three_points_field_goals_attempted_per_game: number | undefined;
              three_points_field_goals_percentage: number | undefined;
              two_points_field_goals_made_per_game: number | undefined;
              two_points_field_goals_attempted_per_game: number | undefined;
              two_points_field_goals_percentage: number | undefined;
              free_throws_made_per_game: number | undefined;
              free_throws_attempted_per_game: number | undefined;
              free_throw_percentage: number | undefined;
              effective_field_goal_percentage: number | undefined;
            }
          | {
              games_played: number | undefined;
              sets_played: number | undefined;
              kills: number | undefined;
              kills_per_set: number | undefined;
              attack_errors: number | undefined;
              total_attack_attempts: number | undefined;
              attacking_percentage: number | undefined;
              assists: number | undefined;
              assists_per_set: number | undefined;
              service_aces: number | undefined;
              service_errors: number | undefined;
              service_aces_per_set: number | undefined;
              receiving_errors: number | undefined;
              digs: number | undefined;
              digs_per_set: number | undefined;
              solo_blocks: number | undefined;
              block_assists: number | undefined;
              total_blocks: number | undefined;
              blocks_per_set: number | undefined;
              blocking_errors: number | undefined;
              ball_handling_errors: number | undefined;
              attack_errors_per_set: number | undefined;
              service_errors_per_set: number | undefined;
              solo_blocks_per_set: number | undefined;
              block_assists_per_set: number | undefined;
              total_blocks_per_set: number | undefined;
              blocking_errors_per_set: number | undefined;
              ball_handling_errors_per_set: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              total_points: number | undefined;
              shots: number | undefined;
              shooting_percentage: number | undefined;
              shots_on_goal: number | undefined;
              shot_on_goal_percentage: number | undefined;
              defensive_save: number | undefined;
              penalty_corners: number | undefined;
              fouls: number | undefined;
              minutes_played: number | undefined;
              goals_against: number | undefined;
              goals_against_per_game: number | undefined;
              goalie_saves: number | undefined;
              save_percentage: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              ties: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              passing_completions: number | undefined;
              passing_attempts: number | undefined;
              completion_percentage: number | undefined;
              passing_yards: number | undefined;
              yards_per_attempt: number | undefined;
              yards_per_game: number | undefined;
              passing_sacks: number | undefined;
              passing_touchdowns: number | undefined;
              passing_interceptions: number | undefined;
              rushing_attempts: number | undefined;
              rushing_yards: number | undefined;
              rushing_yards_per_attempt: number | undefined;
              rushing_yards_per_game: number | undefined;
              rushing_longest: number | undefined;
              rushing_touchdowns: number | undefined;
              rushing_fumbles: number | undefined;
              receiving_receptions: number | undefined;
              targets: number | undefined;
              receiving_yards: number | undefined;
              yards_per_reception: string | undefined;
              receiving_longest: number | undefined;
              receiving_touchdowns: number | undefined;
              receiving_fumbles: number | undefined;
              solo_tackles: number | undefined;
              assisted_tackles: number | undefined;
              total_tackles: number | undefined;
              tackles_for_loss: number | undefined;
              defensive_sacks: number | undefined;
              yards_lost: number | undefined;
              hurries: number | undefined;
              qb_hits: number | undefined;
              defensive_passes_defended: number | undefined;
              defensive_interceptions: number | undefined;
              defensive_interception_yards: number | undefined;
              defensive_fumbles_forced: number | undefined;
              defensive_fumbles_recovered: number | undefined;
              defensive_blocked_punts: number | undefined;
              defensive_blocked_field_goals: number | undefined;
              defensive_touchdown: number | undefined;
              field_goals_made: number | undefined;
              field_goals_attempts: number | undefined;
              field_goal_percentage: number | undefined;
              field_goals_longest: number | undefined;
              pat_made: number | undefined;
              pat_attempts: number | undefined;
              extra_point_percentage: number | undefined;
              points: number | undefined;
              punts: number | undefined;
              punt_yards: number | undefined;
              average_punt_yards: number | undefined;
              punt_long: number | undefined;
              punts_inside_20_yard_line: number | undefined;
              total_kick_returns: number | undefined;
              kickoff_return_yards: number | undefined;
              yards_per_kick_return: number | undefined;
              kick_return_long: number | undefined;
              kick_return_touchdowns: number | undefined;
              total_punt_returns: number | undefined;
              punt_return_yards: number | undefined;
              yards_per_punt_return: number | undefined;
              punt_return_long: number | undefined;
              punt_return_touchdowns: number | undefined;
            }
          | {
              games_played: number | undefined;
              passing_completions: number | undefined;
              passing_attempts: number | undefined;
              completion_percentage: number | undefined;
              passing_yards: number | undefined;
              yards_per_attempt: number | undefined;
              yards_per_game: number | undefined;
              passing_sacks: number | undefined;
              passing_touchdowns: number | undefined;
              passing_interceptions: number | undefined;
              rushing_attempts: number | undefined;
              rushing_yards: number | undefined;
              rushing_yards_per_attempt: number | undefined;
              rushing_yards_per_game: number | undefined;
              rushing_longest: number | undefined;
              rushing_touchdowns: number | undefined;
              rushing_fumbles: number | undefined;
              receiving_receptions: number | undefined;
              targets: number | undefined;
              receiving_yards: number | undefined;
              yards_per_reception: string | undefined;
              receiving_longest: number | undefined;
              receiving_touchdowns: number | undefined;
              receiving_fumbles: number | undefined;
              solo_tackles: number | undefined;
              assisted_tackles: number | undefined;
              total_tackles: number | undefined;
              tackles_for_loss: number | undefined;
              defensive_sacks: number | undefined;
              yards_lost: number | undefined;
              hurries: number | undefined;
              qb_hits: number | undefined;
              defensive_passes_defended: number | undefined;
              defensive_interceptions: number | undefined;
              defensive_interception_yards: number | undefined;
              defensive_fumbles_forced: number | undefined;
              defensive_fumbles_recovered: number | undefined;
              defensive_blocked_punts: number | undefined;
              defensive_blocked_field_goals: number | undefined;
              defensive_touchdown: number | undefined;
              field_goals_made: number | undefined;
              field_goals_attempts: number | undefined;
              field_goal_percentage: number | undefined;
              field_goals_longest: number | undefined;
              pat_made: number | undefined;
              pat_attempts: number | undefined;
              extra_point_percentage: number | undefined;
              points: number | undefined;
              punts: number | undefined;
              punt_yards: number | undefined;
              average_punt_yards: number | undefined;
              punt_long: number | undefined;
              punts_inside_20_yard_line: number | undefined;
              total_kick_returns: number | undefined;
              kickoff_return_yards: number | undefined;
              yards_per_kick_return: number | undefined;
              kick_return_long: number | undefined;
              kick_return_touchdowns: number | undefined;
              total_punt_returns: number | undefined;
              punt_return_yards: number | undefined;
              yards_per_punt_return: number | undefined;
              punt_return_long: number | undefined;
              punt_return_touchdowns: number | undefined;
            }
          | {
              events_played: number | undefined;
              rounds_played: number | undefined;
              total_strokes: number | undefined;
              average_score: number | undefined;
              vs_par: number | undefined;
              vs_par_average: number | undefined;
              wins: number | undefined;
              top_five_finishes: number | undefined;
              top_ten_finishes: number | undefined;
              top_twenty_finishes: number | undefined;
              best_finish: number | undefined;
              average_finish: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              time_on_ice: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              total_points: number | undefined;
              penalties_in_minutes: number | undefined;
              time_on_ice_per_game: number | undefined;
              power_play_goals: number | undefined;
              power_play_assists: number | undefined;
              short_hand_goals: number | undefined;
              short_hand_assists: number | undefined;
              shots: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              overtime_losses: number | undefined;
              goals_against_per_game: number | undefined;
              saves: number | undefined;
              save_percentage: number | undefined;
              goals_against: number | undefined;
              shots_against: number | undefined;
              shootout_shorts_against: number | undefined;
              shootout_saves: number | undefined;
              shootout_save_percentage: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              minutes_played: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              total_points: number | undefined;
              shots: number | undefined;
              shots_on_goal: number | undefined;
              ground_balls: number | undefined;
              faceoffs_won: number | undefined;
              total_faceoffs: number | undefined;
              turnovers: number | undefined;
              clears: number | undefined;
              penalties: number | undefined;
              minutes_in_goal: number | undefined;
              goals_against: number | undefined;
              average_goals_against: number | undefined;
              saves: number | undefined;
              save_percentage: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              ties: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              games_started: number | undefined;
              minutes_played: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              shots: number | undefined;
              shot_percentage: number | undefined;
              shots_on_goal: number | undefined;
              shots_on_goal_percentage: number | undefined;
              penalty_kick_attempts: number | undefined;
              penalty_kick_goals: number | undefined;
              penalty_kick_percentage: number | undefined;
              goals_against: number | undefined;
              saves: number | undefined;
              save_percentage: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              draws: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              at_bats: number | undefined;
              runs: number | undefined;
              hits: number | undefined;
              singles: number | undefined;
              doubles: number | undefined;
              triples: number | undefined;
              home_runs: number | undefined;
              runs_batted_in: number | undefined;
              total_bases: number | undefined;
              total_walks: number | undefined;
              strikeouts_off: number | undefined;
              stolen_bases: number | undefined;
              batting_average: string | undefined;
              on_base_percentage: string | undefined;
              slugging_percentage: string | undefined;
              on_base_plus_slugging: string | undefined;
              games_started: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              saves: number | undefined;
              holds: number | undefined;
              innings_pitched: string | undefined;
              hits_allowed: number | undefined;
              earned_runs_allowed: number | undefined;
              home_runs_allowed: number | undefined;
              walks_allowed: number | undefined;
              strikeouts_def: number | undefined;
              strikeouts_per_9_innings: number | undefined;
              walks_hits_per_inning_pitched: number | undefined;
              earned_run_average: number | undefined;
              total_chances: number | undefined;
              put_outs: number | undefined;
              assists: number | undefined;
              fielding_percentage: string | undefined;
              fielding_errors: number | undefined;
              double_plays: number | undefined;
              pickoffs: number | undefined;
              runners_caught_stealing: number | undefined;
              points: number | undefined;
            }
          | { points: number | undefined }
          | {
              games_played: number | undefined;
              sets_played: number | undefined;
              service_attempts: number | undefined;
              service_points_won: number | undefined;
              service_aces: number | undefined;
              service_faults: number | undefined;
              double_faults: number | undefined;
              points: number | undefined;
              returns_attempted: number | undefined;
              return_points_won: number | undefined;
              break_point_attempts: number | undefined;
              break_points_won: number | undefined;
              ejections: number | undefined;
            }
          | { points: number | undefined }
          | { points: number | undefined }
          | {
              games_played: number | undefined;
              sets_played: number | undefined;
              kills: number | undefined;
              kills_per_set: number | undefined;
              attack_errors: number | undefined;
              total_attack_attempts: number | undefined;
              attacking_percentage: number | undefined;
              assists: number | undefined;
              assists_per_set: number | undefined;
              service_aces: number | undefined;
              service_errors: number | undefined;
              service_aces_per_set: number | undefined;
              receiving_errors: number | undefined;
              digs: number | undefined;
              digs_per_set: number | undefined;
              solo_blocks: number | undefined;
              block_assists: number | undefined;
              total_blocks: number | undefined;
              blocks_per_set: number | undefined;
              blocking_errors: number | undefined;
              ball_handling_errors: number | undefined;
              attack_errors_per_set: number | undefined;
              service_errors_per_set: number | undefined;
              solo_blocks_per_set: number | undefined;
              block_assists_per_set: number | undefined;
              total_blocks_per_set: number | undefined;
              blocking_errors_per_set: number | undefined;
              ball_handling_errors_per_set: number | undefined;
              points: number | undefined;
            }
          | {
              games_played: number | undefined;
              shots: number | undefined;
              goals: number | undefined;
              assists: number | undefined;
              total_points: number | undefined;
              shooting_percentage: number | undefined;
              steals: number | undefined;
              sprints_won: number | undefined;
              sprints_attempted: number | undefined;
              games_started: number | undefined;
              minutes_in_goal: number | undefined;
              wins: number | undefined;
              losses: number | undefined;
              shots_against: number | undefined;
              saves: number | undefined;
              goals_against: number | undefined;
              save_percentage: number | undefined;
              average_goals_against: number | undefined;
              points: number | undefined;
            }
          | undefined;
      }>;
    };
    stat:
      | {
          games_played: number | undefined;
          at_bats: number | undefined;
          runs: number | undefined;
          hits: number | undefined;
          singles: number | undefined;
          doubles: number | undefined;
          triples: number | undefined;
          home_runs: number | undefined;
          runs_batted_in: number | undefined;
          total_bases: number | undefined;
          total_walks: number | undefined;
          strikeouts_off: number | undefined;
          stolen_bases: number | undefined;
          batting_average: string | undefined;
          on_base_percentage: string | undefined;
          slugging_percentage: string | undefined;
          on_base_plus_slugging: string | undefined;
          games_started: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          saves: number | undefined;
          holds: number | undefined;
          innings_pitched: string | undefined;
          hits_allowed: number | undefined;
          earned_runs_allowed: number | undefined;
          home_runs_allowed: number | undefined;
          walks_allowed: number | undefined;
          strikeouts_def: number | undefined;
          strikeouts_per_9_innings: number | undefined;
          walks_hits_per_inning_pitched: number | undefined;
          earned_run_average: number | undefined;
          total_chances: number | undefined;
          put_outs: number | undefined;
          assists: number | undefined;
          fielding_percentage: string | undefined;
          fielding_errors: number | undefined;
          double_plays: number | undefined;
          pickoffs: number | undefined;
          runners_caught_stealing: number | undefined;
          stolen_base_attempts: number | undefined;
          caught_stealing_percentage: number | undefined;
          pitch_count: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          games_started: number | undefined;
          points_per_game: number | undefined;
          offensive_rebounds_per_game: number | undefined;
          defensive_rebounds_per_game: number | undefined;
          rebounds_per_game: number | undefined;
          assists_per_game: number | undefined;
          steals_per_game: number | undefined;
          blocks_per_game: number | undefined;
          turnovers_per_game: number | undefined;
          fouls_per_game: number | undefined;
          assist_to_turnover_ratio: number | undefined;
          points: number | undefined;
          two_points_made: number | undefined;
          two_points_attempted: number | undefined;
          three_points_made: number | undefined;
          three_points_attempted: number | undefined;
          free_throws_made: number | undefined;
          free_throws_attempted: number | undefined;
          offensive_rebounds: number | undefined;
          defensive_rebounds: number | undefined;
          rebounds: number | undefined;
          assists: number | undefined;
          steals: number | undefined;
          blocks: number | undefined;
          turnovers: number | undefined;
          personal_fouls: number | undefined;
          field_goals_made_per_game: number | undefined;
          field_goals_attempted_per_game: number | undefined;
          field_goal_percentage: number | undefined;
          three_points_field_goals_made_per_game: number | undefined;
          three_points_field_goals_attempted_per_game: number | undefined;
          three_points_field_goals_percentage: number | undefined;
          two_points_field_goals_made_per_game: number | undefined;
          two_points_field_goals_attempted_per_game: number | undefined;
          two_points_field_goals_percentage: number | undefined;
          free_throws_made_per_game: number | undefined;
          free_throws_attempted_per_game: number | undefined;
          free_throw_percentage: number | undefined;
          effective_field_goal_percentage: number | undefined;
        }
      | {
          games_played: number | undefined;
          sets_played: number | undefined;
          kills: number | undefined;
          kills_per_set: number | undefined;
          attack_errors: number | undefined;
          total_attack_attempts: number | undefined;
          attacking_percentage: number | undefined;
          assists: number | undefined;
          assists_per_set: number | undefined;
          service_aces: number | undefined;
          service_errors: number | undefined;
          service_aces_per_set: number | undefined;
          receiving_errors: number | undefined;
          digs: number | undefined;
          digs_per_set: number | undefined;
          solo_blocks: number | undefined;
          block_assists: number | undefined;
          total_blocks: number | undefined;
          blocks_per_set: number | undefined;
          blocking_errors: number | undefined;
          ball_handling_errors: number | undefined;
          attack_errors_per_set: number | undefined;
          service_errors_per_set: number | undefined;
          solo_blocks_per_set: number | undefined;
          block_assists_per_set: number | undefined;
          total_blocks_per_set: number | undefined;
          blocking_errors_per_set: number | undefined;
          ball_handling_errors_per_set: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          shots: number | undefined;
          shooting_percentage: number | undefined;
          shots_on_goal: number | undefined;
          shot_on_goal_percentage: number | undefined;
          defensive_save: number | undefined;
          penalty_corners: number | undefined;
          fouls: number | undefined;
          minutes_played: number | undefined;
          goals_against: number | undefined;
          goals_against_per_game: number | undefined;
          goalie_saves: number | undefined;
          save_percentage: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          ties: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          passing_completions: number | undefined;
          passing_attempts: number | undefined;
          completion_percentage: number | undefined;
          passing_yards: number | undefined;
          yards_per_attempt: number | undefined;
          yards_per_game: number | undefined;
          passing_sacks: number | undefined;
          passing_touchdowns: number | undefined;
          passing_interceptions: number | undefined;
          rushing_attempts: number | undefined;
          rushing_yards: number | undefined;
          rushing_yards_per_attempt: number | undefined;
          rushing_yards_per_game: number | undefined;
          rushing_longest: number | undefined;
          rushing_touchdowns: number | undefined;
          rushing_fumbles: number | undefined;
          receiving_receptions: number | undefined;
          targets: number | undefined;
          receiving_yards: number | undefined;
          yards_per_reception: string | undefined;
          receiving_longest: number | undefined;
          receiving_touchdowns: number | undefined;
          receiving_fumbles: number | undefined;
          solo_tackles: number | undefined;
          assisted_tackles: number | undefined;
          total_tackles: number | undefined;
          tackles_for_loss: number | undefined;
          defensive_sacks: number | undefined;
          yards_lost: number | undefined;
          hurries: number | undefined;
          qb_hits: number | undefined;
          defensive_passes_defended: number | undefined;
          defensive_interceptions: number | undefined;
          defensive_interception_yards: number | undefined;
          defensive_fumbles_forced: number | undefined;
          defensive_fumbles_recovered: number | undefined;
          defensive_blocked_punts: number | undefined;
          defensive_blocked_field_goals: number | undefined;
          defensive_touchdown: number | undefined;
          field_goals_made: number | undefined;
          field_goals_attempts: number | undefined;
          field_goal_percentage: number | undefined;
          field_goals_longest: number | undefined;
          pat_made: number | undefined;
          pat_attempts: number | undefined;
          extra_point_percentage: number | undefined;
          points: number | undefined;
          punts: number | undefined;
          punt_yards: number | undefined;
          average_punt_yards: number | undefined;
          punt_long: number | undefined;
          punts_inside_20_yard_line: number | undefined;
          total_kick_returns: number | undefined;
          kickoff_return_yards: number | undefined;
          yards_per_kick_return: number | undefined;
          kick_return_long: number | undefined;
          kick_return_touchdowns: number | undefined;
          total_punt_returns: number | undefined;
          punt_return_yards: number | undefined;
          yards_per_punt_return: number | undefined;
          punt_return_long: number | undefined;
          punt_return_touchdowns: number | undefined;
        }
      | {
          games_played: number | undefined;
          passing_completions: number | undefined;
          passing_attempts: number | undefined;
          completion_percentage: number | undefined;
          passing_yards: number | undefined;
          yards_per_attempt: number | undefined;
          yards_per_game: number | undefined;
          passing_sacks: number | undefined;
          passing_touchdowns: number | undefined;
          passing_interceptions: number | undefined;
          rushing_attempts: number | undefined;
          rushing_yards: number | undefined;
          rushing_yards_per_attempt: number | undefined;
          rushing_yards_per_game: number | undefined;
          rushing_longest: number | undefined;
          rushing_touchdowns: number | undefined;
          rushing_fumbles: number | undefined;
          receiving_receptions: number | undefined;
          targets: number | undefined;
          receiving_yards: number | undefined;
          yards_per_reception: string | undefined;
          receiving_longest: number | undefined;
          receiving_touchdowns: number | undefined;
          receiving_fumbles: number | undefined;
          solo_tackles: number | undefined;
          assisted_tackles: number | undefined;
          total_tackles: number | undefined;
          tackles_for_loss: number | undefined;
          defensive_sacks: number | undefined;
          yards_lost: number | undefined;
          hurries: number | undefined;
          qb_hits: number | undefined;
          defensive_passes_defended: number | undefined;
          defensive_interceptions: number | undefined;
          defensive_interception_yards: number | undefined;
          defensive_fumbles_forced: number | undefined;
          defensive_fumbles_recovered: number | undefined;
          defensive_blocked_punts: number | undefined;
          defensive_blocked_field_goals: number | undefined;
          defensive_touchdown: number | undefined;
          field_goals_made: number | undefined;
          field_goals_attempts: number | undefined;
          field_goal_percentage: number | undefined;
          field_goals_longest: number | undefined;
          pat_made: number | undefined;
          pat_attempts: number | undefined;
          extra_point_percentage: number | undefined;
          points: number | undefined;
          punts: number | undefined;
          punt_yards: number | undefined;
          average_punt_yards: number | undefined;
          punt_long: number | undefined;
          punts_inside_20_yard_line: number | undefined;
          total_kick_returns: number | undefined;
          kickoff_return_yards: number | undefined;
          yards_per_kick_return: number | undefined;
          kick_return_long: number | undefined;
          kick_return_touchdowns: number | undefined;
          total_punt_returns: number | undefined;
          punt_return_yards: number | undefined;
          yards_per_punt_return: number | undefined;
          punt_return_long: number | undefined;
          punt_return_touchdowns: number | undefined;
        }
      | {
          events_played: number | undefined;
          rounds_played: number | undefined;
          total_strokes: number | undefined;
          average_score: number | undefined;
          vs_par: number | undefined;
          vs_par_average: number | undefined;
          wins: number | undefined;
          top_five_finishes: number | undefined;
          top_ten_finishes: number | undefined;
          top_twenty_finishes: number | undefined;
          best_finish: number | undefined;
          average_finish: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          time_on_ice: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          penalties_in_minutes: number | undefined;
          time_on_ice_per_game: number | undefined;
          power_play_goals: number | undefined;
          power_play_assists: number | undefined;
          short_hand_goals: number | undefined;
          short_hand_assists: number | undefined;
          shots: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          overtime_losses: number | undefined;
          goals_against_per_game: number | undefined;
          saves: number | undefined;
          save_percentage: number | undefined;
          goals_against: number | undefined;
          shots_against: number | undefined;
          shootout_shorts_against: number | undefined;
          shootout_saves: number | undefined;
          shootout_save_percentage: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          games_started: number | undefined;
          minutes_played: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          shots: number | undefined;
          shots_on_goal: number | undefined;
          ground_balls: number | undefined;
          faceoffs_won: number | undefined;
          total_faceoffs: number | undefined;
          turnovers: number | undefined;
          clears: number | undefined;
          penalties: number | undefined;
          minutes_in_goal: number | undefined;
          goals_against: number | undefined;
          average_goals_against: number | undefined;
          saves: number | undefined;
          save_percentage: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          ties: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          games_started: number | undefined;
          minutes_played: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          shots: number | undefined;
          shot_percentage: number | undefined;
          shots_on_goal: number | undefined;
          shots_on_goal_percentage: number | undefined;
          penalty_kick_attempts: number | undefined;
          penalty_kick_goals: number | undefined;
          penalty_kick_percentage: number | undefined;
          goals_against: number | undefined;
          saves: number | undefined;
          save_percentage: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          draws: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          at_bats: number | undefined;
          runs: number | undefined;
          hits: number | undefined;
          singles: number | undefined;
          doubles: number | undefined;
          triples: number | undefined;
          home_runs: number | undefined;
          runs_batted_in: number | undefined;
          total_bases: number | undefined;
          total_walks: number | undefined;
          strikeouts_off: number | undefined;
          stolen_bases: number | undefined;
          batting_average: string | undefined;
          on_base_percentage: string | undefined;
          slugging_percentage: string | undefined;
          on_base_plus_slugging: string | undefined;
          games_started: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          saves: number | undefined;
          holds: number | undefined;
          innings_pitched: string | undefined;
          hits_allowed: number | undefined;
          earned_runs_allowed: number | undefined;
          home_runs_allowed: number | undefined;
          walks_allowed: number | undefined;
          strikeouts_def: number | undefined;
          strikeouts_per_9_innings: number | undefined;
          walks_hits_per_inning_pitched: number | undefined;
          earned_run_average: number | undefined;
          total_chances: number | undefined;
          put_outs: number | undefined;
          assists: number | undefined;
          fielding_percentage: string | undefined;
          fielding_errors: number | undefined;
          double_plays: number | undefined;
          pickoffs: number | undefined;
          runners_caught_stealing: number | undefined;
          points: number | undefined;
        }
      | { points: number | undefined }
      | {
          games_played: number | undefined;
          sets_played: number | undefined;
          service_attempts: number | undefined;
          service_points_won: number | undefined;
          service_aces: number | undefined;
          service_faults: number | undefined;
          double_faults: number | undefined;
          points: number | undefined;
          returns_attempted: number | undefined;
          return_points_won: number | undefined;
          break_point_attempts: number | undefined;
          break_points_won: number | undefined;
          ejections: number | undefined;
        }
      | { points: number | undefined }
      | { points: number | undefined }
      | {
          games_played: number | undefined;
          sets_played: number | undefined;
          kills: number | undefined;
          kills_per_set: number | undefined;
          attack_errors: number | undefined;
          total_attack_attempts: number | undefined;
          attacking_percentage: number | undefined;
          assists: number | undefined;
          assists_per_set: number | undefined;
          service_aces: number | undefined;
          service_errors: number | undefined;
          service_aces_per_set: number | undefined;
          receiving_errors: number | undefined;
          digs: number | undefined;
          digs_per_set: number | undefined;
          solo_blocks: number | undefined;
          block_assists: number | undefined;
          total_blocks: number | undefined;
          blocks_per_set: number | undefined;
          blocking_errors: number | undefined;
          ball_handling_errors: number | undefined;
          attack_errors_per_set: number | undefined;
          service_errors_per_set: number | undefined;
          solo_blocks_per_set: number | undefined;
          block_assists_per_set: number | undefined;
          total_blocks_per_set: number | undefined;
          blocking_errors_per_set: number | undefined;
          ball_handling_errors_per_set: number | undefined;
          points: number | undefined;
        }
      | {
          games_played: number | undefined;
          shots: number | undefined;
          goals: number | undefined;
          assists: number | undefined;
          total_points: number | undefined;
          shooting_percentage: number | undefined;
          steals: number | undefined;
          sprints_won: number | undefined;
          sprints_attempted: number | undefined;
          games_started: number | undefined;
          minutes_in_goal: number | undefined;
          wins: number | undefined;
          losses: number | undefined;
          shots_against: number | undefined;
          saves: number | undefined;
          goals_against: number | undefined;
          save_percentage: number | undefined;
          average_goals_against: number | undefined;
          points: number | undefined;
        };
    level: { name: string };
    standing: { overallRecord: string; leagueRecord: string };
    school: { id: string; webPath: string } | undefined;
    otherTeams: Array<{ webPath: string; level: { name: string } }>;
    owner: { id: string; username: string } | undefined;
  };
};

type TeamsStatsQueryStatFieldsBaseballStatFragment = {
  games_played: number | undefined;
  at_bats: number | undefined;
  runs: number | undefined;
  hits: number | undefined;
  singles: number | undefined;
  doubles: number | undefined;
  triples: number | undefined;
  home_runs: number | undefined;
  runs_batted_in: number | undefined;
  total_bases: number | undefined;
  total_walks: number | undefined;
  strikeouts_off: number | undefined;
  stolen_bases: number | undefined;
  batting_average: string | undefined;
  on_base_percentage: string | undefined;
  slugging_percentage: string | undefined;
  on_base_plus_slugging: string | undefined;
  games_started: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  saves: number | undefined;
  holds: number | undefined;
  innings_pitched: string | undefined;
  hits_allowed: number | undefined;
  earned_runs_allowed: number | undefined;
  home_runs_allowed: number | undefined;
  walks_allowed: number | undefined;
  strikeouts_def: number | undefined;
  strikeouts_per_9_innings: number | undefined;
  walks_hits_per_inning_pitched: number | undefined;
  earned_run_average: number | undefined;
  total_chances: number | undefined;
  put_outs: number | undefined;
  assists: number | undefined;
  fielding_percentage: string | undefined;
  fielding_errors: number | undefined;
  double_plays: number | undefined;
  pickoffs: number | undefined;
  runners_caught_stealing: number | undefined;
  stolen_base_attempts: number | undefined;
  caught_stealing_percentage: number | undefined;
  pitch_count: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsBasketballStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  points_per_game: number | undefined;
  offensive_rebounds_per_game: number | undefined;
  defensive_rebounds_per_game: number | undefined;
  rebounds_per_game: number | undefined;
  assists_per_game: number | undefined;
  steals_per_game: number | undefined;
  blocks_per_game: number | undefined;
  turnovers_per_game: number | undefined;
  fouls_per_game: number | undefined;
  assist_to_turnover_ratio: number | undefined;
  points: number | undefined;
  two_points_made: number | undefined;
  two_points_attempted: number | undefined;
  three_points_made: number | undefined;
  three_points_attempted: number | undefined;
  free_throws_made: number | undefined;
  free_throws_attempted: number | undefined;
  offensive_rebounds: number | undefined;
  defensive_rebounds: number | undefined;
  rebounds: number | undefined;
  assists: number | undefined;
  steals: number | undefined;
  blocks: number | undefined;
  turnovers: number | undefined;
  personal_fouls: number | undefined;
  field_goals_made_per_game: number | undefined;
  field_goals_attempted_per_game: number | undefined;
  field_goal_percentage: number | undefined;
  three_points_field_goals_made_per_game: number | undefined;
  three_points_field_goals_attempted_per_game: number | undefined;
  three_points_field_goals_percentage: number | undefined;
  two_points_field_goals_made_per_game: number | undefined;
  two_points_field_goals_attempted_per_game: number | undefined;
  two_points_field_goals_percentage: number | undefined;
  free_throws_made_per_game: number | undefined;
  free_throws_attempted_per_game: number | undefined;
  free_throw_percentage: number | undefined;
  effective_field_goal_percentage: number | undefined;
};

type TeamsStatsQueryStatFieldsBeachVolleyballStatFragment = {
  games_played: number | undefined;
  sets_played: number | undefined;
  kills: number | undefined;
  kills_per_set: number | undefined;
  attack_errors: number | undefined;
  total_attack_attempts: number | undefined;
  attacking_percentage: number | undefined;
  assists: number | undefined;
  assists_per_set: number | undefined;
  service_aces: number | undefined;
  service_errors: number | undefined;
  service_aces_per_set: number | undefined;
  receiving_errors: number | undefined;
  digs: number | undefined;
  digs_per_set: number | undefined;
  solo_blocks: number | undefined;
  block_assists: number | undefined;
  total_blocks: number | undefined;
  blocks_per_set: number | undefined;
  blocking_errors: number | undefined;
  ball_handling_errors: number | undefined;
  attack_errors_per_set: number | undefined;
  service_errors_per_set: number | undefined;
  solo_blocks_per_set: number | undefined;
  block_assists_per_set: number | undefined;
  total_blocks_per_set: number | undefined;
  blocking_errors_per_set: number | undefined;
  ball_handling_errors_per_set: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsFieldHockeyStatFragment = {
  games_played: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  shots: number | undefined;
  shooting_percentage: number | undefined;
  shots_on_goal: number | undefined;
  shot_on_goal_percentage: number | undefined;
  defensive_save: number | undefined;
  penalty_corners: number | undefined;
  fouls: number | undefined;
  minutes_played: number | undefined;
  goals_against: number | undefined;
  goals_against_per_game: number | undefined;
  goalie_saves: number | undefined;
  save_percentage: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  ties: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsFlagFootballStatFragment = {
  games_played: number | undefined;
  passing_completions: number | undefined;
  passing_attempts: number | undefined;
  completion_percentage: number | undefined;
  passing_yards: number | undefined;
  yards_per_attempt: number | undefined;
  yards_per_game: number | undefined;
  passing_sacks: number | undefined;
  passing_touchdowns: number | undefined;
  passing_interceptions: number | undefined;
  rushing_attempts: number | undefined;
  rushing_yards: number | undefined;
  rushing_yards_per_attempt: number | undefined;
  rushing_yards_per_game: number | undefined;
  rushing_longest: number | undefined;
  rushing_touchdowns: number | undefined;
  rushing_fumbles: number | undefined;
  receiving_receptions: number | undefined;
  targets: number | undefined;
  receiving_yards: number | undefined;
  yards_per_reception: string | undefined;
  receiving_longest: number | undefined;
  receiving_touchdowns: number | undefined;
  receiving_fumbles: number | undefined;
  solo_tackles: number | undefined;
  assisted_tackles: number | undefined;
  total_tackles: number | undefined;
  tackles_for_loss: number | undefined;
  defensive_sacks: number | undefined;
  yards_lost: number | undefined;
  hurries: number | undefined;
  qb_hits: number | undefined;
  defensive_passes_defended: number | undefined;
  defensive_interceptions: number | undefined;
  defensive_interception_yards: number | undefined;
  defensive_fumbles_forced: number | undefined;
  defensive_fumbles_recovered: number | undefined;
  defensive_blocked_punts: number | undefined;
  defensive_blocked_field_goals: number | undefined;
  defensive_touchdown: number | undefined;
  field_goals_made: number | undefined;
  field_goals_attempts: number | undefined;
  field_goal_percentage: number | undefined;
  field_goals_longest: number | undefined;
  pat_made: number | undefined;
  pat_attempts: number | undefined;
  extra_point_percentage: number | undefined;
  points: number | undefined;
  punts: number | undefined;
  punt_yards: number | undefined;
  average_punt_yards: number | undefined;
  punt_long: number | undefined;
  punts_inside_20_yard_line: number | undefined;
  total_kick_returns: number | undefined;
  kickoff_return_yards: number | undefined;
  yards_per_kick_return: number | undefined;
  kick_return_long: number | undefined;
  kick_return_touchdowns: number | undefined;
  total_punt_returns: number | undefined;
  punt_return_yards: number | undefined;
  yards_per_punt_return: number | undefined;
  punt_return_long: number | undefined;
  punt_return_touchdowns: number | undefined;
};

type TeamsStatsQueryStatFieldsFootballStatFragment = {
  games_played: number | undefined;
  passing_completions: number | undefined;
  passing_attempts: number | undefined;
  completion_percentage: number | undefined;
  passing_yards: number | undefined;
  yards_per_attempt: number | undefined;
  yards_per_game: number | undefined;
  passing_sacks: number | undefined;
  passing_touchdowns: number | undefined;
  passing_interceptions: number | undefined;
  rushing_attempts: number | undefined;
  rushing_yards: number | undefined;
  rushing_yards_per_attempt: number | undefined;
  rushing_yards_per_game: number | undefined;
  rushing_longest: number | undefined;
  rushing_touchdowns: number | undefined;
  rushing_fumbles: number | undefined;
  receiving_receptions: number | undefined;
  targets: number | undefined;
  receiving_yards: number | undefined;
  yards_per_reception: string | undefined;
  receiving_longest: number | undefined;
  receiving_touchdowns: number | undefined;
  receiving_fumbles: number | undefined;
  solo_tackles: number | undefined;
  assisted_tackles: number | undefined;
  total_tackles: number | undefined;
  tackles_for_loss: number | undefined;
  defensive_sacks: number | undefined;
  yards_lost: number | undefined;
  hurries: number | undefined;
  qb_hits: number | undefined;
  defensive_passes_defended: number | undefined;
  defensive_interceptions: number | undefined;
  defensive_interception_yards: number | undefined;
  defensive_fumbles_forced: number | undefined;
  defensive_fumbles_recovered: number | undefined;
  defensive_blocked_punts: number | undefined;
  defensive_blocked_field_goals: number | undefined;
  defensive_touchdown: number | undefined;
  field_goals_made: number | undefined;
  field_goals_attempts: number | undefined;
  field_goal_percentage: number | undefined;
  field_goals_longest: number | undefined;
  pat_made: number | undefined;
  pat_attempts: number | undefined;
  extra_point_percentage: number | undefined;
  points: number | undefined;
  punts: number | undefined;
  punt_yards: number | undefined;
  average_punt_yards: number | undefined;
  punt_long: number | undefined;
  punts_inside_20_yard_line: number | undefined;
  total_kick_returns: number | undefined;
  kickoff_return_yards: number | undefined;
  yards_per_kick_return: number | undefined;
  kick_return_long: number | undefined;
  kick_return_touchdowns: number | undefined;
  total_punt_returns: number | undefined;
  punt_return_yards: number | undefined;
  yards_per_punt_return: number | undefined;
  punt_return_long: number | undefined;
  punt_return_touchdowns: number | undefined;
};

type TeamsStatsQueryStatFieldsGolfStatFragment = {
  events_played: number | undefined;
  rounds_played: number | undefined;
  total_strokes: number | undefined;
  average_score: number | undefined;
  vs_par: number | undefined;
  vs_par_average: number | undefined;
  wins: number | undefined;
  top_five_finishes: number | undefined;
  top_ten_finishes: number | undefined;
  top_twenty_finishes: number | undefined;
  best_finish: number | undefined;
  average_finish: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsIceHockeyStatFragment = {
  games_played: number | undefined;
  time_on_ice: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  penalties_in_minutes: number | undefined;
  time_on_ice_per_game: number | undefined;
  power_play_goals: number | undefined;
  power_play_assists: number | undefined;
  short_hand_goals: number | undefined;
  short_hand_assists: number | undefined;
  shots: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  overtime_losses: number | undefined;
  goals_against_per_game: number | undefined;
  saves: number | undefined;
  save_percentage: number | undefined;
  goals_against: number | undefined;
  shots_against: number | undefined;
  shootout_shorts_against: number | undefined;
  shootout_saves: number | undefined;
  shootout_save_percentage: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsLacrosseStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  minutes_played: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  shots: number | undefined;
  shots_on_goal: number | undefined;
  ground_balls: number | undefined;
  faceoffs_won: number | undefined;
  total_faceoffs: number | undefined;
  turnovers: number | undefined;
  clears: number | undefined;
  penalties: number | undefined;
  minutes_in_goal: number | undefined;
  goals_against: number | undefined;
  average_goals_against: number | undefined;
  saves: number | undefined;
  save_percentage: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  ties: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsSoccerStatFragment = {
  games_played: number | undefined;
  games_started: number | undefined;
  minutes_played: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  shots: number | undefined;
  shot_percentage: number | undefined;
  shots_on_goal: number | undefined;
  shots_on_goal_percentage: number | undefined;
  penalty_kick_attempts: number | undefined;
  penalty_kick_goals: number | undefined;
  penalty_kick_percentage: number | undefined;
  goals_against: number | undefined;
  saves: number | undefined;
  save_percentage: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  draws: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsSoftballStatFragment = {
  games_played: number | undefined;
  at_bats: number | undefined;
  runs: number | undefined;
  hits: number | undefined;
  singles: number | undefined;
  doubles: number | undefined;
  triples: number | undefined;
  home_runs: number | undefined;
  runs_batted_in: number | undefined;
  total_bases: number | undefined;
  total_walks: number | undefined;
  strikeouts_off: number | undefined;
  stolen_bases: number | undefined;
  batting_average: string | undefined;
  on_base_percentage: string | undefined;
  slugging_percentage: string | undefined;
  on_base_plus_slugging: string | undefined;
  games_started: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  saves: number | undefined;
  holds: number | undefined;
  innings_pitched: string | undefined;
  hits_allowed: number | undefined;
  earned_runs_allowed: number | undefined;
  home_runs_allowed: number | undefined;
  walks_allowed: number | undefined;
  strikeouts_def: number | undefined;
  strikeouts_per_9_innings: number | undefined;
  walks_hits_per_inning_pitched: number | undefined;
  earned_run_average: number | undefined;
  total_chances: number | undefined;
  put_outs: number | undefined;
  assists: number | undefined;
  fielding_percentage: string | undefined;
  fielding_errors: number | undefined;
  double_plays: number | undefined;
  pickoffs: number | undefined;
  runners_caught_stealing: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsStatFragment = { points: number | undefined };

type TeamsStatsQueryStatFieldsTennisStatFragment = {
  games_played: number | undefined;
  sets_played: number | undefined;
  service_attempts: number | undefined;
  service_points_won: number | undefined;
  service_aces: number | undefined;
  service_faults: number | undefined;
  double_faults: number | undefined;
  points: number | undefined;
  returns_attempted: number | undefined;
  return_points_won: number | undefined;
  break_point_attempts: number | undefined;
  break_points_won: number | undefined;
  ejections: number | undefined;
};

type TeamsStatsQueryStatFieldsUltimateFrisbeeStatFragment = { points: number | undefined };

type TeamsStatsQueryStatFieldsUnifiedBasketballStatFragment = { points: number | undefined };

type TeamsStatsQueryStatFieldsVolleyballStatFragment = {
  games_played: number | undefined;
  sets_played: number | undefined;
  kills: number | undefined;
  kills_per_set: number | undefined;
  attack_errors: number | undefined;
  total_attack_attempts: number | undefined;
  attacking_percentage: number | undefined;
  assists: number | undefined;
  assists_per_set: number | undefined;
  service_aces: number | undefined;
  service_errors: number | undefined;
  service_aces_per_set: number | undefined;
  receiving_errors: number | undefined;
  digs: number | undefined;
  digs_per_set: number | undefined;
  solo_blocks: number | undefined;
  block_assists: number | undefined;
  total_blocks: number | undefined;
  blocks_per_set: number | undefined;
  blocking_errors: number | undefined;
  ball_handling_errors: number | undefined;
  attack_errors_per_set: number | undefined;
  service_errors_per_set: number | undefined;
  solo_blocks_per_set: number | undefined;
  block_assists_per_set: number | undefined;
  total_blocks_per_set: number | undefined;
  blocking_errors_per_set: number | undefined;
  ball_handling_errors_per_set: number | undefined;
  points: number | undefined;
};

type TeamsStatsQueryStatFieldsWaterPoloStatFragment = {
  games_played: number | undefined;
  shots: number | undefined;
  goals: number | undefined;
  assists: number | undefined;
  total_points: number | undefined;
  shooting_percentage: number | undefined;
  steals: number | undefined;
  sprints_won: number | undefined;
  sprints_attempted: number | undefined;
  games_started: number | undefined;
  minutes_in_goal: number | undefined;
  wins: number | undefined;
  losses: number | undefined;
  shots_against: number | undefined;
  saves: number | undefined;
  goals_against: number | undefined;
  save_percentage: number | undefined;
  average_goals_against: number | undefined;
  points: number | undefined;
};

export type TeamsStatsQueryStatFieldsFragment =
  | TeamsStatsQueryStatFieldsBaseballStatFragment
  | TeamsStatsQueryStatFieldsBasketballStatFragment
  | TeamsStatsQueryStatFieldsBeachVolleyballStatFragment
  | TeamsStatsQueryStatFieldsFieldHockeyStatFragment
  | TeamsStatsQueryStatFieldsFlagFootballStatFragment
  | TeamsStatsQueryStatFieldsFootballStatFragment
  | TeamsStatsQueryStatFieldsGolfStatFragment
  | TeamsStatsQueryStatFieldsIceHockeyStatFragment
  | TeamsStatsQueryStatFieldsLacrosseStatFragment
  | TeamsStatsQueryStatFieldsSoccerStatFragment
  | TeamsStatsQueryStatFieldsSoftballStatFragment
  | TeamsStatsQueryStatFieldsStatFragment
  | TeamsStatsQueryStatFieldsTennisStatFragment
  | TeamsStatsQueryStatFieldsUltimateFrisbeeStatFragment
  | TeamsStatsQueryStatFieldsUnifiedBasketballStatFragment
  | TeamsStatsQueryStatFieldsVolleyballStatFragment
  | TeamsStatsQueryStatFieldsWaterPoloStatFragment;

export type UserInvitationQueryVariables = Exact<{
  inviteToken: Scalars['String']['input'];
}>;

export type UserInvitationQuery = {
  userEntityInvitation: {
    invitationStatus: string;
    entities: Array<{
      id: string;
      invitationStatus: string;
      entity:
        | { __typename: 'Bracket'; id: string; divisionText: string; logoImage: string | undefined; name: string; webPath: string }
        | { __typename: 'Group' }
        | { __typename: 'School'; id: string; image: string | undefined; name: string; webPath: string }
        | {
            __typename: 'Team';
            id: string;
            image: string | undefined;
            fullName: string;
            divisionText: string;
            webPath: string;
            genderSport: { slug: string };
          };
    }>;
  };
};

export type WidgetsGameScoreShowQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type WidgetsGameScoreShowQuery = {
  game:
    | {
        id: string;
        date: string;
        longStatusText: string;
        statusId: number;
        titleText: string;
        webPath: string;
        webPhotosPath: string | undefined;
        gameTeams: Array<{
          id: string;
          isLoser: boolean;
          isTbd: boolean;
          isWinner: boolean;
          scoreText: string | undefined;
          pregameStanding: { overallRecord: string } | undefined;
          team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
        }>;
        contest:
          | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
          | undefined;
      }
    | {
        id: string;
        date: string;
        longStatusText: string;
        statusId: number;
        titleText: string;
        webPath: string;
        webPhotosPath: string | undefined;
        gameTeams: Array<{
          id: string;
          isLoser: boolean;
          isTbd: boolean;
          isWinner: boolean;
          scoreText: string | undefined;
          pregameStanding: { overallRecord: string } | undefined;
          team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
        }>;
        contest:
          | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
          | undefined;
      }
    | {
        id: string;
        date: string;
        longStatusText: string;
        statusId: number;
        titleText: string;
        webPath: string;
        webPhotosPath: string | undefined;
        gameTeams: Array<{
          id: string;
          isLoser: boolean;
          isTbd: boolean;
          isWinner: boolean;
          scoreText: string | undefined;
          pregameStanding: { overallRecord: string } | undefined;
          team: { image: string | undefined; name: string; state: { abbrev: string } | undefined };
        }>;
        contest:
          | { hometownTicketingUrl: string | undefined; gofanTicketingUrl: string | undefined; nfhsNetworkUrl: string | undefined }
          | undefined;
      };
};

export type WidgetsLeadersQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  group_id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type WidgetsLeadersQuery = {
  widget:
    | {
        state: string | undefined;
        group:
          | {
              id: string;
              gender_id: number;
              gender_sport: string | undefined;
              sport: { id: number; name: string } | undefined;
              group_season:
                | {
                    season: { name: string };
                    teamPlayerLeaders:
                      | Array<{
                          stat:
                            | { games_played: number | undefined; points: number | undefined }
                            | {
                                three_points_made: number | undefined;
                                free_throws_made: number | undefined;
                                free_throws_attempted: number | undefined;
                                free_throw_percentage: number | undefined;
                                games_played: number | undefined;
                                points: number | undefined;
                              }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined }
                            | { games_played: number | undefined; points: number | undefined };
                          teamPlayer: {
                            jersey_number: string | undefined;
                            web_path: string | undefined;
                            team: { full_name: string };
                            player: { id: string; name: string | undefined; image: string | undefined };
                          };
                        }>
                      | undefined;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type WidgetsV2GamesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  group_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
}>;

export type WidgetsV2GamesQuery = {
  widget: { group: { id: string; name_text: string; gender_sport: { name: string } | undefined } | undefined } | undefined;
  games: {
    edges: Array<{
      node:
        | {
            id: string;
            long_status_text: string;
            web_path: string;
            away: {
              score: number;
              team: { name: string; mascot: string | undefined; image: string | undefined; short_division_text: string };
            };
            home: {
              score: number;
              team: { name: string; mascot: string | undefined; image: string | undefined; short_division_text: string };
            };
          }
        | {
            id: string;
            long_status_text: string;
            web_path: string;
            away: {
              score: number;
              team: { name: string; mascot: string | undefined; image: string | undefined; short_division_text: string };
            };
            home: {
              score: number;
              team: { name: string; mascot: string | undefined; image: string | undefined; short_division_text: string };
            };
          }
        | {
            id: string;
            long_status_text: string;
            web_path: string;
            away: {
              score: number;
              team: { name: string; mascot: string | undefined; image: string | undefined; short_division_text: string };
            };
            home: {
              score: number;
              team: { name: string; mascot: string | undefined; image: string | undefined; short_division_text: string };
            };
          };
    }>;
  };
};

export type WidgetsV2GamesQueryGameTeamFragment = {
  score: number;
  team: { name: string; mascot: string | undefined; image: string | undefined; short_division_text: string };
};

export type WidgetsV2LeadersQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  group_id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type WidgetsV2LeadersQuery = {
  widget:
    | {
        group:
          | {
              id: string;
              sport_id: number;
              name_text: string;
              gender_sport: { name: string } | undefined;
              group_season:
                | {
                    teamPlayerLeaders:
                      | Array<{
                          teamPlayer: {
                            web_path: string | undefined;
                            team: { image: string | undefined; name: string };
                            player: { name: string | undefined };
                            position: { name: string } | undefined;
                          };
                          stat:
                            | {
                                points: number | undefined;
                                points_per_game: number | undefined;
                                two_points_made: number | undefined;
                                three_points_made: number | undefined;
                                free_throws_made: number | undefined;
                                free_throws_attempted: number | undefined;
                                personal_fouls: number | undefined;
                              }
                            | {
                                rushing_attempts: number | undefined;
                                rushing_touchdowns: number | undefined;
                                rushing_yards: number | undefined;
                                passing_completions: number | undefined;
                                passing_attempts: number | undefined;
                                passing_yards: number | undefined;
                                receiving_receptions: number | undefined;
                                receiving_yards: number | undefined;
                              }
                            | {};
                        }>
                      | undefined;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type WidgetsV2StandingsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  group_id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type WidgetsV2StandingsQuery = {
  widget:
    | {
        group:
          | {
              name_text: string;
              gender_sport: { name: string } | undefined;
              group_season:
                | {
                    teamStandings: Array<{
                      team: { id: string; name: string; mascot: string | undefined; image: string | undefined; web_path: string };
                      standing: {
                        leagueRecord: string;
                        leagueGamesBack: string;
                        overallRecord: string;
                        overallWinPercentage: string;
                        homeRecord: string;
                        awayRecord: string;
                        streak: string;
                      };
                    }>;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type WidgetsV3QueryVariables = Exact<{
  id: Scalars['Int']['input'];
  date?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  group_id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type WidgetsV3Query = {
  widget:
    | {
        id: string;
        custom_ad: string | undefined;
        sbl_ad: boolean | undefined;
        groups: Array<{ id: string; name: string }> | undefined;
        group:
          | {
              id: string;
              games:
                | {
                    pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
                    edges: Array<{
                      node:
                        | {
                            id: string;
                            date: string;
                            venue: string | undefined;
                            location_text: string | undefined;
                            long_status_text: string;
                            status_id: number;
                            sport_id: number;
                            gender_sport: string | undefined;
                            web_path: string;
                            game_snapshot:
                              | {
                                  event_text: string | undefined;
                                  points: number | undefined;
                                  possession_game_team_id: string | undefined;
                                  game_player_image: string | undefined;
                                }
                              | undefined;
                            leaders:
                              | Array<{
                                  stat_line: string | undefined;
                                  game_player:
                                    | {
                                        id: string;
                                        image: string | undefined;
                                        first_name: string | undefined;
                                        last_name: string | undefined;
                                      }
                                    | undefined;
                                }>
                              | undefined;
                            away: {
                              id: string;
                              score: number;
                              team: {
                                id: string;
                                image: string | undefined;
                                name: string;
                                mascot: string | undefined;
                                short_division_text: string;
                              };
                            };
                            home: {
                              id: string;
                              score: number;
                              team: {
                                id: string;
                                image: string | undefined;
                                name: string;
                                mascot: string | undefined;
                                short_division_text: string;
                                state: { abbrev: string } | undefined;
                              };
                            };
                          }
                        | {
                            id: string;
                            date: string;
                            venue: string | undefined;
                            location_text: string | undefined;
                            long_status_text: string;
                            status_id: number;
                            sport_id: number;
                            gender_sport: string | undefined;
                            web_path: string;
                            game_snapshot:
                              | {
                                  possession_game_team_id: string | undefined;
                                  yardline: number | undefined;
                                  down: number | undefined;
                                  distance: number | undefined;
                                  to_go_text: string | undefined;
                                  last_event_text: string | undefined;
                                }
                              | undefined;
                            leaders:
                              | Array<{
                                  stat_line: string | undefined;
                                  game_player:
                                    | {
                                        id: string;
                                        image: string | undefined;
                                        first_name: string | undefined;
                                        last_name: string | undefined;
                                      }
                                    | undefined;
                                }>
                              | undefined;
                            away: {
                              id: string;
                              score: number;
                              team: {
                                id: string;
                                image: string | undefined;
                                name: string;
                                mascot: string | undefined;
                                short_division_text: string;
                              };
                            };
                            home: {
                              id: string;
                              score: number;
                              team: {
                                id: string;
                                image: string | undefined;
                                name: string;
                                mascot: string | undefined;
                                short_division_text: string;
                                state: { abbrev: string } | undefined;
                              };
                            };
                          }
                        | {
                            id: string;
                            date: string;
                            venue: string | undefined;
                            location_text: string | undefined;
                            long_status_text: string;
                            status_id: number;
                            sport_id: number;
                            gender_sport: string | undefined;
                            web_path: string;
                            leaders:
                              | Array<{
                                  stat_line: string | undefined;
                                  game_player:
                                    | {
                                        id: string;
                                        image: string | undefined;
                                        first_name: string | undefined;
                                        last_name: string | undefined;
                                      }
                                    | undefined;
                                }>
                              | undefined;
                            away: {
                              id: string;
                              score: number;
                              team: {
                                id: string;
                                image: string | undefined;
                                name: string;
                                mascot: string | undefined;
                                short_division_text: string;
                              };
                            };
                            home: {
                              id: string;
                              score: number;
                              team: {
                                id: string;
                                image: string | undefined;
                                name: string;
                                mascot: string | undefined;
                                short_division_text: string;
                                state: { abbrev: string } | undefined;
                              };
                            };
                          };
                    }>;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type WidgetsV3QueryGameTeamFieldsFragment = {
  id: string;
  score: number;
  team: { id: string; image: string | undefined; name: string; mascot: string | undefined; short_division_text: string };
};

export type WidgetsV4QueryVariables = Exact<{
  id: Scalars['Int']['input'];
  date?: InputMaybe<Scalars['String']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  level_id?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  initial_load: Scalars['Boolean']['input'];
}>;

export type WidgetsV4Query = {
  schoolWidget: {
    id: string;
    name: string;
    sbl_ad: boolean | undefined;
    source:
      | {
          __typename: 'School';
          id: string;
          gender_sports?: Array<{ gender_id: number; sport_id: number; name: string; slug: string }>;
          games: {
            pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
            edges: Array<{
              node:
                | {
                    id: string;
                    date: string;
                    venue: string | undefined;
                    location_text: string | undefined;
                    long_status_text: string;
                    status_id: number;
                    sport_id: number;
                    gender_id: number;
                    gender_sport: string | undefined;
                    web_path: string;
                    game_snapshot:
                      | {
                          event_text: string | undefined;
                          points: number | undefined;
                          possession_game_team_id: string | undefined;
                          game_player_image: string | undefined;
                          game_player_name: string | undefined;
                        }
                      | undefined;
                    leaders:
                      | Array<{
                          stat_line: string | undefined;
                          game_player:
                            | { id: string; image: string | undefined; first_name: string | undefined; last_name: string | undefined }
                            | undefined;
                        }>
                      | undefined;
                    away: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                      };
                    };
                    home: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                        state: { abbrev: string } | undefined;
                      };
                    };
                  }
                | {
                    id: string;
                    date: string;
                    venue: string | undefined;
                    location_text: string | undefined;
                    long_status_text: string;
                    status_id: number;
                    sport_id: number;
                    gender_id: number;
                    gender_sport: string | undefined;
                    web_path: string;
                    game_snapshot:
                      | {
                          possession_game_team_id: string | undefined;
                          yardline: number | undefined;
                          down: number | undefined;
                          distance: number | undefined;
                          to_go_text: string | undefined;
                          last_event_text: string | undefined;
                          game_player_image: string | undefined;
                        }
                      | undefined;
                    leaders:
                      | Array<{
                          stat_line: string | undefined;
                          game_player:
                            | { id: string; image: string | undefined; first_name: string | undefined; last_name: string | undefined }
                            | undefined;
                        }>
                      | undefined;
                    away: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                      };
                    };
                    home: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                        state: { abbrev: string } | undefined;
                      };
                    };
                  }
                | {
                    id: string;
                    date: string;
                    venue: string | undefined;
                    location_text: string | undefined;
                    long_status_text: string;
                    status_id: number;
                    sport_id: number;
                    gender_id: number;
                    gender_sport: string | undefined;
                    web_path: string;
                    leaders:
                      | Array<{
                          stat_line: string | undefined;
                          game_player:
                            | { id: string; image: string | undefined; first_name: string | undefined; last_name: string | undefined }
                            | undefined;
                        }>
                      | undefined;
                    away: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                      };
                    };
                    home: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                        state: { abbrev: string } | undefined;
                      };
                    };
                  };
            }>;
          };
        }
      | {
          __typename: 'SchoolGroup';
          id: string;
          gender_sports?: Array<{ gender_id: number; sport_id: number; name: string; slug: string }>;
          games: {
            pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
            edges: Array<{
              node:
                | {
                    id: string;
                    date: string;
                    venue: string | undefined;
                    location_text: string | undefined;
                    long_status_text: string;
                    status_id: number;
                    sport_id: number;
                    gender_id: number;
                    gender_sport: string | undefined;
                    web_path: string;
                    game_snapshot:
                      | {
                          event_text: string | undefined;
                          points: number | undefined;
                          possession_game_team_id: string | undefined;
                          game_player_image: string | undefined;
                          game_player_name: string | undefined;
                        }
                      | undefined;
                    leaders:
                      | Array<{
                          stat_line: string | undefined;
                          game_player:
                            | { id: string; image: string | undefined; first_name: string | undefined; last_name: string | undefined }
                            | undefined;
                        }>
                      | undefined;
                    away: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                      };
                    };
                    home: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                        state: { abbrev: string } | undefined;
                      };
                    };
                  }
                | {
                    id: string;
                    date: string;
                    venue: string | undefined;
                    location_text: string | undefined;
                    long_status_text: string;
                    status_id: number;
                    sport_id: number;
                    gender_id: number;
                    gender_sport: string | undefined;
                    web_path: string;
                    game_snapshot:
                      | {
                          possession_game_team_id: string | undefined;
                          yardline: number | undefined;
                          down: number | undefined;
                          distance: number | undefined;
                          to_go_text: string | undefined;
                          last_event_text: string | undefined;
                          game_player_image: string | undefined;
                        }
                      | undefined;
                    leaders:
                      | Array<{
                          stat_line: string | undefined;
                          game_player:
                            | { id: string; image: string | undefined; first_name: string | undefined; last_name: string | undefined }
                            | undefined;
                        }>
                      | undefined;
                    away: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                      };
                    };
                    home: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                        state: { abbrev: string } | undefined;
                      };
                    };
                  }
                | {
                    id: string;
                    date: string;
                    venue: string | undefined;
                    location_text: string | undefined;
                    long_status_text: string;
                    status_id: number;
                    sport_id: number;
                    gender_id: number;
                    gender_sport: string | undefined;
                    web_path: string;
                    leaders:
                      | Array<{
                          stat_line: string | undefined;
                          game_player:
                            | { id: string; image: string | undefined; first_name: string | undefined; last_name: string | undefined }
                            | undefined;
                        }>
                      | undefined;
                    away: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                      };
                    };
                    home: {
                      id: string;
                      score: number;
                      is_loser: boolean;
                      team: {
                        id: string;
                        image: string | undefined;
                        name: string;
                        mascot: string | undefined;
                        short_division_text: string;
                        state: { abbrev: string } | undefined;
                      };
                    };
                  };
            }>;
          };
        };
  };
  team?: {
    id: string;
    name: string;
    mascot: string | undefined;
    image: string | undefined;
    web_path: string;
    division_text: string;
    location_text: string;
  };
};

export type WidgetsV4QueryGameTeamFieldsFragment = {
  id: string;
  score: number;
  is_loser: boolean;
  team: { id: string; image: string | undefined; name: string; mascot: string | undefined; short_division_text: string };
};

export type WidgetsV5ShowQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  date?: InputMaybe<Scalars['String']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type WidgetsV5ShowQuery = {
  schoolWidget: {
    sbl_ad: boolean | undefined;
    source:
      | {
          gender_sports: Array<{ genderId: number; sportId: number; slug: string; name: string }>;
          games: {
            pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
            nodes: Array<
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    team: { mascot: string | undefined; image: string | undefined; name: string; state: { abbrev: string } | undefined };
                    pregameStanding: { overallRecord: string } | undefined;
                  }>;
                  genderSport: { name: string; slug: string };
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    team: { mascot: string | undefined; image: string | undefined; name: string; state: { abbrev: string } | undefined };
                    pregameStanding: { overallRecord: string } | undefined;
                  }>;
                  genderSport: { name: string; slug: string };
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    team: { mascot: string | undefined; image: string | undefined; name: string; state: { abbrev: string } | undefined };
                    pregameStanding: { overallRecord: string } | undefined;
                  }>;
                  genderSport: { name: string; slug: string };
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
            >;
          };
        }
      | {
          gender_sports: Array<{ genderId: number; sportId: number; slug: string; name: string }>;
          games: {
            pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
            nodes: Array<
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    team: { mascot: string | undefined; image: string | undefined; name: string; state: { abbrev: string } | undefined };
                    pregameStanding: { overallRecord: string } | undefined;
                  }>;
                  genderSport: { name: string; slug: string };
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    team: { mascot: string | undefined; image: string | undefined; name: string; state: { abbrev: string } | undefined };
                    pregameStanding: { overallRecord: string } | undefined;
                  }>;
                  genderSport: { name: string; slug: string };
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
              | {
                  id: string;
                  date: string;
                  longStatusText: string;
                  statusId: number;
                  titleText: string;
                  webPath: string;
                  webPhotosPath: string | undefined;
                  gameTeams: Array<{
                    id: string;
                    isLoser: boolean;
                    isTbd: boolean;
                    isWinner: boolean;
                    scoreText: string | undefined;
                    team: { mascot: string | undefined; image: string | undefined; name: string; state: { abbrev: string } | undefined };
                    pregameStanding: { overallRecord: string } | undefined;
                  }>;
                  genderSport: { name: string; slug: string };
                  contest:
                    | {
                        hometownTicketingUrl: string | undefined;
                        gofanTicketingUrl: string | undefined;
                        nfhsNetworkUrl: string | undefined;
                      }
                    | undefined;
                }
            >;
          };
        };
  };
};

export function getRubySdk(requester: <R, V>(queryName: string, ttl: number, variables?: V) => Promise<R>) {
  return {
    activeGamesQuery(ttl: number, variables?: ActiveGamesQueryVariables): Promise<ActiveGamesQuery> {
      return requester<ActiveGamesQuery, ActiveGamesQueryVariables>('activeGamesQuery', ttl, variables);
    },
    adminTeamGamesScoreQuery(ttl: number, variables?: AdminTeamGamesScoreQueryVariables): Promise<AdminTeamGamesScoreQuery> {
      return requester<AdminTeamGamesScoreQuery, AdminTeamGamesScoreQueryVariables>('adminTeamGamesScoreQuery', ttl, variables);
    },
    adminTeamGamesShowQuery(ttl: number, variables?: AdminTeamGamesShowQueryVariables): Promise<AdminTeamGamesShowQuery> {
      return requester<AdminTeamGamesShowQuery, AdminTeamGamesShowQueryVariables>('adminTeamGamesShowQuery', ttl, variables);
    },
    adminTeamGamesStatsQuery(ttl: number, variables?: AdminTeamGamesStatsQueryVariables): Promise<AdminTeamGamesStatsQuery> {
      return requester<AdminTeamGamesStatsQuery, AdminTeamGamesStatsQueryVariables>('adminTeamGamesStatsQuery', ttl, variables);
    },
    adminTeamsQuery(ttl: number, variables?: AdminTeamsQueryVariables): Promise<AdminTeamsQuery> {
      return requester<AdminTeamsQuery, AdminTeamsQueryVariables>('adminTeamsQuery', ttl, variables);
    },
    bracketsGlobalIndexQuery(ttl: number, variables?: BracketsGlobalIndexQueryVariables): Promise<BracketsGlobalIndexQuery> {
      return requester<BracketsGlobalIndexQuery, BracketsGlobalIndexQueryVariables>('bracketsGlobalIndexQuery', ttl, variables);
    },
    bracketsIndexClassificationQuery(
      ttl: number,
      variables?: BracketsIndexClassificationQueryVariables
    ): Promise<BracketsIndexClassificationQuery> {
      return requester<BracketsIndexClassificationQuery, BracketsIndexClassificationQueryVariables>(
        'bracketsIndexClassificationQuery',
        ttl,
        variables
      );
    },
    bracketsIndexQuery(ttl: number, variables?: BracketsIndexQueryVariables): Promise<BracketsIndexQuery> {
      return requester<BracketsIndexQuery, BracketsIndexQueryVariables>('bracketsIndexQuery', ttl, variables);
    },
    bracketsMoreQuery(ttl: number, variables?: BracketsMoreQueryVariables): Promise<BracketsMoreQuery> {
      return requester<BracketsMoreQuery, BracketsMoreQueryVariables>('bracketsMoreQuery', ttl, variables);
    },
    bracketsScoresQuery(ttl: number, variables?: BracketsScoresQueryVariables): Promise<BracketsScoresQuery> {
      return requester<BracketsScoresQuery, BracketsScoresQueryVariables>('bracketsScoresQuery', ttl, variables);
    },
    bracketsShowQuery(ttl: number, variables?: BracketsShowQueryVariables): Promise<BracketsShowQuery> {
      return requester<BracketsShowQuery, BracketsShowQueryVariables>('bracketsShowQuery', ttl, variables);
    },
    bracketsTeamsQuery(ttl: number, variables?: BracketsTeamsQueryVariables): Promise<BracketsTeamsQuery> {
      return requester<BracketsTeamsQuery, BracketsTeamsQueryVariables>('bracketsTeamsQuery', ttl, variables);
    },
    constantsQuery(ttl: number, variables?: ConstantsQueryVariables): Promise<ConstantsQuery> {
      return requester<ConstantsQuery, ConstantsQueryVariables>('constantsQuery', ttl, variables);
    },
    contentIndexQuery(ttl: number, variables?: ContentIndexQueryVariables): Promise<ContentIndexQuery> {
      return requester<ContentIndexQuery, ContentIndexQueryVariables>('contentIndexQuery', ttl, variables);
    },
    enumTypesQuery(ttl: number, variables?: EnumTypesQueryVariables): Promise<EnumTypesQuery> {
      return requester<EnumTypesQuery, EnumTypesQueryVariables>('enumTypesQuery', ttl, variables);
    },
    favoriteGamesQuery(ttl: number, variables?: FavoriteGamesQueryVariables): Promise<FavoriteGamesQuery> {
      return requester<FavoriteGamesQuery, FavoriteGamesQueryVariables>('favoriteGamesQuery', ttl, variables);
    },
    gamesActiveLegacyQuery(ttl: number, variables?: GamesActiveLegacyQueryVariables): Promise<GamesActiveLegacyQuery> {
      return requester<GamesActiveLegacyQuery, GamesActiveLegacyQueryVariables>('gamesActiveLegacyQuery', ttl, variables);
    },
    gamesAllGenderSportsIndexFanAppQuery(
      ttl: number,
      variables?: GamesAllGenderSportsIndexFanAppQueryVariables
    ): Promise<GamesAllGenderSportsIndexFanAppQuery> {
      return requester<GamesAllGenderSportsIndexFanAppQuery, GamesAllGenderSportsIndexFanAppQueryVariables>(
        'gamesAllGenderSportsIndexFanAppQuery',
        ttl,
        variables
      );
    },
    gamesAllGenderSportsIndexQuery(
      ttl: number,
      variables?: GamesAllGenderSportsIndexQueryVariables
    ): Promise<GamesAllGenderSportsIndexQuery> {
      return requester<GamesAllGenderSportsIndexQuery, GamesAllGenderSportsIndexQueryVariables>(
        'gamesAllGenderSportsIndexQuery',
        ttl,
        variables
      );
    },
    gamesBoxscoreQuery(ttl: number, variables?: GamesBoxscoreQueryVariables): Promise<GamesBoxscoreQuery> {
      return requester<GamesBoxscoreQuery, GamesBoxscoreQueryVariables>('gamesBoxscoreQuery', ttl, variables);
    },
    gamesGenderSportIndexCalendarQuery(
      ttl: number,
      variables?: GamesGenderSportIndexCalendarQueryVariables
    ): Promise<GamesGenderSportIndexCalendarQuery> {
      return requester<GamesGenderSportIndexCalendarQuery, GamesGenderSportIndexCalendarQueryVariables>(
        'gamesGenderSportIndexCalendarQuery',
        ttl,
        variables
      );
    },
    gamesGenderSportIndexFanAppQuery(
      ttl: number,
      variables?: GamesGenderSportIndexFanAppQueryVariables
    ): Promise<GamesGenderSportIndexFanAppQuery> {
      return requester<GamesGenderSportIndexFanAppQuery, GamesGenderSportIndexFanAppQueryVariables>(
        'gamesGenderSportIndexFanAppQuery',
        ttl,
        variables
      );
    },
    gamesGenderSportIndexQuery(ttl: number, variables?: GamesGenderSportIndexQueryVariables): Promise<GamesGenderSportIndexQuery> {
      return requester<GamesGenderSportIndexQuery, GamesGenderSportIndexQueryVariables>('gamesGenderSportIndexQuery', ttl, variables);
    },
    gamesLiveQuery(ttl: number, variables?: GamesLiveQueryVariables): Promise<GamesLiveQuery> {
      return requester<GamesLiveQuery, GamesLiveQueryVariables>('gamesLiveQuery', ttl, variables);
    },
    gamesPhotosQuery(ttl: number, variables?: GamesPhotosQueryVariables): Promise<GamesPhotosQuery> {
      return requester<GamesPhotosQuery, GamesPhotosQueryVariables>('gamesPhotosQuery', ttl, variables);
    },
    gamesPreviewQuery(ttl: number, variables?: GamesPreviewQueryVariables): Promise<GamesPreviewQuery> {
      return requester<GamesPreviewQuery, GamesPreviewQueryVariables>('gamesPreviewQuery', ttl, variables);
    },
    gamesRecapQuery(ttl: number, variables?: GamesRecapQueryVariables): Promise<GamesRecapQuery> {
      return requester<GamesRecapQuery, GamesRecapQueryVariables>('gamesRecapQuery', ttl, variables);
    },
    gamesScorebookcastQuery(ttl: number, variables?: GamesScorebookcastQueryVariables): Promise<GamesScorebookcastQuery> {
      return requester<GamesScorebookcastQuery, GamesScorebookcastQueryVariables>('gamesScorebookcastQuery', ttl, variables);
    },
    gamesShowQuery(ttl: number, variables?: GamesShowQueryVariables): Promise<GamesShowQuery> {
      return requester<GamesShowQuery, GamesShowQueryVariables>('gamesShowQuery', ttl, variables);
    },
    organizationsScoresQuery(ttl: number, variables?: OrganizationsScoresQueryVariables): Promise<OrganizationsScoresQuery> {
      return requester<OrganizationsScoresQuery, OrganizationsScoresQueryVariables>('organizationsScoresQuery', ttl, variables);
    },
    organizationsShowQuery(ttl: number, variables?: OrganizationsShowQueryVariables): Promise<OrganizationsShowQuery> {
      return requester<OrganizationsShowQuery, OrganizationsShowQueryVariables>('organizationsShowQuery', ttl, variables);
    },
    organizationsStandingsQuery(ttl: number, variables?: OrganizationsStandingsQueryVariables): Promise<OrganizationsStandingsQuery> {
      return requester<OrganizationsStandingsQuery, OrganizationsStandingsQueryVariables>('organizationsStandingsQuery', ttl, variables);
    },
    photoGalleriesIndexQuery(ttl: number, variables?: PhotoGalleriesIndexQueryVariables): Promise<PhotoGalleriesIndexQuery> {
      return requester<PhotoGalleriesIndexQuery, PhotoGalleriesIndexQueryVariables>('photoGalleriesIndexQuery', ttl, variables);
    },
    photoGalleryQuery(ttl: number, variables?: PhotoGalleryQueryVariables): Promise<PhotoGalleryQuery> {
      return requester<PhotoGalleryQuery, PhotoGalleryQueryVariables>('photoGalleryQuery', ttl, variables);
    },
    rankingsIndexQuery(ttl: number, variables?: RankingsIndexQueryVariables): Promise<RankingsIndexQuery> {
      return requester<RankingsIndexQuery, RankingsIndexQueryVariables>('rankingsIndexQuery', ttl, variables);
    },
    rankingsOrganizationsQuery(ttl: number, variables?: RankingsOrganizationsQueryVariables): Promise<RankingsOrganizationsQuery> {
      return requester<RankingsOrganizationsQuery, RankingsOrganizationsQueryVariables>('rankingsOrganizationsQuery', ttl, variables);
    },
    schoolsIndexQuery(ttl: number, variables?: SchoolsIndexQueryVariables): Promise<SchoolsIndexQuery> {
      return requester<SchoolsIndexQuery, SchoolsIndexQueryVariables>('schoolsIndexQuery', ttl, variables);
    },
    schoolsNewsQuery(ttl: number, variables?: SchoolsNewsQueryVariables): Promise<SchoolsNewsQuery> {
      return requester<SchoolsNewsQuery, SchoolsNewsQueryVariables>('schoolsNewsQuery', ttl, variables);
    },
    schoolsScoresQuery(ttl: number, variables?: SchoolsScoresQueryVariables): Promise<SchoolsScoresQuery> {
      return requester<SchoolsScoresQuery, SchoolsScoresQueryVariables>('schoolsScoresQuery', ttl, variables);
    },
    schoolsShowQuery(ttl: number, variables?: SchoolsShowQueryVariables): Promise<SchoolsShowQuery> {
      return requester<SchoolsShowQuery, SchoolsShowQueryVariables>('schoolsShowQuery', ttl, variables);
    },
    schoolsTeamsQuery(ttl: number, variables?: SchoolsTeamsQueryVariables): Promise<SchoolsTeamsQuery> {
      return requester<SchoolsTeamsQuery, SchoolsTeamsQueryVariables>('schoolsTeamsQuery', ttl, variables);
    },
    seriesShowQuery(ttl: number, variables?: SeriesShowQueryVariables): Promise<SeriesShowQuery> {
      return requester<SeriesShowQuery, SeriesShowQueryVariables>('seriesShowQuery', ttl, variables);
    },
    standingsIndexQuery(ttl: number, variables?: StandingsIndexQueryVariables): Promise<StandingsIndexQuery> {
      return requester<StandingsIndexQuery, StandingsIndexQueryVariables>('standingsIndexQuery', ttl, variables);
    },
    standingsShowQuery(ttl: number, variables?: StandingsShowQueryVariables): Promise<StandingsShowQuery> {
      return requester<StandingsShowQuery, StandingsShowQueryVariables>('standingsShowQuery', ttl, variables);
    },
    TeamPhotoGalleriesQuery(ttl: number, variables?: TeamPhotoGalleriesQueryVariables): Promise<TeamPhotoGalleriesQuery> {
      return requester<TeamPhotoGalleriesQuery, TeamPhotoGalleriesQueryVariables>('TeamPhotoGalleriesQuery', ttl, variables);
    },
    teamPlayersIndexQuery(ttl: number, variables?: TeamPlayersIndexQueryVariables): Promise<TeamPlayersIndexQuery> {
      return requester<TeamPlayersIndexQuery, TeamPlayersIndexQueryVariables>('teamPlayersIndexQuery', ttl, variables);
    },
    teamPlayersShowQuery(ttl: number, variables?: TeamPlayersShowQueryVariables): Promise<TeamPlayersShowQuery> {
      return requester<TeamPlayersShowQuery, TeamPlayersShowQueryVariables>('teamPlayersShowQuery', ttl, variables);
    },
    teamsGamesQuery(ttl: number, variables?: TeamsGamesQueryVariables): Promise<TeamsGamesQuery> {
      return requester<TeamsGamesQuery, TeamsGamesQueryVariables>('teamsGamesQuery', ttl, variables);
    },
    teamsIndexQuery(ttl: number, variables?: TeamsIndexQueryVariables): Promise<TeamsIndexQuery> {
      return requester<TeamsIndexQuery, TeamsIndexQueryVariables>('teamsIndexQuery', ttl, variables);
    },
    teamsNewsQuery(ttl: number, variables?: TeamsNewsQueryVariables): Promise<TeamsNewsQuery> {
      return requester<TeamsNewsQuery, TeamsNewsQueryVariables>('teamsNewsQuery', ttl, variables);
    },
    teamsRankingsQuery(ttl: number, variables?: TeamsRankingsQueryVariables): Promise<TeamsRankingsQuery> {
      return requester<TeamsRankingsQuery, TeamsRankingsQueryVariables>('teamsRankingsQuery', ttl, variables);
    },
    teamsShowQuery(ttl: number, variables?: TeamsShowQueryVariables): Promise<TeamsShowQuery> {
      return requester<TeamsShowQuery, TeamsShowQueryVariables>('teamsShowQuery', ttl, variables);
    },
    teamsStandingsQuery(ttl: number, variables?: TeamsStandingsQueryVariables): Promise<TeamsStandingsQuery> {
      return requester<TeamsStandingsQuery, TeamsStandingsQueryVariables>('teamsStandingsQuery', ttl, variables);
    },
    teamsStatsQuery(ttl: number, variables?: TeamsStatsQueryVariables): Promise<TeamsStatsQuery> {
      return requester<TeamsStatsQuery, TeamsStatsQueryVariables>('teamsStatsQuery', ttl, variables);
    },
    userInvitationQuery(ttl: number, variables?: UserInvitationQueryVariables): Promise<UserInvitationQuery> {
      return requester<UserInvitationQuery, UserInvitationQueryVariables>('userInvitationQuery', ttl, variables);
    },
    widgetsGameScoreShowQuery(ttl: number, variables?: WidgetsGameScoreShowQueryVariables): Promise<WidgetsGameScoreShowQuery> {
      return requester<WidgetsGameScoreShowQuery, WidgetsGameScoreShowQueryVariables>('widgetsGameScoreShowQuery', ttl, variables);
    },
    widgetsLeadersQuery(ttl: number, variables?: WidgetsLeadersQueryVariables): Promise<WidgetsLeadersQuery> {
      return requester<WidgetsLeadersQuery, WidgetsLeadersQueryVariables>('widgetsLeadersQuery', ttl, variables);
    },
    widgetsV2GamesQuery(ttl: number, variables?: WidgetsV2GamesQueryVariables): Promise<WidgetsV2GamesQuery> {
      return requester<WidgetsV2GamesQuery, WidgetsV2GamesQueryVariables>('widgetsV2GamesQuery', ttl, variables);
    },
    widgetsV2LeadersQuery(ttl: number, variables?: WidgetsV2LeadersQueryVariables): Promise<WidgetsV2LeadersQuery> {
      return requester<WidgetsV2LeadersQuery, WidgetsV2LeadersQueryVariables>('widgetsV2LeadersQuery', ttl, variables);
    },
    widgetsV2StandingsQuery(ttl: number, variables?: WidgetsV2StandingsQueryVariables): Promise<WidgetsV2StandingsQuery> {
      return requester<WidgetsV2StandingsQuery, WidgetsV2StandingsQueryVariables>('widgetsV2StandingsQuery', ttl, variables);
    },
    widgetsV3Query(ttl: number, variables?: WidgetsV3QueryVariables): Promise<WidgetsV3Query> {
      return requester<WidgetsV3Query, WidgetsV3QueryVariables>('widgetsV3Query', ttl, variables);
    },
    widgetsV4Query(ttl: number, variables?: WidgetsV4QueryVariables): Promise<WidgetsV4Query> {
      return requester<WidgetsV4Query, WidgetsV4QueryVariables>('widgetsV4Query', ttl, variables);
    },
    widgetsV5ShowQuery(ttl: number, variables?: WidgetsV5ShowQueryVariables): Promise<WidgetsV5ShowQuery> {
      return requester<WidgetsV5ShowQuery, WidgetsV5ShowQueryVariables>('widgetsV5ShowQuery', ttl, variables);
    }
  };
}
